// Default Date Time Picker
import React, { useState, forwardRef, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { formatDate } from "utils/pivot";
import moment from "moment";

import dayjs from "dayjs";

const BasicDatePicker = (props, ref) => {
  const [initDate, setInitDate] = useState(
    formatDate(props.value, "YYYY-MM-DD").length == 10
      ? formatDate(props.value, "YYYY-MM-DD")
      : props.value
  );

  useEffect(() => {
    setInitDate(props.value);
  }, [props]);

  const handleDateChange = (date, context) => {
    date = date["$d"];
    if (
      date &&
      date != "Invalid Date" &&
      formatDate(date, "YYYY-MM-DD").length == 10
    ) {
      setInitDate(date);
      if (props.lineItem && document.activeElement.name != props.id) {
        let [itmPos, itmNme] = props.id.split("_");
        props.handleChange(
          itmNme,
          formatDate(date, "YYYY-MM-DD"),
          itmPos,
          null
        );
      } else if (!props.lineItem) {
        props.handleChange({
          target: {
            value: formatDate(date, "YYYY-MM-DD"),
            name: props.id,
            pickerID: props.pickerID ?? 0,
          },
        });
      }
    }
  };

  const handleOnBlur = (event) => {
    let date = new Date(
      event.target.value.split("-").reverse().join("-").toString()
    );

    if (
      date &&
      date != "Invalid Date" &&
      formatDate(date, "YYYY-MM-DD").length == 10 &&
      props.lineItem
    ) {
      let [itmPos, itmNme] = props.id.split("_");

      props.handleChange(itmNme, formatDate(date, "YYYY-MM-DD"), itmPos, null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        id={props.id}
        name={props.name}
        autoFocus={props.autoFocus ?? false}
        className="customDatePickerMui"
        disabled={props.disabled}
        label={props.label}
        format="DD-MM-YYYY"
        value={initDate ? dayjs(new Date(initDate)) : null}
        onChange={handleDateChange}
        minDate={dayjs(new Date(props.minDate))}
        maxDate={dayjs(new Date(props.maxDate))}
        slotProps={{
          textField: {
            name: props.name,
            onFocus: (event) =>
              props.handleOnFocus ? props.handleOnFocus(event) : null,
            onBlur: (event) => handleOnBlur(event),
            variant: props.variant ?? "standard",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default forwardRef(BasicDatePicker);
