/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import axios from "axios";
import { AppTrlns, AppConstants } from "config";
import { aURL, pURL } from "utils/pivot";

//----------- Initialize All Constants In Reducer ------------
export const __initAppConstants = async (usrID, cmpID) => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePathCRM, {
        page: "themes/initAppConstants.php",
        usrID: usrID,
        cmpID: cmpID,
      })
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Populate Menu ------------------------
export const __popuateMenu = async (moduleName, users) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "themes/generalSettings/populateMenu",
        pURL({ module: moduleName + "_menu", push_token: "D" })
      )
      .then(function (response) {
        // console.log(response);

        if (response) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: response.data.message });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- GET Search Data ------------------------
export const __getSearchData = async (searchString) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getModuleListData",
        pURL({
          searchString: searchString,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- GET List Of Applications ------------------------
export const __getApps = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePath + "themes/generalSettings/getApps", pURL({}))
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};
