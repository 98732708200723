/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

// app config
import { AppConfig } from "config";

import {
  SET_MODULES_LIST_DATA,
  SET_MENU_LIST_DATA,
  SET_MENU_LIST_DUMMY,
  ACL_LIST,
  TEMPLATE_LIST,
  EMAIL_TEMPLATE_LIST,
  USER_UI_SETTINGS,
  COMPANY_SETTINGS,
} from ".";

const INIT_STATE = {
  user: [],
  moduleListData: null,
  menuListData: null,
  ACLList: [],
  templateList: [],
  emailTemplateList: [],
  userUISettings: [],
  companySetings: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MODULES_LIST_DATA:
      return { ...state, moduleListData: action.payload };
    case SET_MENU_LIST_DATA:
      return { ...state, menuListData: action.payload };
    case SET_MENU_LIST_DUMMY:
      return { ...state, menuListDataDummy: action.payload };

    case ACL_LIST:
      return { ...state, ACLList: action.payload };

    case TEMPLATE_LIST:
      return { ...state, templateList: action.payload };
    case EMAIL_TEMPLATE_LIST:
      return { ...state, emailTemplateList: action.payload };

    case USER_UI_SETTINGS:
      return { ...state, userUISettings: action.payload };

    case COMPANY_SETTINGS:
      return { ...state, companySetings: action.payload };

    default:
      return { ...state };
  }
};
