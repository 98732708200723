/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar AR
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { SET_SOCKET_IO, CLEAR_SOCKET_IO } from ".";

const INIT_STATE = { _socketIO: null };

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SOCKET_IO:
      return { ...state, _socketIO: action.payload };
    case CLEAR_SOCKET_IO:
      return { ...state, _socketIO: action.payload };
    default:
      return { ...state };
  }
};
