/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import jwt_decode from "jwt-decode";

import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import QueueAnim from "rc-queue-anim";

import { useForm } from "react-hook-form";

import { Navigate, useNavigate, Link } from "react-router-dom";

// Application Config
import { AppConfig } from "config";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";
import { signIn, signInWithGoogle } from "modules/resources/stores/resources";

import FieldError from "components/FieldError";
import { getValue } from "@mui/system";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [googleButtonWidth, setGoogleButtonWidth] = useState(0);
  const [googleAuthLink, setGoogleAuthLink] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    function handleResize() {
      const container = document.getElementById("google_button_container");
      if (container) {
        setGoogleButtonWidth(container.clientWidth - 26);
      }
    }

    handleResize(); // Call it once to set initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (form) => {
    setLoading(true);
    dispatch(signIn(form))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then((response) => {
        //setLoading(false);
        if (response && parseInt(response.msg_stat) === 0) {
          NotificationManager.error("Incorrect User Credentials!");
        } else {
          navigate("/");
          NotificationManager.success("User Login Successfully!");
        }
      })
      .finally(() => {});
  };

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const signInGuest = () => {
    onSubmit({ username: "guest", password: "guest-sp-user" });
  };

  const signInGoogle = (response) => {
    const userObject = jwt_decode(response.credential);

    setLoading(true);
    dispatch(signInWithGoogle(userObject.email, userObject.aud))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then((response) => {
        setLoading(false);
        if (response == "new_user") {
          navigate("/resources/signup", {
            state: {
              email: userObject.email,
              aud: userObject.aud,
              name: userObject.name,
              picture: userObject.picture,
            },
            replace: true,
          });
          NotificationManager.error(
            "New Registration. Please Fill Basic Detail To Continue."
          );
          setGoogleAuthLink(false);
        } else if (response == "new_google_user") {
          setValue("username", userObject.email);
          setValue("google_key", userObject.aud);
          setGoogleAuthLink(true);
        } else if (response == "success") {
          setGoogleAuthLink(false);
          navigate("/");
          NotificationManager.success("User Login Successfully!");
        } else {
          setGoogleAuthLink(false);
          NotificationManager.warning("UnExpected Error. Please ReTry!");
        }
      })
      .finally(() => {});
  };

  const signInFacebook = (response) => {
    console.log(response);
  };

  const onScriptLoadGoogle = () => {
    setGoogleButtonWidth(
      document.getElementById("google_button_container").clientWidth - 26
    );
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src={AppConfig.appLogo}
                      alt="session-logo"
                      className="img-fluid"
                      width="175"
                    />
                  </Link>
                </div>
                <div>
                  <a
                    className="mr-15"
                    onClick={() => navigate("/resources/signup")}
                  >
                    Create New account?
                  </a>
                  <Button
                    variant="contained"
                    className="btn-light text-dark"
                    onClick={() => navigate("/resources/signup")}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-7 col-md-7 col-lg-8 mx-auto">
                <div
                  className="session-body text-center"
                  style={{ opacity: 0.9 }}
                >
                  <div className="session-head mb-30">
                    <h2 className="font-weight-bold">
                      Get started with {AppConfig.brandName}
                    </h2>
                    {googleAuthLink ? (
                      <div className="user-login-info-msg">
                        Email ID [{getValues("username")}] Exists In Our
                        Application. Please Enter Password To Enable Google
                        Authentication.
                      </div>
                    ) : null}
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField hidden {...register("google_key")} />
                    <div className="mb-3">
                      <TextField
                        error={errors?.username ? true : false}
                        label="Username"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: googleAuthLink ? true : false,
                        }}
                        {...register("username", {
                          required: true,
                          // pattern: /\S+@\S+\.\S+/,
                        })}
                      />
                      {errors?.username?.type === "required" && (
                        <FieldError errorText="This Field Is Required" />
                      )}
                    </div>
                    <div className="mb-3">
                      <TextField
                        error={errors?.password ? true : false}
                        label="Password"
                        variant="outlined"
                        type={passwordShown ? "text" : "password"}
                        size="small"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePassword}
                                onMouseDown={togglePassword}
                                edge="end"
                              >
                                {passwordShown ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...register("password", {
                          required: true,
                        })}
                      />

                      {errors?.password?.type === "required" && (
                        <FieldError errorText="This Field Is Required" />
                      )}
                    </div>
                    <div className="mt-3 d-grid">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Sign In
                          </button>
                        </div>
                        {googleAuthLink == false ? (
                          <>
                            <div className="col-md-6 mb-3">
                              {/* guest-signin-container */}
                              <button
                                type="button"
                                className="btn btn-info btn-block "
                                onClick={() => signInGuest()}
                              >
                                Continue As Guest
                              </button>
                            </div>
                            <div
                              className="col-md-6 mb-3"
                              id="google_button_container"
                            >
                              <GoogleOAuthProvider
                                clientId="869969676527-i66ce3l32rq01dup7pqu4lfrkkg3id51.apps.googleusercontent.com"
                                onScriptLoadSuccess={onScriptLoadGoogle}
                                onScriptLoadError={onScriptLoadGoogle}
                              >
                                <GoogleLogin
                                  render={(renderProps) => (
                                    <button
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      Sign in with google
                                    </button>
                                  )}
                                  width={googleButtonWidth}
                                  onSuccess={signInGoogle}
                                  onFailure={signInGoogle}
                                  cookiePolicy="single_host_origin"
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </>
                        ) : null}
                        {/* <div className="col-md-6 mb-3 facebook-button-container">
                          <FacebookLogin
                            appId="667949955247636"
                            autoLoad={true}
                            fields="name,email,picture"
                            callback={signInFacebook}
                            icon="fa-facebook"
                            render={(renderProps) => (
                              <button onClick={renderProps.onClick}>
                                Sign in with facebook
                              </button>
                            )}
                          />
                        </div> */}
                      </div>
                    </div>

                    <div className="mt-4 mb-4 text-center">
                      <a
                        className="text-muted"
                        onClick={() => navigate("/resources/forgotpassword")}
                      >
                        <i className="mdi mdi-lock me-1" /> Forgot Password?
                      </a>
                    </div>
                  </form>
                  <p className="text-muted">
                    By signing up you agree to {AppConfig.brandName}
                  </p>
                  <p className="mb-0">
                    <a
                      onClick={() => navigate("/resources/termsofservice")}
                      className="text-muted"
                    >
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};
export default SignIn;
