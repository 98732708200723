/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import axios from "axios";
import { AppTrlns, AppConstants } from "config";
import { pURL } from "utils/pivot";

export const __downloadRFQ = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePath + "purchase/orders/testPDF", pURL({}))
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* INIT Invoice Form Data*/
export const __initInvoiceForm = async (
  selectedCompany,
  selectedTransactionId
) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      selectedCompany
        ? axios.post(
            AppConstants.nodePathCRM,
            pURL({
              page: "invoice/getParticipants.php",
              companyId: selectedCompany,
            })
          )
        : null,
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getCompanyDetails.php",
          companyId: selectedCompany,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvoiceItems.php",
        })
      ),
      selectedCompany
        ? axios.post(
            AppConstants.nodePathCRM,
            pURL({
              page: "invoice/getPlanManagers.php",
              companyId: selectedCompany,
            })
          )
        : null,
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getGstList.php",
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getItemUnits.php",
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/invClaimType.php",
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvoiceTypes.php",
        })
      ),
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch(function (error) {
        reject({ msg_stat: "0", msg: "Unexpected Error. Please Retry" }); // Corrected the message
      });
  });
};

/* INIT Invoice Edit Form Data*/
export const __initEditInvoiceForm = async (
  selectedTransactionId,
  selectedTransactionCompId
) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      selectedTransactionId
        ? axios.post(
            AppConstants.nodePathCRM,
            pURL({
              page: "invoice/getInvoiceData.php",
              InvId: selectedTransactionId,
              CompanyId: selectedTransactionCompId,
            })
          )
        : null,
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch(function (error) {
        reject({ msg_stat: "0", msg: "Unexpected Error. Please Retry" }); // Corrected the message
      });
  });
};

//-------------------------- Update Transaction Data --------------------------------
export const __updateTransactionData = async (formData, transData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/postInvoice.php",
          formData: JSON.stringify(formData),
          transData: JSON.stringify(transData),
        })
      )
      .then(function (response) {
        if (response) {
          resolve(response);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//-------------------------- Update Company --------------------------------
export const __updateCompany = async (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/saveCompanyChange.php",
          companyId: companyId,
        })
      )
      .then(function (response) {
        if (response) {
          resolve(response);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/*Init Invoice Listing*/
export const __initListInvoices = async (
  listPage,
  rowsPerPage,
  searchText,
  filterList,
  sortOrder,
  cmp_Id,
  fromDate,
  toDate
) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/listInvoice.php",
          pageNum: listPage,
          rowsPerPage: rowsPerPage,
          searchText: searchText,
          filterList: JSON.stringify(filterList),
          sortOrder: sortOrder,
          companyId: cmp_Id,
          fromDate: fromDate,
          toDate: toDate,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({ page: "invoice/invComboFilter.php" })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getParticipants.php",
          companyId: cmp_Id,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getPlanManagers.php",
          companyId: cmp_Id,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getCompanyDetails.php",
          companyId: cmp_Id,
        })
      ),
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//-------------------generate print for transaction----------------
export const __generatePrintForTransaction = async (transId, CompanyId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvoicePDF.php",
          InvId: transId,
          CompanyId: CompanyId,
        })
      )
      .then(function (response) {
        if (response) {
          resolve(response);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//-------------------generate pdf for transaction----------------
export const __generatePdfForTransaction = async (pdfDetails) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "invoices/invoices/generatePdfForTransaction",
        pURL({ pdfDetails: pdfDetails })
      )
      .then(function (response) {
        if (response) {
          resolve(response);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//-------------------update PDF name in simple plans db----------------
export const __updatePDFNameInSP = async (InvID, fileName) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/saveInvPDF.php",
          InvId: InvID,
          InvPDF: fileName,
        })
      )
      .then(function (response) {
        if (response) {
          resolve(response);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* INIT Invoice Email Datas*/
export const __initEmailInvoice = async (selectedInvId, selectedCompanyId) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getRecps.php",
          InvId: selectedInvId,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvEmail.php",
          InvId: selectedInvId,
          CompanyId: selectedCompanyId,
        })
      ),
      axios.post(
        AppConstants.nodePath + "invoices/invoices/getInvoiceEmailTemplate",
        pURL({})
      ),
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch(function (error) {
        reject({ msg_stat: "0", msg: "Unexpected Error. Please Retry" }); // Corrected the message
      });
  });
};

/* Initialize Invoice Dashboard */
export const __initInvoiceDashboard = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvDashGraph.php",
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* Export Invoices To CSV */
export const __exportInvoicesToCSV = async (invoices, csvFileName) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/xlsExport.php",
          itemList: JSON.stringify(invoices),
          fileName: csvFileName,
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* Send Email History */
export const __sendEmailHistory = async (
  emailParameters,
  invoiceId,
  companyId
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/postInvMailHistory.php",
          companyId: companyId,
          InvId: invoiceId,
          email_to: emailParameters.to,
          email_cc: emailParameters.cc,
          email_bcc: emailParameters.bcc,
          Email_content: btoa(emailParameters.html),
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* Get Email History */
export const __getEmailHistory = async (invoiceId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getInvMailHistory.php",
          InvId: invoiceId,
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

/* Delete Invoices */
export const __deleteUserInvoices = async (deleteInvoices) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/deleteInvoices.php",
          invList: JSON.stringify(deleteInvoices),
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};
