/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete as MUIAutocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import Autocomplete from "react-google-autocomplete";

// app config
import { AppConfig } from "config";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";

import FieldError from "components/FieldError";
import { setLoader } from "modules/themes/stores/themeSettings";

import { initSignUp, signUpNewUser } from "modules/resources/stores/resources";

const SignIn = ({ props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // console.log(location?.state);

  const { companyServices } = useSelector((store) => store.resources.resources);

  const [services, setServices] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initSignUp())
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {})
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, []);

  useEffect(() => {
    if (location?.state) {
      setValue("google_key", location?.state?.aud);
      setValue("google_picture", location?.state?.picture);

      setValue("email", location?.state?.email);
      setValue("contact_name", location?.state?.name);
    }
  }, [location?.state]);

  const onSubmit = (form) => {
    // console.log(form);
    dispatch(setLoader(true));
    dispatch(signUpNewUser(form))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then(() => {
        navigate("/");
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src={AppConfig.appLogo}
                      alt="session-logo"
                      className="img-fluid"
                      width="175"
                    />
                  </Link>
                </div>
                <div>
                  <a
                    className="mr-15"
                    onClick={() => navigate("/resources/signin")}
                  >
                    Already have an account?
                  </a>
                  <Button
                    variant="contained"
                    className="btn-light text-dark"
                    onClick={() => navigate("/resources/signin")}
                  >
                    Sign In
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper session-inner-wrapper-sign-up">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-9 col-md-9 col-lg-9 mx-auto">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <h2 className="font-weight-bold">
                      Get started with {AppConfig.brandName}
                    </h2>
                    {location && location.state != null ? (
                      <div className="user-login-info-msg">
                        New Registration. Please Fill Basic Detail To Continue.
                      </div>
                    ) : null}
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <TextField hidden {...register("google_key")} />
                      <TextField hidden {...register("google_picture")} />

                      <div className="col-6 mb-3">
                        <TextField
                          error={errors?.company_name ? true : false}
                          label="Company Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("company_name", e.target.value);
                          }}
                          {...register("company_name", {
                            required: true,
                            maxLength: 20,
                            pattern: /^[A-Za-z]+$/i,
                          })}
                        />
                        {errors?.company_name?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                        {errors?.company_name?.type === "maxLength" && (
                          <FieldError errorText="Company cannot exceed 20 characters" />
                        )}
                        {errors?.company_name?.type === "pattern" && (
                          <FieldError errorText="Alphabetical characters only" />
                        )}
                      </div>
                      <div className="col-6 mb-3">
                        <TextField
                          error={errors?.contact_name ? true : false}
                          label="Key Person Name"
                          variant="outlined"
                          // focused
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("contact_name", e.target.value);
                          }}
                          {...register("contact_name", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        {errors?.contact_name?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                        {errors?.contact_name?.type === "maxLength" && (
                          <FieldError errorText="Name cannot exceed 20 characters" />
                        )}
                      </div>
                      {/* {console.log(location)} */}
                      {location && location.state == null ? (
                        <>
                          <div className="col-6 mb-3">
                            <TextField
                              error={errors?.password ? true : false}
                              label="Password"
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                setValue("password", e.target.value);
                              }}
                              {...register("password", {
                                required: true,
                                minLength: 6,
                                maxLength: 20,
                              })}
                            />
                            {errors?.password?.type === "required" && (
                              <FieldError errorText="This Field Is Required" />
                            )}
                            {errors?.password?.type === "maxLength" && (
                              <FieldError errorText="Password requires min 6 characters" />
                            )}
                            {errors?.password?.type === "maxLength" && (
                              <FieldError errorText="Password cannot exceed 20 characters" />
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <TextField
                              error={errors?.company_name ? true : false}
                              label="Re Password"
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                setValue("re_password", e.target.value);
                              }}
                              {...register("re_password", {
                                required: true,
                                minLength: 6,
                                maxLength: 20,
                              })}
                            />
                            {errors?.re_password?.type === "required" && (
                              <FieldError errorText="This Field Is Required" />
                            )}
                            {errors?.re_password?.type === "maxLength" && (
                              <FieldError errorText="Password requires min 6 characters" />
                            )}
                            {errors?.re_password?.type === "maxLength" && (
                              <FieldError errorText="Password cannot exceed 20 characters" />
                            )}
                          </div>
                        </>
                      ) : null}
                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.email ? true : false}
                          label="Email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            readOnly: location?.state?.email ? true : false,
                          }}
                          onChange={(e) => {
                            setValue("email", e.target.value);
                          }}
                          {...register("email", {
                            required: true,
                            pattern: /\S+@\S+\.\S+/,
                          })}
                        />
                        {errors?.email?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                        {errors?.email?.type === "pattern" && (
                          <FieldError errorText="Invalid Email" />
                        )}
                      </div>
                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.phone ? true : false}
                          label="Phone"
                          variant="outlined"
                          // focused
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("phone", e.target.value);
                          }}
                          {...register("phone", {
                            required: true,
                          })}
                        />
                        {errors?.email?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.website ? true : false}
                          label="Website"
                          variant="outlined"
                          // focused
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("website", e.target.value);
                          }}
                          {...register("website", {
                            required: true,
                          })}
                        />
                        {errors?.website?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>

                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.reg_no ? true : false}
                          label="Reg: No:"
                          variant="outlined"
                          // focused
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("reg_no", e.target.value);
                          }}
                          {...register("reg_no", {
                            required: true,
                          })}
                        />
                        {errors?.reg_no?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.abn_no ? true : false}
                          label="ABN"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("abn_no", e.target.value);
                          }}
                          {...register("abn_no", {
                            required: true,
                          })}
                        />
                        {errors?.abn_no?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-4 mb-3">
                        <TextField
                          error={errors?.house_no ? true : false}
                          label="House/Flat No:"
                          variant="outlined"
                          // focused
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setValue("house_no", e.target.value);
                          }}
                          {...register("house_no", {
                            required: true,
                          })}
                        />
                        {errors?.house_no?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <Autocomplete
                          style={{
                            borderColor:
                              errors?.address?.type === "required"
                                ? "#F00"
                                : null,
                          }}
                          {...register("address", {
                            required: true,
                          })}
                          className="google-places-autocomplete"
                          apiKey="AIzaSyDa_T47FfeEErZ0iEUxvwjC7R_vbM5UEnY"
                          onPlaceSelected={(place) => {
                            setValue("address", place, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        {errors?.address?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-12 mb-3 text-left">
                        <FormControl fullWidth size="small">
                          <InputLabel>States You Operate</InputLabel>
                          <Select
                            error={errors?.states ? true : false}
                            label="States You Operate"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              setValue("states", e.target.value, {
                                shouldValidate: true,
                              });
                            }}
                            {...register("states", {
                              required: true,
                            })}
                          >
                            <MenuItem value="ACT">ACT</MenuItem>
                            <MenuItem value="NSW">NSW</MenuItem>
                            <MenuItem value="NT">NT</MenuItem>
                            <MenuItem value="QLD">QLD</MenuItem>
                            <MenuItem value="SA">SA</MenuItem>
                            <MenuItem value="TAS">TAS</MenuItem>
                            <MenuItem value="VIC">VIC</MenuItem>
                            <MenuItem value="WA">WA</MenuItem>
                          </Select>
                        </FormControl>
                        {errors?.states?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <MUIAutocomplete
                          multiple
                          filterSelectedOptions
                          size="small"
                          options={companyServices || []}
                          getOptionLabel={(option) =>
                            option && option.SRName ? option.SRName : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.SRID === parseInt(value.SRID)
                          }
                          onChange={(e, options) => {
                            setValue("services", options, {
                              shouldValidate: true,
                            });
                            setValue("services_Options", options, {
                              shouldValidate: true,
                            });
                            setServices(options);
                          }}
                          value={services}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Services"
                              {...register("services", {
                                required: services.length > 0 ? false : true,
                              })}
                            />
                          )}
                        />
                        {errors?.services?.type === "required" && (
                          <FieldError errorText="This Field Is Required" />
                        )}
                      </div>
                    </div>
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Sign Up
                      </button>
                    </div>
                    <div className="mt-4 mb-4 text-center">
                      <a
                        className="text-muted"
                        onClick={() => navigate("/signin")}
                      >
                        <i className="mdi mdi-lock me-1" /> Already Have An
                        Account?
                      </a>
                    </div>
                  </form>
                  <p className="text-muted">
                    By signing up you agree to {AppConfig.brandName}
                  </p>
                  <p className="mb-0">
                    <a
                      onClick={() => navigate("/resources/termsofservice")}
                      className="text-muted"
                    >
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};
export default SignIn;
