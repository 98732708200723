/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";
import FieldError from "components/FieldError";
import { setLoader } from "modules/themes/stores/themeSettings";

import { AppTrlns, ChartConfig } from "config";

import {
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Popper,
  Button,
  Tooltip,
  IconButton,
  InputLabel,
} from "@mui/material";

import {
  initCompanySettings,
  updateCompanySettings,
} from "modules/settings/stores/settings";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { DeleteForever, DeleteOutline, Save } from "@mui/icons-material";

import { useDropzone } from "react-dropzone";

const imgExt = [".jpg", ".png", ".jpeg"];

const CompanySettings = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState(
    sessionStorage.getItem("comp_id")
  );
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(1);
  const [bankInfoDetails, setBankInfoDetails] = useState("");
  const [footerMsgInfo, setFooterMsgInfo] = useState("");
  const [mailFooterMsgInfo, setMailFooterMsgInfo] = useState("");

  const [base64Data, setBase64Data] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { companySetings } = useSelector((store) => store.settings.settings);
  const { invoiceCompanyDetails } = useSelector(
    (store) => store.invoices.invoices
  );

  useEffect(() => {
    if (selectedCompany) {
      dispatch(setLoader(true));
      dispatch(initCompanySettings(selectedCompany)).then(() => {
        dispatch(setLoader(false));
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (companySetings && Object.keys(companySetings).length > 0) {
      const invoiceCompanyIndex = invoiceCompanyDetails.findIndex(
        (p) => p.CMP_Id.toString() === selectedCompany.toString()
      );

      if (invoiceCompanyIndex > -1) {
        setValue(
          "companyName_Options",
          invoiceCompanyDetails[invoiceCompanyIndex],
          {
            shouldValidate: true,
          }
        );
        setValue("companyName", invoiceCompanyDetails[invoiceCompanyIndex], {
          shouldValidate: true,
        });
        setSelectedCompanyDetails(invoiceCompanyDetails[invoiceCompanyIndex]);
      }

      setValue("TempName", companySetings["TempName"], {
        shouldValidate: true,
      });

      setValue("Invoice_Type", companySetings["Invoice_Type"], {
        shouldValidate: true,
      });
      setSelectedInvoiceType(companySetings["Invoice_Type"]);

      setValue("Trade_Name", companySetings["Trade_Name"], {
        shouldValidate: true,
      });
      setValue("ABN_No", companySetings["ABN_No"], {
        shouldValidate: true,
      });
      setValue("RegNo", companySetings["RegNo"], {
        shouldValidate: true,
      });
      setValue("Prefix", companySetings["Prefix"], {
        shouldValidate: true,
      });

      setValue("Logo_Width", companySetings["Logo_Width"], {
        shouldValidate: true,
      });

      setBankInfoDetails(
        companySetings["Bank_Info"] ? atob(companySetings["Bank_Info"]) : ""
      );
      setValue(
        "Bank_Info",
        companySetings["Bank_Info"] ? atob(companySetings["Bank_Info"]) : "",
        {
          shouldValidate: true,
        }
      );

      setValue(
        "Footer_Msg",
        companySetings["Footer_Msg"] ? atob(companySetings["Footer_Msg"]) : ""
      );
      setFooterMsgInfo(
        companySetings["Footer_Msg"] ? atob(companySetings["Footer_Msg"]) : ""
      );

      setValue(
        "Mail_Footer_Msg",
        companySetings["Mail_Footer_Msg"]
          ? atob(companySetings["Mail_Footer_Msg"])
          : ""
      );
      setMailFooterMsgInfo(
        companySetings["Mail_Footer_Msg"]
          ? atob(companySetings["Mail_Footer_Msg"])
          : ""
      );

      if (companySetings["Cmp_Logo"] && companySetings["logoBase64"]) {
        const imageUrl = companySetings["Cmp_Logo"];
        setBase64Data(companySetings["logoBase64"]);
        setFileExtension(imageUrl.split(".").pop().toLowerCase());
        setErrorMessage("");
      }
    }
  }, [companySetings]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const dropzoneStyle = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    textAlign: "center",
    padding: "20px",
    cursor: "pointer",
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file.type.startsWith("image/")) {
      setErrorMessage("Invalid file type. Please upload an image.");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result.split(",")[1];
      setBase64Data(base64);
      setFileExtension(file.name.split(".").pop());
      setErrorMessage("");
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: imgExt.join(","),
  });

  const displayCompanySettingsForm = () => {
    return (
      <div className="transactionFormGroup">
        <table className="transactionFormInnerGroup">
          <tbody>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <Autocomplete
                    freeSolo
                    options={invoiceCompanyDetails || []}
                    getOptionLabel={(option) =>
                      option && option.CMP_Name ? option.CMP_Name : ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.CMP_Id === parseInt(value.CMP_Id)
                    }
                    onChange={(event, value) => {
                      handleCompanyChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        multiline
                        error={errors?.companyName ? true : false}
                        {...register("companyName", {
                          required: true,
                        })}
                        variant="standard"
                        placeholder="Select Company"
                        label="Select Company"
                      />
                    )}
                    value={selectedCompanyDetails}
                  />
                  {errors?.companyName?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>

            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <TextField
                    type="text"
                    label="Template Name"
                    placeholder="Template Name"
                    {...register("TempName", {
                      required: false,
                    })}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("TempName", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.TempName?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <InputLabel>Invoice Type</InputLabel>
                  <Select
                    {...register("Invoice_Type", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setValue("Invoice_Type", e.target.value, {
                        shouldValidate: true,
                      });
                      setSelectedInvoiceType(e.target.value);
                    }}
                    placeholder="Invoice Type"
                    label="Invoice Type"
                    fullWidth
                    sx={{ width: "100%" }}
                    size="small"
                    value={selectedInvoiceType}
                  >
                    <MenuItem value="1">Invoice</MenuItem>
                    <MenuItem value="2">Tax Invoice</MenuItem>
                  </Select>
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <TextField
                    type="text"
                    label="Trade Name"
                    placeholder="Trade Name"
                    {...register("Trade_Name", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("Trade_Name", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.Trade_Name?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <TextField
                    type="text"
                    label="ABN #"
                    placeholder="ABN #"
                    {...register("ABN_No", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("ABN_No", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.ABN_No?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <TextField
                    type="text"
                    label="Registration Number"
                    placeholder="Registration Number"
                    {...register("RegNo", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("RegNo", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.RegNo?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth className="mt-2">
                  <TextField
                    type="text"
                    label="Prefix"
                    placeholder="Prefix"
                    {...register("Prefix", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("Prefix", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.Prefix?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>

            <tr>
              <td>
                {!base64Data && (
                  <>
                    <div
                      {...getRootProps()}
                      style={dropzoneStyle}
                      className="mt-2"
                    >
                      <input {...getInputProps()} />
                      <p>Drag & drop image here, or click to select one</p>
                    </div>
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                  </>
                )}

                {base64Data && (
                  <div>
                    <div className="uploadedImageDiv">
                      <img
                        src={`data:image/${fileExtension};base64,${base64Data}`}
                        alt="Uploaded"
                        style={{ width: "150px" }}
                      />
                      <Tooltip title="Delete Image" arrow>
                        <DeleteOutline
                          className="deleteButton"
                          onClick={handleDelete}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="uploadedImgDetails">
                      <strong>File Extension:</strong> {fileExtension}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="transactionFormInnerGroupGap"></table>
        <table className="transactionFormInnerGroupRight">
          <tbody>
            <tr>
              <td>
                <span>
                  <InputLabel>Bank Details</InputLabel>
                </span>

                <ReactQuill
                  theme="snow"
                  selection={{ start: 0, end: 0 }}
                  // {...register("Bank_Info")}
                  modules={modules}
                  formats={formats}
                  value={bankInfoDetails}
                  onChange={onEditorStateChange}
                  style={{ height: "150px" }}
                  className="bankInfoQlContainer"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <InputLabel>Footer Message</InputLabel>
                </span>

                <ReactQuill
                  theme="snow"
                  selection={{ start: 0, end: 0 }}
                  // {...register("Footer_Msg")}
                  modules={modules}
                  formats={formats}
                  value={footerMsgInfo}
                  onChange={onEditorFooterMsgStateChange}
                  style={{ height: "150px" }}
                  className="bankInfoQlContainer"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <InputLabel>Mail Footer Message</InputLabel>
                </span>

                <ReactQuill
                  theme="snow"
                  selection={{ start: 0, end: 0 }}
                  // {...register("Mail_Footer_Msg")}
                  modules={modules}
                  formats={formats}
                  value={mailFooterMsgInfo}
                  onChange={onEditorMailFooterMsgStateChange}
                  style={{ height: "150px" }}
                  className="bankInfoQlContainer"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const handleCompanyChange = (value) => {
    setValue("companyName_Options", value, {
      shouldValidate: true,
    });
    setValue("companyName", value, {
      shouldValidate: true,
    });
    setSelectedCompanyDetails(value);
    setSelectedCompany(value?.CMP_Id);
  };

  const handleDelete = () => {
    setBase64Data(null);
    setFileExtension(null);
  };

  // modules
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image"],
      ["clean"],
      [{ align: [] }],
    ],
  };

  // formats
  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
  ];

  const onEditorStateChange = (content) => {
    setValue("Bank_Info", content);
    setBankInfoDetails(content);
  };
  const onEditorFooterMsgStateChange = (content) => {
    setValue("Footer_Msg", content);
    setFooterMsgInfo(content);
  };
  const onEditorMailFooterMsgStateChange = (content) => {
    setValue("Mail_Footer_Msg", content);
    setMailFooterMsgInfo(content);
  };

  const saveCompanySettings = (form) => {
    form["companyId"] = selectedCompany;
    form["logoImageData"] = base64Data;
    form["fileExt"] = fileExtension;
    dispatch(setLoader(true));
    dispatch(updateCompanySettings(form))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        NotificationManager.success("Company Settings\nUpdated Successfully");
        dispatch(setLoader(false));
      })
      .finally(() => {});
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("settings:CompanySettings.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:CompanySettings.sceneTitle")}
        location={location}
      />
      <div className="invoice_form_container">
        <form className="form-horizontal invoice_line_items w-100">
          <div className="formWrapper">
            <div className="formContent">
              <div className="form_view">
                <div className="formSheetBg pl-0 pr-0 w-100">
                  <div className="formStatusBar pl-1">
                    <div className="formStatusBarButtons">
                      <button
                        type="button"
                        variant="outlined"
                        className="btn btn-outline-save invoice-manage-button"
                        onClick={handleSubmit((formData) =>
                          saveCompanySettings(formData)
                        )}
                      >
                        <Save
                          color="success"
                          fontSize="small"
                          className="mr-1 "
                        />
                        SAVE
                      </button>
                    </div>
                  </div>
                  <div className="clearfix position-relative transactionFormSheet">
                    {displayCompanySettingsForm()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanySettings;
