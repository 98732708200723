/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";

import { AppTrlns, ChartConfig } from "config";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("resources:Dashboard.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("resources:Dashboard.sceneTitle")}
        location={location}
      />
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1 aos-init aos-animate"
              data-aos="fade-up"
            >
              <div>
                <h1>Resource Management</h1>
                <h2>
                  Assure application performance with smarter resource
                  management
                </h2>{" "}
              </div>
            </div>
            <div
              className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img aos-init aos-animate"
              data-aos="fade-up"
            >
              {" "}
              <img
                src={require("modules/resources/assets/img/dashboard.png")}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
