/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppConstants } from "modules/resources/config";

import Dashboard from "modules/resources/routes/";
import MyProfile from "modules/resources/routes/users/MyProfile";

const ResourcesNavigation = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path={AppConstants.scene.dashboard} element={<Dashboard />} />
      <Route path={AppConstants.scene.myProfile} element={<MyProfile />} />

      <Route
        index
        element={<Navigate to={`${pathname}/dashboard`} replace />}
      />
    </Routes>
  );
};
export default ResourcesNavigation;
