/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

const scene = {
  dashboard: "/dashboard",
  myProfile: "/users/myProfile",
};

const AppConstants = {
  scene,
};

export default AppConstants;
