/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Joyride from "react-joyride";

// Redux Modules
import { setUserTour } from "modules/themes/stores/themeSettings";

const TourComponent = () => {
  const joyrideRef = useRef(null);
  const dispatch = useDispatch();

  const { userTour } = useSelector((store) => store.themes.themeSettings);

  const steps = [
    {
      title: "Multi Languages",
      content: "Switch to your preferred language.",
      textAlign: "left",
      target: ".tour-step-5",
      position: "left",
      isFixed: true,
      disableBeacon: true,
    },
    {
      title: "Theme Options",
      content: "Customise your dashboard with theme settings.",
      textAlign: "left",
      target: ".tour-step-6",
      position: "left",
      isFixed: true,
      disableBeacon: true,
    },
  ];
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(0);
  }, []);

  const handleCallback = (result) => {
    if (result.action == "skip") {
      setStep(0);
      dispatch(setUserTour(false));
    } else if (result.type == "tour:end") {
      setStep(0);
      dispatch(setUserTour(false));
    } else if (result.action == "next" && result.lifecycle == "complete") {
      setStep(step + 1);
    } else if (result.action == "prev" && result.lifecycle == "complete") {
      setStep(step - 1);
    }
  };

  return (
    <Joyride
      ref={joyrideRef}
      run={userTour}
      stepIndex={step}
      autoStart={false}
      steps={steps}
      debug={false}
      callback={(result) => {
        handleCallback(result);
      }}
      disableOverlayClose={true}
      showSkipButton={true}
      disableBeacon={true}
      continuous={true}
      hideCloseButton={true}
      disableCloseOnEsc={true}
      styles={{
        options: {
          zIndex: 1000,
        },
        tooltipTitle: {
          color: "#192A53",
          fontSize: 18,
          textAlign: "left",
          padding: "0 0 10px 0",
          margin: 0,
        },
        tooltipContent: {
          color: "#000",
          fontSize: 14,
          textAlign: "left",
          padding: "10px 0 0 0",
          margin: 0,
          borderTop: `1px dashed #CCC`,
        },
        buttonSkip: {
          color: "#F75C1E",
          fontSize: 14,
          margin: 0,
          padding: 0,
        },
        buttonClose: {
          display: "none",
        },
      }}
    />
  );
};
export default TourComponent;
