/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { combineReducers } from "redux";

const initreducer = (state = { fingerPrint: "" }, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

export default (asyncReducers) =>
  combineReducers({ initreducer, ...asyncReducers });
