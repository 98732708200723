/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import axios from "axios";
import { AppTrlns, AppConstants } from "config";
import { pURL } from "utils/pivot";

//----------------------- Get All Menu Data For Manage Menu ------------------------
export const __getModuleMenuList = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getModuleListData",
        pURL({})
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Update Menu Data For Manage Menu ------------------------
export const __updateModuleMenuList = async (menuData, module) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/updateModuleListData",
        pURL({
          menuData: JSON.stringify(Object.entries(menuData)),
          module: module,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Initialize ACL  ------------------------
export const __initACL = async () => {
  return new Promise((resolve, reject) => {
    Promise.all([
      axios.post(
        AppConstants.nodePath + "settings/settings/getModuleListData",
        pURL({})
      ),
      axios.post(AppConstants.nodePath + "settings/settings/getACL", pURL({})),
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch((err) => {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Update ACL ------------------------
export const __updateACL = async (form) => {
  // console.log(form);
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/updateACL",
        pURL({
          form: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          // console.log(response.data);
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Get ACL ------------------------
export const __getACLList = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePath + "settings/settings/getACL", pURL({}))
      .then(function (response) {
        if (response && response.data) {
          // console.log(response.data);
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Get ACL Data ------------------------
export const __getACLData = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getACLDetails",
        pURL({
          id: id,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          // console.log(response.data);
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Get Template Type----------------------------
export const __getTemplates = async (type) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getTemplates",
        pURL({
          type: type,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Get Template Data----------------------------
export const __getTemplatesData = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getTemplatesData",
        pURL({
          id: id,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Save Template Data--------------------------------
export const __updateTemplatesData = async (form) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/updateTemplatesData",
        pURL({
          form: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Get Email Template Type----------------------------
export const __getEmailTemplates = async (type) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getEmailTemplates",
        pURL({
          type: type,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Get Email Template Data----------------------------
export const __getEmailTemplatesData = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/getEmailTemplatesData",
        pURL({
          id: id,
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//---------------Save Email Template Data--------------------------------
export const __updateEmailTemplatesData = async (form) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/updateEmailTemplatesData",
        pURL({
          form: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//--------------- Initialize UI Settings --------------------------------
export const __initUISettings = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({ page: "settings/initUISettings.php" })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//--------------- Update UI Settings --------------------------------
export const __updateUISettings = async (form) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "settings/updateUISettings.php",
          form: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- send Email------------------------
export const __sendEmail = async (mailData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePath + "settings/settings/sendEmail",
        pURL({
          mailData: JSON.stringify(mailData),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//--------------- Post Contact Us Form --------------------------------
export const __postContactUs = async (form) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "resources/contactUs.php",
          formData: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//--------------- Initialize Company Settings --------------------------------
export const __initCompanySettings = async (selectedCompany) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "invoice/getCompanyDetails.php",
          companyId: selectedCompany,
        })
      ),
      axios.post(
        AppConstants.nodePathCRM,
        pURL({
          page: "resources/getInvSettings.php",
          companyId: selectedCompany,
        })
      ),
    ])
      .then(
        axios.spread((...response) => {
          resolve(response);
        })
      )
      .catch(function (error) {
        reject({ msg_stat: "0", msg: "Unexpected Error. Please Retry" }); // Corrected the message
      });
  });
};

//--------------- Update Company Settings --------------------------------
export const __updateCompanySettings = async (form) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "resources/updateInvSettings.php",
          formData: JSON.stringify(form),
        })
      )
      .then(function (response) {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};
