/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppConstants } from "modules/settings/config";

import Dashboard from "modules/settings/routes/";
import ManageMenu from "modules/settings/routes/ManageMenu";
import ACL from "modules/settings/routes/ACL";
import ManageLayout from "modules/settings/routes/ManageLayout";
import ManageEmailTemplate from "modules/settings/routes/ManageEmailTemplate";
import UISettings from "modules/settings/routes/UISettings";
import CompanySettings from "modules/settings/routes/CompanySettings";

import AboutUs from "modules/settings/routes/AboutUs";
import ContactUs from "modules/settings/routes/ContactUs";
import GettingStarted from "modules/settings/routes/GettingStarted";

const SettingsNavigation = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path={AppConstants.scene.dashboard} element={<Dashboard />} />
      <Route path={AppConstants.scene.menu} element={<ManageMenu />} />
      <Route path={AppConstants.scene.acl} element={<ACL />} />
      <Route path={AppConstants.scene.uiSettings} element={<UISettings />} />
      <Route
        path={AppConstants.scene.companySettings}
        element={<CompanySettings />}
      />
      <Route
        path={AppConstants.scene.emailLayouts}
        element={<ManageEmailTemplate />}
      />
      <Route path={AppConstants.scene.pdfLayouts} element={<ManageLayout />} />

      <Route path={AppConstants.scene.aboutus} element={<AboutUs />} />
      <Route path={AppConstants.scene.contactus} element={<ContactUs />} />
      <Route
        path={AppConstants.scene.gettingstarted}
        element={<GettingStarted />}
      />
      <Route
        index
        element={<Navigate to={`${pathname}/dashboard`} replace />}
      />
    </Routes>
  );
};
export default SettingsNavigation;
