/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar AR
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

export * from "./actions";
export { default as themeSettings } from "./reducers";

export const COLLAPSED_SIDEBAR = "COLLAPSED_SIDEBAR";
export const DARK_MODE = "DARK_MODE";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const MINI_SIDEBAR = "MINI_SIDEBAR";
export const SEARCH_FORM_ENABLE = "SEARCH_FORM_ENABLE";
export const CHANGE_THEME_COLOR = "CHANGE_THEME_COLOR";
export const TOGGLE_SIDEBAR_IMAGE = "TOGGLE_SIDEBAR_IMAGE";
export const SET_SIDEBAR_IMAGE = "SET_SIDEBAR_IMAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_USER_TOUR = "SET_USER_TOUR";
export const TOGGLE_DARK_SIDENAV = "TOGGLE_DARK_SIDENAV";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const SET_SIDEBAR_MENU = "SET_SIDEBAR_MENU";
export const SET_APPLICATION_ACL = "SET_APPLICATION_ACL";
export const THIS_PAGE_ACL = "THIS_PAGE_ACL";
export const SET_MENU_TYPE = "SET_MENU_TYPE";
export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
