/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import { combineReducers } from "redux";

import { settings } from "./settings";

const reducers = combineReducers({
  settings,
});

export default reducers;
