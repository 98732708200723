import moment from "moment";
import { AppConstants } from "config";
import __configureStore from "components/InitializeReducer";

var CryptoJS = require("crypto-js");

export const setSidebarSubMenu = (response, moduleName, parent, userACL) => {
  let submenuOBJ = [];
  response.map((value, i) => {
    if (
      value.MN_Parent === parent &&
      userACL[value.MN_Id] &&
      userACL[value.MN_Id] != "NA"
    ) {
      submenuOBJ.push({
        menu_title: "Menu." + moduleName + "." + value.MN_Title,
        menu_icon: "zmdi " + value.MN_Icon,
        menu_seperator:
          value.MN_WithSeperator === 1 ? " menu-seperator " : null,
        new_item: value.MN_NewItem === 1 ? true : false,
        path: value.MN_Path,
        menu_acl: userACL[value.MN_Id],
      });
    }
  });
  return submenuOBJ;
};

export const setSidebarMenu = (response, moduleName, userACL) => {
  let menuOBJ = [];
  response &&
    userACL &&
    response.map((value, i) => {
      let child_routes = null;
      if (
        value.MN_Parent === 0 &&
        userACL[value.MN_Id] &&
        userACL[value.MN_Id] != "NA"
      ) {
        let submenuOBJ = setSidebarSubMenu(
          response,
          moduleName,
          value.MN_Id,
          userACL
        );

        if (submenuOBJ == null || submenuOBJ.length === 0) {
        } else {
          child_routes = submenuOBJ;
        }

        let menuItemData = {
          menu_id: value.MN_Id,
          menu_title: "Menu." + moduleName + "." + value.MN_Title,
          menu_icon: "zmdi " + value.MN_Icon,
          new_item: value.MN_NewItem === 1 ? true : false,
          child_routes: child_routes,
          menu_acl: userACL[value.MN_Id],
        };
        if (!child_routes) {
          menuItemData["path"] = value.MN_Path;
        } else {
          menuItemData["type_multi"] = null;
          menuItemData["open"] = value.MN_Open === 1 ? true : false;
        }
        menuOBJ.push(menuItemData);
      }
    });
  return menuOBJ;
};

export const getTodaysDate = (format) => {
  var currentdate = new Date();
  var daysList = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var date = currentdate.getDate();
  var month = monthsList[currentdate.getMonth()];
  var year = currentdate.getFullYear();
  var day = daysList[currentdate.getDay()];
  var time = currentdate.toLocaleTimeString();
  var mySQLDate = currentdate.toJSON().slice(0, 10);
  var mySQLDateTime = currentdate.toISOString().slice(0, 19).replace("T", " ");

  var dateTimeToday;

  if (format === "detailedDate") {
    dateTimeToday = ` ${day} ${date} ${month} ${year}`;
  } else if (format === "mysqlDate") {
    dateTimeToday = mySQLDate;
  } else if (format === "mysqlDateTime") {
    dateTimeToday = mySQLDateTime;
  } else if (format === "timeNow") {
    dateTimeToday = time;
  }

  return dateTimeToday;
};

export const getBrowserName = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    if (ua.match(/\bOPR\/(\d+)/) != null) {
      return { name: "Opera", version: ua.match(/\bOPR\/(\d+)/)[1] };
    } else if (ua.match(/\EdgA|Edge\/(\d+)/) != null) {
      return {
        name: "Microsoft Edge",
        version: ua.match(/\EdgA|Edge\/(\d+)/)[1],
      };
    } else if (ua.match(/\UCBrowser\/(\d+)/) != null) {
      return { name: "UC Browser", version: ua.match(/\UCBrowser\/(\d+)/)[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const encrypt = (ciphertext) => {
  let cipherString = ciphertext
    ? CryptoJS.AES.encrypt(ciphertext, AppConstants.APIKey.aesKey).toString()
    : 0;

  cipherString = cipherString
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");
  return cipherString;
};

export const decrypt = (ciphertext) => {
  if (ciphertext == null) {
    return null;
  }
  let cipherString = ciphertext
    .replace(/p1L2u3S/g, "+")
    .replace(/s1L2a3S4h/g, "/")
    .replace(/e1Q2u3A4l/g, "=");

  const DataKey = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
  const DataVector = CryptoJS.enc.Utf8.parse("1234567890123412");
  let decrypted = CryptoJS.AES.decrypt(cipherString, DataKey, {
    iv: DataVector,
  });
  return CryptoJS.enc.Utf8.stringify(decrypted);
};

export const calcDate = (date1) => {
  var dt2 = new Date();
  var dt1 = new Date(date1);

  var timeDiff = dt2.getTime() - dt1.getTime();
  var timeDiffInSeconds = Math.abs(timeDiff / 1000);
  var timeDiffInMinutes = timeDiffInSeconds / 60;
  var timeDiffInHours = timeDiffInMinutes / 60;

  dt2.setHours(0);
  dt2.setMinutes(0);
  dt2.setSeconds(0, 0);

  dt1.setHours(0);
  dt1.setMinutes(0);
  dt1.setSeconds(0, 0);

  var DayDiffTimeDiff = dt2.getTime() - dt1.getTime();
  var diffDays = Math.ceil(DayDiffTimeDiff / (1000 * 3600 * 24));

  var diffYear = (dt2.getTime() - dt1.getTime()) / 1000;
  diffYear /= 60 * 60 * 24;
  diffYear = Math.abs(Math.round(diffYear / 365.25));

  var diffMonth = (dt2.getTime() - dt1.getTime()) / 1000;
  diffMonth /= 60 * 60 * 24 * 7 * 4;
  diffMonth = Math.abs(Math.round(diffMonth));

  if (diffDays == 0 && timeDiffInSeconds < 1) {
    return "Now";
  } else if (diffDays == 0 && timeDiffInSeconds < 60) {
    return parseInt(timeDiffInSeconds) + " Seconds Ago";
  } else if (diffDays == 0 && timeDiffInMinutes < 60) {
    return parseInt(timeDiffInMinutes) + " Minutes Ago";
  } else if (diffDays == 0 && timeDiffInHours < 12) {
    return parseInt(timeDiffInHours) + " Hours Ago";
  } else if (diffDays == -1) {
    return "Yesterday";
  } else if (diffDays > -1 && diffDays <= 31) {
    return parseInt(diffDays) + " Days Ago";
  } else if (diffDays > 31 && diffMonth > 0 && diffYear == 0) {
    return parseInt(diffMonth) + " Month Ago";
  } else {
    return parseInt(diffYear) + " Year Ago";
  }
};

export const calcDateDiff = (date, format) => {
  var dt2 = new Date();
  var dt1 = new Date(date);

  dt2.setHours(0);
  dt2.setMinutes(0);
  dt2.setSeconds(0, 0);

  dt1.setHours(0);
  dt1.setMinutes(0);
  dt1.setSeconds(0, 0);

  var today = moment(dt2);
  var newDate = moment(dt1, format);
  let dateDiff = newDate.diff(today, "days");

  return dateDiff;
};

export const CurrencyConverter = (value, roundoff) => {
  return parseFloat(value).toFixed(roundoff);
};

const verifyAuthentication = (thisReducer) => {
  if (
    sessionStorage.getItem("user_id") &&
    sessionStorage.getItem("comp_id") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("comp_id") &&
    localStorage.getItem("mode") == "si" &&
    sessionStorage.getItem("user_id") == localStorage.getItem("user_id") &&
    sessionStorage.getItem("comp_id") == localStorage.getItem("comp_id") &&
    Number.isInteger(parseInt(decrypt(sessionStorage.getItem("user_id")))) &&
    Number.isInteger(parseInt(decrypt(sessionStorage.getItem("comp_id")))) &&
    decrypt(thisReducer["US_Id"]) ==
      decrypt(sessionStorage.getItem("user_id")) &&
    decrypt(thisReducer["CMP_Id"]) == decrypt(sessionStorage.getItem("comp_id"))
  ) {
    return true;
  } else {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
};

/* Populate URL*/
export const pURL = (params) => {
  let thisReducer =
    __configureStore.getState().themes.generalSettings.appConstants;

  if (verifyAuthentication(thisReducer)) {
    params["userId"] = sessionStorage.getItem("user_id");
    params["compId"] = sessionStorage.getItem("comp_id");

    return params;
  }
};

/* Append URL - For Uploading Files*/
export const aURL = (params) => {
  let thisReducer = __configureStore.getState().settings.settings.appConstants;

  if (verifyAuthentication(thisReducer)) {
    params.append("userId", sessionStorage.getItem("user_id"));
    params.append("compId", sessionStorage.getItem("comp_id"));
    params.append("timeZone", thisReducer["TimeZone"]);

    return params;
  }
};

export const formatDate = (date, format, addDays = null) => {
  var d;

  if (addDays) {
    d = new Date(new Date(date).getTime() + 5 * 24 * 60 * 60 * 1000);
  } else {
    d = new Date(date);
  }

  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return format == "YYYY-MM-DD"
    ? [year, month, day].join("-")
    : [day, month, year].join("-");
};
