/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// Redux Modules
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppTrlns } from "config";
import { decrypt } from "utils/pivot";

import { getApps } from "modules/themes/stores/generalSettings";
import { collapsedSidebarAction } from "modules/themes/stores/themeSettings";

const Applications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { appsList } = useSelector((store) => store.themes.generalSettings);

  useEffect(() => {
    dispatch(getApps());
  }, []);

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("Dashboard.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="si_apps">
            <a
              href="https://crm.simpleplans.com.au/"
              className="si_apps_menuitem pulse"
              target="_blank"
            >
              <div
                style={{
                  backgroundImage: `url(${require(`assets/application-icons/crm_icon.png`)})`,
                }}
                className="si_apps_icon"
              />
              <div className="si_apps_caption">
                <p className="fw-500 textUpperCase">CRM</p>
              </div>
            </a>
            {appsList &&
              appsList.map((value, i) => {
                let backgroundImage = require(`assets/application-icons/${value.APP_Img}`);
                return (
                  <a
                    href={value.APP_Path}
                    className="si_apps_menuitem pulse"
                    key={i}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${backgroundImage})`,
                      }}
                      className="si_apps_icon"
                    />
                    <div className="si_apps_caption">
                      <p className="fw-500 textUpperCase">{value.APP_Name}</p>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
