/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

const scene = {
  dashboard: "/dashboard",
  listInvoices: "/listInvoices",
  manageInvoice: "/manageInvoice",
};

const AppConstants = {
  scene,
};

export default AppConstants;
