/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { Helmet } from "react-helmet";
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";
import FieldError from "components/FieldError";

import { FormControl, Select, Button } from "@mui/material";

import JoditEditor from "jodit-react";

import {
  getEmailTemplates,
  getEmailTemplatesData,
  updateEmailTemplatesData,
} from "modules/settings/stores/settings";
import { setLoader } from "modules/themes/stores/themeSettings";
import { AppTrlns } from "config";
import { AppConstants as MainAppConstants } from "config";

const ManageEmailTemplate = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const editor = useRef(null);
  const [templateType, setTemplateType] = useState(1);
  const [content, setContent] = useState("");
  const [editId, setEditId] = useState(
    location?.state?.ID ? location.state.ID : ""
  );
  const dummyData = {
    "{{logo}}": `<img src="${MainAppConstants.fsPath}accounts/dummy-logo-transparent-png.png" alt="Logo" width="152px" height="91px"/>`,
    "{{no}}": "#0001",
    "{{date}}": "05.01.2022",
    "{{to_address}}":
      "Jennifer Richards <br>365 Bloor Street East, Toronto, <br>Ontario, M4W 3L4, <br>Canada",
    "{{pay_to}}":
      "Biman Airlines <br>237 Roanoke Road, North York, <br>Ontario, Canada <br>demo@email.com",
    "{{from_address}}":
      "Jennifer Richards <br>365 Bloor Street East, Toronto, <br>Ontario, M4W 3L4, <br>Canada",
    "{{shipping_address}}":
      "Biman Airlines <br>237 Roanoke Road, North York, <br>Ontario, Canada <br>demo@email.com",
    "{{#each items}}": "",
    "{{/each}}": "",
    "{{this.itemName}}": "Large Cabinet",
    "{{this.itemDescription}}": "Large Cabinet",
    "{{this.itemQty}}": "1",
    "{{this.itemUom}}": "Units",
    "{{this.itemPackageQty}}": "1",
    "{{this.itemPackage}}": "Box",
    "{{rfq_terms}}": "Sample Terms",
    "{{this.itemUnitPrice}}": "320.00",
    "{{this.itemDiscount}}": "0.00",
    "{{this.itemTax}}": "GST 5%",
    "{{this.itemSubTotal}}": "320.00",
    "{{payment_terms}}": "Immediate Payment",
    "{{sub_total}}": "320.00",
    "{{tax_amount}}": "16.00",
    "{{total_amount}}": "336.00",
    "{{invoice_sub_total}}":
      "<table>\
      <tbody>\
        <tr class='cs-border_left'>\
          <td class='cs-width_3 cs-semi_bold cs-primary_color cs-focus_bg'>Subtoal</td>\
          <td class='cs-width_3 cs-semi_bold cs-focus_bg cs-primary_color cs-text_right'>$1140</td>\
        </tr>\
        <tr class='cs-border_left'>\
          <td class='cs-width_3 cs-semi_bold cs-primary_color cs-focus_bg'>Tax</td>\
          <td class='cs-width_3 cs-semi_bold cs-focus_bg cs-primary_color cs-text_right'>-$20</td>\
        </tr>\
    </tbody>\
  </table>",
    "{{total_amt}}": "$1160",
    "{{payment_details}}":
      "<li><strong>Account No:</strong> 00 123 647 840</li>\
    <li><strong>Account Name:</strong> Biman Airlines</li>\
    <li><strong>Branch Name:</strong> xyz</li>",
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    dispatch(getEmailTemplates(templateType));
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", trackBodyClick);

    return function cleanup() {
      window.removeEventListener("click", trackBodyClick);
    };
  }, []);

  const { emailTemplateList } = useSelector((store) => store.settings.settings);

  const replacePreviewHtml = (text) => {
    let replacedText = text;
    Object.keys(dummyData).forEach(function (key) {
      replacedText = replacedText.replaceAll(key, dummyData[key]);
    });
    return replacedText;
  };

  const trackBodyClick = () => {
    if (document.getElementsByClassName("jodit-dialog__content").length > 0) {
      let previewHtml = replacePreviewHtml(
        document.getElementsByClassName("jodit-dialog__content")[0].innerHTML
      );

      document.getElementsByClassName("jodit-dialog__content")[0].innerHTML =
        previewHtml;
    }
  };

  const handleOnSave = (form) => {
    form["content"] = escapeHtml(content);
    dispatch(setLoader(true));
    dispatch(updateEmailTemplatesData(form))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        setContent(null);
        reset();
        clearErrors();
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const escapeHtml = (text) => {
    var map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    };

    return text.replace(/[&<>"']/g, function (m) {
      return map[m];
    });
  };

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  const handleOnError = (errors, e) => {
    console.log("--------handleOnError called------------", errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });

    if (name == "layoutType") {
      dispatch(getEmailTemplates(value));
    }
    if (name == "title") {
      if (value != 0) {
        dispatch(setLoader(true));
        dispatch(getEmailTemplatesData(value))
          .catch((err) => {
            alert(err.msg);
          })
          .then((response) => {
            setContent(htmlDecode(response[0]["SET_Content"]));
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      } else {
        setContent(null);
      }
    }
  };

  const config = {
    removeButtons: ["image", "video", "link", "about"],
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  return (
    <div className="container-fluid ecom-dashboard-wrapper mb-10">
      <Helmet>
        <title>{AppTrlns("settings:manageEmailTemplate.sceneTitle")}</title>
        <meta name="description" content="Layout" />
      </Helmet>
      <PageTitleBar
        title={
          <TextTranslation text={"settings:manageEmailTemplate.sceneTitle"} />
        }
        location={location}
      />

      <form onSubmit={handleSubmit(handleOnSave, handleOnError)}>
        <div className="row modulePageBody mb-4">
          <div className="col-md-12">
            <div className="card m-2">
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageEmailTemplate.formLabels.layoutType"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="layoutType"
                            error={errors?.layoutType ? true : false}
                            {...register("layoutType", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="1" value="1">
                              Invoice
                            </option>
                          </Select>
                          {errors?.layoutType?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageEmailTemplate.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageEmailTemplate.formLabels.title"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="title"
                            error={errors?.title ? true : false}
                            {...register("title", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="0" value="">
                              Select
                            </option>
                            {emailTemplateList &&
                              Object.keys(emailTemplateList).map((key) => {
                                return (
                                  <option
                                    key={emailTemplateList[key].SET_Id}
                                    value={emailTemplateList[key].SET_Id}
                                  >
                                    {emailTemplateList[key].SET_Name}
                                  </option>
                                );
                              })}
                          </Select>
                          {errors?.title?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageEmailTemplate.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-3">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageEmailTemplate.formLabels.layoutStatus"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="layoutStatus"
                            error={errors?.layoutStatus ? true : false}
                            {...register("layoutStatus", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="1" value="1">
                              Active
                            </option>
                            <option key="2" value="2">
                              Blocked
                            </option>
                          </Select>
                          {errors?.layoutStatus?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageEmailTemplate.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-1">
                        <Button
                          type="submit"
                          size="small"
                          className="btn btn-success ml-auto mr-2 text-light"
                          variant="contained"
                          onClick={handleSubmit(handleOnSave)}
                        >
                          <TextTranslation
                            text={AppTrlns(
                              "settings:manageEmailTemplate.buttonText.Save"
                            )}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageEmailTemplate.formLabels.layoutContent"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <JoditEditor
                            ref={editor}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)}
                            value={content}
                          />
                          {/* <TextField
                            hidden
                            //sx={{ visibility: "hidden" }}
                            error={errors?.layoutContent ? true : false}
                            {...register("layoutContent", {
                              //required: true,
                            })}
                            type="text"
                            rows={4}
                            id="layoutContent"
                            variant="outlined"
                            value={content}
                            fullWidth
                          /> */}
                          {errors?.layoutContent?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageEmailTemplate.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ManageEmailTemplate;
