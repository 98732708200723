/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";
import FieldError from "components/FieldError";
import { setLoader } from "modules/themes/stores/themeSettings";

import { AppTrlns, ChartConfig } from "config";

import { Box, Tab, TextField, InputAdornment, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import {
  initUISettings,
  updateUISettings,
} from "modules/settings/stores/settings";

const UISettings = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = React.useState("1");

  const { userUISettings } = useSelector((store) => store.settings.settings);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initUISettings()).then(() => {
      dispatch(setLoader(false));
    });
  }, []);

  useEffect(() => {
    if (
      userUISettings &&
      userUISettings.invoices &&
      userUISettings.invoices.invoiceBoxWidth
    ) {
      setValueInvoices(
        "invoiceBoxWidth",
        userUISettings.invoices.invoiceBoxWidth
      );
    }
  }, [userUISettings]);

  const {
    register: registerInvoices,
    handleSubmit: handleSubmitInvoices,
    setValue: setValueInvoices,
    formState: { errors: errorsInvoices },
  } = useForm({
    defaultValues: {},
  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const updateInvoiceSettings = (form) => {
    dispatch(setLoader(true));
    dispatch(updateUISettings(form))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then(() => {
        dispatch(setLoader(false));
        NotificationManager.success(
          <TextTranslation
            text={"settings:UISettings.Notifications.invoiceSavedSuccessfully"}
          />
        );
      })
      .finally(() => {});
  };
  const handleOnInvoiceError = () => {};

  const handleChange = (e) => {
    setValueInvoices(e.target.name, e.target.value);
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("settings:UISettings.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:UISettings.sceneTitle")}
        location={location}
      />
      <div className="container-fluid mb-50">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabIndex}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="Application Modules"
                    >
                      <Tab label="Invoices" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <TextField
                          hidden
                          value="invoices"
                          {...registerInvoices("module")}
                        />
                        <TextField
                          type="number"
                          size="small"
                          label={
                            <TextTranslation
                              text={
                                "settings:UISettings.formLabels.invoiceBoxWidth"
                              }
                            />
                          }
                          variant="outlined"
                          fullWidth
                          error={errorsInvoices?.invoiceBoxWidth ? true : false}
                          {...registerInvoices("invoiceBoxWidth", {
                            required: true,
                          })}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            if (e.target.value < 1200) {
                              setValueInvoices("invoiceBoxWidth", 1200);
                            }
                          }}
                          defaultValue={1200}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">px</InputAdornment>
                            ),
                            inputProps: {
                              min: 1200,
                            },
                          }}
                          helperText="Minimum Box Width Should Be 1200 PX"
                        />
                        {errorsInvoices?.invoiceBoxWidth?.type ===
                          "required" && (
                          <FieldError
                            errorText={
                              <TextTranslation
                                text={"settings:UISettings.errorText.required"}
                              />
                            }
                          />
                        )}
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-12 form-group mt-50 mb-3 d-flex justify-content-end">
                        <Button
                          className="h-100"
                          color="success"
                          variant="contained"
                          onClick={handleSubmitInvoices(
                            updateInvoiceSettings,
                            handleOnInvoiceError
                          )}
                        >
                          <TextTranslation
                            text={"settings:UISettings.buttonText.SaveInvoice"}
                          />
                        </Button>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UISettings;
