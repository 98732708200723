/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./utils/reportWebVitals";
import * as serviceWorker from "./utils/serviceWorker";

import MainApp from "./App";
const rootEl = document.getElementById("root");

ReactDOM.render(<MainApp />, rootEl);

reportWebVitals();
serviceWorker.unregister();
