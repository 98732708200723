/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { useLocation } from "react-router-dom";

import List from "@mui/material/List";
import { useSelector, useDispatch } from "react-redux";

import NavMenuItem from "./NavMenuItem";
import { setSidebarMenu } from "utils/pivot";

// Redux Modules
import {
  collapsedSidebarAction,
  onToggleMenu,
} from "modules/themes/stores/themeSettings";

const SidebarContent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let moduleName = pathname.split("/")[1];

  const { sidebarMENU, userACL } = useSelector(
    (store) => store.themes.themeSettings
  );

  const menuOBJ =
    sidebarMENU && sidebarMENU[moduleName] && userACL && userACL[moduleName]
      ? setSidebarMenu(
          JSON.parse(sidebarMENU[moduleName]),
          moduleName,
          userACL[moduleName]
        )
      : [];

  const toggleMenu = (menu, moduleName) => {
    let data = {
      menu,
      moduleName,
    };

    dispatch(onToggleMenu(data));
  };

  const requestMenu = () => {
    if (window.innerWidth < 1199) {
      dispatch(collapsedSidebarAction(true));
    }
  };

  return (
    <div className="rct-sidebar-nav">
      <nav className="navigation">
        <List className="rct-mainMenu p-0 m-0 list-unstyled">
          {menuOBJ &&
            menuOBJ.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                module={moduleName}
                key={key}
                onToggleMenu={(e) => {
                  toggleMenu(menu, moduleName);
                }}
                onRequestMenu={() => requestMenu()}
              />
            ))}
        </List>
      </nav>
    </div>
  );
};

export default SidebarContent;
