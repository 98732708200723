/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

import { AppConstants } from "config";

import { logout } from "modules/resources/stores/resources";
import { setLoader } from "modules/themes/stores/themeSettings";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog/DeleteConfirmationDialog";

import { decrypt, encrypt } from "utils/pivot";

const ModuleHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);

  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
  };

  const confirmLogout = () => {
    dispatch(setLoader(true));
    setConfirmDialog(false);

    dispatch(logout())
      .catch((err) => {})
      .then(() => {
        dispatch(setLoader(false));
        navigate("/");
      })
      .finally(() => {});
  };
  const confirmCancel = () => {
    setConfirmDialog(false);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    setConfirmDialog(true);
  };

  const renderProfileDropdown = () => {
    if (profileDropdownVisible) {
      return (
        <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1 show">
          <Link
            className="dropdown-item d-flex align-items-center"
            to={"/resources/users/myProfile"}
            state={{ selectedTab: 0 }}
          >
            Profile <i className="zmdi zmdi-account ms-auto fs-16px my-n1" />
          </Link>
          <div className="dropdown-divider" />
          <a
            href="#"
            className="dropdown-item d-flex align-items-center"
            onClick={(e) => logoutUser(e)}
          >
            Logout <i className="zmdi zmdi-power-off ms-auto fs-16px my-n1" />
          </a>
        </div>
      );
    }
  };

  return (
    <div id="header" className="dashboard-module-header">
      <div className="brand">
        <a className="brand-logo  d-sm-block d-none">
          <span className="brand-text">SIMPLE PLANS</span>
        </a>
      </div>
      <div className="menu">
        <div
          className="menu-item dropdown dropdown-mobile-full"
          onClick={toggleProfileDropdown}
        >
          <a
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-img online">
              <img
                src={`${appConstants["US_Avatar"]}`}
                alt="Profile"
                height={60}
              />
            </div>
            <div className="menu-text d-sm-block d-none">
              {appConstants["US_Name"]}
            </div>
          </a>
          {renderProfileDropdown()}
        </div>
      </div>
      {confirmDialog ? (
        <DeleteConfirmationDialog
          title="Quit Simple Plans"
          message="Are You Sure Want To SignOut Simple Plans."
          onConfirm={() => confirmLogout()}
          onCancel={() => confirmCancel()}
        />
      ) : null}
    </div>
  );
};

export default ModuleHeader;
