/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import { combineReducers } from "redux";

import { invoices } from "./invoices";

const reducers = combineReducers({
  invoices,
});

export default reducers;
