/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { matchPath } from "react-router";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import { AppConstants } from "config";
import { decrypt, encrypt } from "utils/pivot";

// ---------- Theme provider -------------
import ThemeVendor from "themes/ThemeVendor";

// ---------- Default Layout -------------
import DefaultAppLayout from "components/DefaultAppLayout";

// Components
import SignIn from "modules/resources/routes/SignIn";
import SignUp from "modules/resources/routes/SignUp";
import ForgotPassword from "modules/resources/routes/ForgotPassword";
import TermsOfService from "modules/resources/routes/TermsOfService";
import NotFound from "modules/themes/routes/NotFound";

import Themes from "modules/themes";
import Invoices from "modules/invoices";
import Resources from "modules/resources";
import Settings from "modules/settings";

import InitializeSocket from "utils/socketIO";

const Navigation = () => {
  const { pathname } = useLocation();

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  useEffect(() => {
    function authenticationHandler(event) {
      if (event.key == "mode") {
        if (event.oldValue == "so" && event.newValue == "si") {
          //Move Local To Session Storage On SignIn With Multiple Tabs
          sessionStorage.setItem("user_id", localStorage.getItem("user_id"));
          sessionStorage.setItem("comp_id", localStorage.getItem("comp_id"));
        }
        window.location.reload();
      }
    }

    //Storage Change To Check SignIn & SignOut
    window.addEventListener("storage", authenticationHandler);
    return () => {
      window.removeEventListener("storage", authenticationHandler);
    };
  }, []);

  //Redirect Stack On Root Page
  if (pathname === "/") {
    if (
      sessionStorage.getItem("user_id") &&
      sessionStorage.getItem("comp_id") &&
      localStorage.getItem("user_id") &&
      localStorage.getItem("comp_id")
    ) {
      localStorage.setItem("redirectSignUp", null);
      return <Navigate to="/themes/applications" />;
    } else {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("mode", "so");
      if (
        localStorage.getItem("redirectSignUp") &&
        localStorage.getItem("redirectSignUp") != null
      ) {
        return <Navigate to="resources/signup" />;
      } else {
        return <Navigate to="resources/signIn" />;
      }
    }
  }

  //To Check Session, Local Storage & AppConstants
  if (
    sessionStorage.getItem("user_id") &&
    sessionStorage.getItem("comp_id") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("comp_id") &&
    Object.values(AppConstants.scene.public).indexOf(pathname) > -1
  ) {
    return <Navigate to="themes/applications" replace />;
  }

  const protectedStack = () => {
    localStorage.setItem("redirectSignUp", null);
    return (
      <DefaultAppLayout>
        <Routes>
          <Route
            path={AppConstants.scene.protected.moduleThemes}
            element={<Themes />}
          />
          <Route
            path={AppConstants.scene.protected.moduleInvoices}
            element={<Invoices />}
          />
          <Route
            path={AppConstants.scene.protected.moduleResources}
            element={<Resources />}
          />
          <Route
            path={AppConstants.scene.protected.moduleSettings}
            element={<Settings />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </DefaultAppLayout>
    );
  };

  const publicStack = () => {
    return (
      <Fragment>
        <Routes>
          <Route path={AppConstants.scene.public.signIn} element={<SignIn />} />
          <Route path={AppConstants.scene.public.signUp} element={<SignUp />} />
          <Route
            path={AppConstants.scene.public.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route
            path={AppConstants.scene.public.termsofservice}
            element={<TermsOfService />}
          />

          <Route
            path="*"
            element={
              <Navigate
                to={
                  localStorage.getItem("redirectSignUp")
                    ? AppConstants.scene.public.signUp
                    : AppConstants.scene.public.signIn
                }
                replace
              />
            }
          />
        </Routes>
      </Fragment>
    );
  };

  const sessionManager = () => {
    //Functionality will work while copying or duplicating tabs
    //Centralised LOCAL STORAGE will be copied to SESSION STORAGE
    if (
      !sessionStorage.getItem("user_id") &&
      !sessionStorage.getItem("comp_id") &&
      localStorage.getItem("user_id") &&
      localStorage.getItem("comp_id")
    ) {
      sessionStorage.setItem("user_id", localStorage.getItem("user_id"));
      sessionStorage.setItem("comp_id", localStorage.getItem("comp_id"));
    }
  };

  return (
    <ThemeVendor>
      <NotificationContainer />
      <InitializeSocket />

      {sessionManager()}

      {/* To Check Whether Session Exists */}
      {(localStorage.getItem("user_id") &&
        localStorage.getItem("comp_id") &&
        sessionStorage.getItem("user_id") &&
        sessionStorage.getItem("comp_id")) ||
      sessionStorage.getItem("sessionFail")
        ? protectedStack()
        : publicStack()}
    </ThemeVendor>
  );
};

export default Navigation;
