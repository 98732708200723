/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "reactstrap";

import { AppConstants } from "config";

import DeleteConfirmationDialog from "../DeleteConfirmationDialog/DeleteConfirmationDialog";

// Redux Modules
import { logout } from "modules/resources/stores/resources";
import { setLoader } from "modules/themes/stores/themeSettings";

const UserBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDropdownMenu, setUserDropdownMenu] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  const confirmLogout = () => {
    dispatch(setLoader(true));
    setConfirmDialog(false);

    dispatch(logout())
      .catch((err) => {})
      .then(() => {
        dispatch(setLoader(false));
        navigate("/");
      })
      .finally(() => {});
  };
  const confirmCancel = () => {
    setConfirmDialog(false);
  };

  const logoutUser = (e) => {
    setUserDropdownMenu(false);
    e.preventDefault();
    setConfirmDialog(true);
  };
  const onMyProfile = (e) => {
    setUserDropdownMenu(false);
    navigate("/resources/users/myProfile");
  };
  const toggleUserDropdownMenu = () => {
    setUserDropdownMenu(!userDropdownMenu);
  };

  return (
    <div className="top-sidebar">
      <div className="sidebar-user-block">
        <Dropdown
          isOpen={userDropdownMenu}
          toggle={() => toggleUserDropdownMenu()}
          className="rct-dropdown"
        >
          <DropdownToggle tag="div" className="d-flex align-items-center">
            <div className="w-20  user-profile">
              <img
                src={`${appConstants["US_Avatar"]}`}
                alt="user profile"
                className="img-fluid rounded-circle"
                width="50"
                height="100"
              />
            </div>
            <div className="w-80 user-info d-flex justify-content-between align-items-center">
              <span className="user-name ml-2 text-truncate">
                {appConstants["US_Name"]}
              </span>
              <i className="zmdi zmdi-chevron-down dropdown-icon mx-2"></i>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <ul className="list-unstyled mb-0 userProfileList">
              <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                <p className="text-white mb-0 fs-14">
                  {appConstants["US_Username"]}
                </p>
              </li>
              <li>
                {/* <Link
                  to={"/resources/users/myProfile"}
                  state={{ selectedTab: 0 }}
                > */}
                <a href="#" onClick={(e) => onMyProfile(e)}>
                  <i className="zmdi zmdi-account text-primary mr-3"></i>
                  <span>Profile</span>
                </a>
                {/* </Link> */}
              </li>
              <li className="border-top">
                <a href="#" onClick={(e) => logoutUser(e)}>
                  <i className="zmdi zmdi-power text-danger mr-3"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </DropdownMenu>
        </Dropdown>
      </div>
      {confirmDialog ? (
        <DeleteConfirmationDialog
          title="Quit Simple Plans"
          message="Are You Sure Want To SignOut Simple Plans."
          onConfirm={() => confirmLogout()}
          onCancel={() => confirmCancel()}
        />
      ) : null}
    </div>
  );
};
export default UserBlock;
