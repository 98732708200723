/**
 * Search Form
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Input } from "reactstrap";
import { CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

// Redux Modules
import { getSearchData } from "modules/themes/stores/generalSettings";

const SearchForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchList, setSearchList] = useState(false);

  const { searchData } = useSelector((store) => store.themes.generalSettings);
  const { locale } = useSelector((store) => store.themes.themeSettings);
  let menuTranslationFile = require("modules/themes/translations/" +
    locale +
    ".json")["Menu"];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchString) {
        setLoading(true);
        dispatch(getSearchData(searchString))
          .catch((err) => {
            alert(err.msg); // eslint-disable-line no-alert
          })
          .then(() => {})
          .finally(() => {
            setSearchList(true);
            setLoading(false);
          });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchString]);

  const searchChange = (searchTextData, thisTitle) => {
    setSearchList(false);
    document.getElementById("search-input").value = thisTitle;
    navigate(searchTextData, { replace: true });
  };

  return (
    <div className="search-wrapper">
      <Input
        id="search-input"
        type="search"
        className="search-input-lg"
        placeholder="Search menu..."
        onKeyUp={(e) => {
          setSearchString(e.target.value.trim());
        }}
        onFocus={(event) => {
          setSearchList(true);
          event.target.select();
        }}
        onBlur={() => {
          setTimeout(() => {
            setSearchList(false);
          }, 200);
        }}
      />
      {loading ? (
        <CircularProgress
          color="primary"
          size={18}
          className="search-progress-icon"
        />
      ) : null}
      {searchList && searchString != "" && searchData.length != 0 ? (
        <div className="search-data-list">
          {searchData.map(function (element, i) {
            //console.log(element);
            let thisTitle = `${element["Module_Name"]} / ${
              menuTranslationFile[element["Module_Menu"]][element["MN_Title"]]
            }`;
            return (
              <div
                className="search-data-item"
                onClick={() => searchChange(element["MN_Path"], thisTitle)}
                key={i}
              >
                {thisTitle}
              </div>
            );
          })}
        </div>
      ) : searchList && searchString != "" && searchData.length == 0 ? (
        <div className="search-data-list">
          <div className="search-data-no-item">No Results</div>
        </div>
      ) : null}
    </div>
  );
};
export default SearchForm;
