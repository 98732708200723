/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import { Tooltip, Popper, Autocomplete, TextField } from "@mui/material";
import { Home, MenuBook, PendingActions, MoreHoriz } from "@mui/icons-material";

import { decrypt, encrypt } from "utils/pivot";
import { updateAppConstants } from "modules/themes/stores/generalSettings";

const PageTitleBar = ({
  title,
  location,
  directory = null,
  pathDMS = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const path = location.pathname.substr(1);
  const subPath = path.split("/");

  /********************************************Store**************************************************/

  const { appConstants } = useSelector((store) => store.themes.generalSettings);
  /********************************************Store**************************************************/

  const [enableBreadCrumb, setEnableBreadCrumb] = useState(true);

  //---------- Set Display String --------------
  const getDisplayString = (sub) => {
    sub = sub.replace(/([A-Z])/g, " $1").trim();
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  };

  //----------- Set URL String -----------------
  const getUrlString = (path, sub, index) => {
    if (index === 0) {
      return "/" + sub;
    } else {
      return "/" + path.split(sub)[0] + sub;
    }
  };

  //----------- Set Directory Path String -----------------
  const getDirString = (path, id) => {
    path = path.replace(/\/[^\/]*$/, "/" + id);
    return path;
  };

  return (
    <div className="page-title d-flex justify-content-between align-items-center">
      {title && (
        <div className="page-title-wrap">
          <i className="ti-angle-left"></i>
          <h2 className="">{title}</h2>
        </div>
      )}

      <div className="book-breadcrumb-container">
        {enableBreadCrumb && (
          <Breadcrumb className="mb-0 tour-step-7" tag="nav">
            <BreadcrumbItem active={false} tag={Link} key={"root"} to={"/"}>
              <Tooltip
                title="Home"
                placement="bottom"
                className="pointerCursor"
              >
                <Home fontSize="small" />
              </Tooltip>
            </BreadcrumbItem>
            {subPath.map((sub, index) => {
              return (
                <BreadcrumbItem
                  active={index === 0}
                  tag={index !== 0 ? "span" : Link}
                  key={index}
                  to={getUrlString(path, sub, index)}
                  id={index === 0 ? "navbar_path_name" : "navbar_path_link"}
                >
                  {getDisplayString(sub)}
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )}
      </div>
    </div>
  );
};

export default PageTitleBar;
