import React, { Component } from "react";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";

const NotFound = () => {
  return (
    <div className="session-inner-wrapper">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
          <div className="error-body text-center">
            <h2 className="oops">Oops.. </h2>
            <h2 className="bold mb-0">404</h2>
            <h2 className="error-msg mb-30">Sorry, page not found</h2>
            <h4 className="mb-50">
              {" "}
              Looks like you have navigated too far from Federation Space.{" "}
              <br />
              Our Application cannot help you here.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
