/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {} from "utils/socketIO/dependencies";

import { AppConstants } from "config";
import { io } from "socket.io-client";
import { encrypt, decrypt } from "utils/pivot";

import { SET_SOCKET_IO, CLEAR_SOCKET_IO } from "modules/themes/stores/socketIO";

const InitializeSocket = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { _socketIO } = useSelector((store) => store.themes.socketIO);
  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  useEffect(() => {
    if (Object.keys(appConstants).length > 0) {
      //Re Checking To Check Used ID On Logout (Dont Remove)
      let userID = decrypt(appConstants["US_Id"].toString());

      if (!_socketIO && userID) {
        dispatch({
          type: SET_SOCKET_IO,
          payload: io(AppConstants.nodePath, {
            query: "id=" + userID,
          }),
        });
      }

      if (_socketIO && !userID) {
        _socketIO.emit("forceDisconnect");
        dispatch({
          type: CLEAR_SOCKET_IO,
          payload: null,
        });
      }

      if (_socketIO && userID) {
        mountIO();
      }
    }
  }, [_socketIO, appConstants]);

  const mountIO = () => {
    sessionStorage.setItem("chat_user", null);
  };

  return null;
};

export default InitializeSocket;
