/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";

import { useForm } from "react-hook-form";

import { NotificationManager } from "react-notifications";

import {
  Tabs,
  Tab,
  Box,
  tabsClasses,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TableCell,
  Tooltip,
  Badge,
} from "@mui/material";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";

import { AppTrlns } from "config";

import FieldError from "components/FieldError";

import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { useTreeItem, treeItemClasses } from "@mui/lab/TreeItem";
import clsx from "clsx";

import MUIDataTable from "mui-datatables";

import { setLoader } from "modules/themes/stores/themeSettings";

import {
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
  Edit,
} from "@mui/icons-material";

import {
  getACLList,
  initACL,
  updateACL,
  getACLData,
} from "modules/settings/stores/settings";

import PropTypes from "prop-types";

const ACL = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { moduleListData, menuListData, ACLList } = useSelector(
    (store) => store.settings.settings
  );

  let nonChildDropdown = [
    {
      key: "NA",
      value: "No Access",
    },
    {
      key: "AC",
      value: "Access",
    },
  ];

  let childDropdown = [
    {
      key: "NA",
      value: "No Access",
    },
    {
      key: "VO",
      value: "View Only",
    },
    {
      key: "VM",
      value: "View & Manage",
    },
  ];

  const {
    register,
    unregister,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ACLId: "",
      ACLName: "",
      ACLStatus: "1",
    },
  });

  const [activeTab, setActiveTab] = useState(0);
  const [editACLData, setEditACLData] = useState([]);
  const [expandedTree, setExpandedTree] = useState([]);
  const [dummyState, setDummyState] = useState(null);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initACL()).then(() => {
      dispatch(setLoader(false));
    });
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div className="w-100 d-flex justify-content-between mt-1 mb-1 menuRow">
          <div className="menuLabelContainer">
            <div
              onClick={handleExpansionClick}
              className={classes.iconContainer}
            >
              {icon}
              {label[0]}
            </div>
          </div>
          <div
            className={classes.label}
            onClick={handleSelectionClick}
            component="div"
          >
            <div className="d-flex menuInputContainer">
              <div className="flex-grow-1 menuAclContainer">{label[1]}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const StyledTreeItem = styled((props) => (
    <TreeItem ContentComponent={CustomContent} {...props} />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  const cancelMenuGrid = () => {
    clearErrors();
    reset();
    setEditACLData([]);
  };

  const updateMenuGrid = (form) => {
    dispatch(setLoader(true));
    dispatch(updateACL(form))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then(() => {})
      .finally(() => {
        getACLList();
        cancelMenuGrid();
        dispatch(setLoader(false));
        NotificationManager.success(
          <TextTranslation text={"settings:ACL.Notifications.statusMessage"} />
        );
      });
  };
  const handleOnError = () => {};

  const isChildExists = (MNId, menuOBJ) => {
    let childExists = false;
    menuOBJ.map((childValue, i) => {
      if (MNId == childValue["MN_Parent"]) {
        childExists = true;
      }
    });
    return childExists;
  };

  const renderMenu = (parent, key, menuOBJ) => {
    return (
      menuOBJ &&
      menuOBJ.map((value, i) => {
        if (parent === value["MN_Parent"]) {
          let childItems = isChildExists(value["MN_Id"], menuOBJ);
          // if (!expandedTree.includes(`${key}_${value["MN_Id"]}`)) {
          //   setExpandedTree((expandedTree) => [
          //     ...expandedTree,
          //     `${key}_${value["MN_Id"]}`,
          //   ]);
          // }

          return (
            <StyledTreeItem
              key={`${key}_${value["MN_Id"]}`}
              nodeId={`${key}_${value["MN_Id"]}`}
              label={renderMenuRow(
                value["MN_Title"],
                childItems,
                value["MN_Id"],
                value["Module_Menu"]
              )}
            >
              {childItems ? renderMenu(value["MN_Id"], key, menuOBJ) : null}
            </StyledTreeItem>
          );
        }
      })
    );
  };

  const getParentStatus = (aclItem, mName) => {
    let [modName, modIndex] = aclItem.split("__");

    return Object.entries(moduleListData[mName])
      .map(([key, value]) => {
        if (
          (parseInt(value["MN_Id"]) == parseInt(modIndex) &&
            parseInt(value["MN_Parent"]) != 0 &&
            editACLData[modName + "__" + value["MN_Parent"]] != "NA") ||
          (parseInt(value["MN_Id"]) == parseInt(modIndex) &&
            parseInt(value["MN_Parent"]) == 0)
        ) {
          return true;
        }
      })
      .filter((n) => n);
  };

  const populateMenuSelectedCount = (mTitle, mName) => {
    let thisCount = 0;
    let menuCount = moduleListData[mName].length;

    for (var key in editACLData) {
      let [modName, modIndex] = key.split("__");

      if (modName == mName && editACLData[key] != "NA" && modIndex != 0) {
        if (getParentStatus(key, mName).length > 0) {
          thisCount++;
        }
      }
    }

    return editACLData.length == 0 ? (
      mTitle
    ) : (
      <div>
        {mTitle} (
        <i className="acl-menu-count">{thisCount + " / " + menuCount}</i>)
      </div>
    );
  };

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    editACLData[e.target.name] = e.target.value;
    setEditACLData(editACLData);

    //-------------- Added Delay To Render Badge -----------------
    setTimeout(() => {
      setDummyState(Date.now());
    }, 300);
  };

  const renderMenuRow = (Module_Name, childItem, MN_Id, Module_Menu) => {
    return [
      <div className="menuLabel">
        {MN_Id != 0 ? (
          <TextTranslation text={"Menu." + Module_Menu + "." + Module_Name} />
        ) : (
          Module_Name
        )}
      </div>,
      <Select
        className="acl-menu-select"
        variant="standard"
        disableUnderline
        name={Module_Menu + "__" + MN_Id}
        defaultValue={
          editACLData[Module_Menu + "__" + MN_Id]
            ? editACLData[Module_Menu + "__" + MN_Id]
            : "NA"
        }
        {...register(Module_Menu + "__" + MN_Id)}
        value={setValue(
          Module_Menu + "__" + MN_Id,
          editACLData[Module_Menu + "__" + MN_Id]
            ? editACLData[Module_Menu + "__" + MN_Id]
            : "NA"
        )}
        onChange={(e) => handleChange(e)}
        sx={{ width: "150px" }}
      >
        {childItem
          ? nonChildDropdown.map((data, i) => {
              return (
                <MenuItem value={data.key} key={i}>
                  {data.value}
                </MenuItem>
              );
            })
          : childDropdown.map((data, i) => {
              return (
                <MenuItem value={data.key} key={i}>
                  {data.value}
                </MenuItem>
              );
            })}
      </Select>,
    ];
  };

  const getACLDetails = (id) => {
    dispatch(setLoader(true));
    dispatch(getACLData(id))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then((response) => {
        let thisACLData = JSON.parse(response[0].SACL_Data);
        thisACLData = thisACLData ? thisACLData : [];

        setValue("ACLId", response[0].SACL_Id);
        setValue("ACLName", response[0].SACL_Name);
        setValue("ACLStatus", response[0].SACL_Status);
        setEditACLData(thisACLData);
        document
          .querySelector(".ACLForm")
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const columns = [
    {
      name: AppTrlns("settings:ACL.gridLabels.ACLName"),
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} align="left">
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({
          style: {
            border: "1px solid #dee2e6",
          },
        }),
      },
    },
    {
      name: AppTrlns("settings:ACL.gridLabels.ACLStatus"),
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} align="center">
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({
          style: {
            textAlign: "center",
            border: "1px solid #dee2e6",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == "1" ? "Active" : "Inactive";
        },
      },
    },
    {
      name: AppTrlns("settings:ACL.gridLabels.Edit"),
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} align="center">
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({
          style: {
            textAlign: "center",
            border: "1px solid #dee2e6",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              title={<TextTranslation text={"settings:ACL.gridLabels.Edit"} />}
              className="pointerCursor"
            >
              <Edit color="primary" onClick={() => getACLDetails(value)} />
            </Tooltip>
          );
        },
      },
    },
  ];

  const onNodeToggleTab = (e, nodeIDs) => {
    setExpandedTree(nodeIDs);
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("Dashboard.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:ACL.sceneTitle")}
        location={location}
      />

      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div className="ACLForm">
              <div className="row">
                <div className="col-md-8 mb-3">
                  <TextField
                    hidden
                    type="text"
                    name="ACLId"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errors?.ACLId ? true : false}
                    {...register("ACLId")}
                  />
                  <TextField
                    type="text"
                    name="ACLName"
                    size="small"
                    label={
                      <TextTranslation
                        text={"settings:ACL.formLabels.ACLName"}
                      />
                    }
                    variant="outlined"
                    fullWidth
                    error={errors?.ACLName ? true : false}
                    {...register("ACLName", {
                      required: true,
                    })}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.ACLName?.type === "required" && (
                    <FieldError
                      errorText={
                        <TextTranslation
                          text={"settings:ACL.errorText.required"}
                        />
                      }
                    />
                  )}
                  {errors?.ACLName?.type === "uniqueValueCheck" && (
                    <FieldError errorText={errors.ACLName.message} />
                  )}
                </div>
                <div className="col-md-4 form-group mb-3 d-flex">
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>
                      <TextTranslation
                        text={"settings:ACL.formLabels.ACLStatus"}
                      />
                    </InputLabel>
                    <Select
                      native
                      name="ACLStatus"
                      label={
                        <TextTranslation
                          text={"settings:ACL.formLabels.ACLStatus"}
                        />
                      }
                      error={errors?.ACLStatus ? true : false}
                      {...register("ACLStatus", {
                        required: true,
                      })}
                      onChange={(e) => handleChange(e)}
                    >
                      <option key="1" value="1">
                        Active
                      </option>
                      <option key="2" value="2">
                        Blocked
                      </option>
                    </Select>
                    {errors?.ACLStatus?.type === "required" && (
                      <FieldError
                        errorText={
                          <TextTranslation
                            text={"settings:ACL.errorText.required"}
                          />
                        }
                      />
                    )}
                  </FormControl>

                  <Button
                    color="warning"
                    variant="contained"
                    className="h-100 ml-3 mr-2"
                    onClick={() => cancelMenuGrid()}
                  >
                    <TextTranslation text={"settings:ACL.buttonText.Cancel"} />
                  </Button>
                  <Button
                    className="h-100"
                    color="success"
                    variant="contained"
                    onClick={handleSubmit(updateMenuGrid, handleOnError)}
                  >
                    <TextTranslation text={"settings:ACL.buttonText.Save"} />
                  </Button>
                </div>
              </div>
              <div className="row d-flex">
                <div className="container-fluid menuContainerWrapper">
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                    }}
                  >
                    {menuListData &&
                      menuListData.map((key, i) => {
                        if (!expandedTree.includes(`module_${i}`)) {
                          setExpandedTree((expandedTree) => [
                            ...expandedTree,
                            `module_${i}`,
                          ]);
                        }
                        return (
                          <Tab
                            key={`module_${i}`}
                            label={populateMenuSelectedCount(
                              moduleListData[key][0]["Module_Name"],
                              key
                            )}
                            {...a11yProps(i)}
                          />
                        );
                      })}
                  </Tabs>
                  {menuListData &&
                    menuListData.map((key, i) => {
                      return (
                        <TabPanel
                          key={`module_${i}`}
                          value={activeTab}
                          index={i}
                        >
                          <TreeView
                            aria-label="customized"
                            expanded={expandedTree}
                            //defaultExpanded={[`module_${i}`]}
                            defaultCollapseIcon={
                              <IndeterminateCheckBoxOutlined className="menuTreeIcon" />
                            }
                            defaultExpandIcon={
                              <AddBoxOutlined className="menuTreeIcon" />
                            }
                            sx={{
                              flexGrow: 1,
                              overflowY: "auto",
                              marginBottom: "18px",
                            }}
                            onNodeToggle={onNodeToggleTab}
                          >
                            <StyledTreeItem
                              key={`module_${i}`}
                              nodeId={`module_${i}`}
                              label={renderMenuRow(
                                moduleListData[key][0]["Module_Name"],
                                true,
                                0,
                                key
                              )}
                            >
                              {renderMenu(0, key, moduleListData[key])}
                            </StyledTreeItem>
                          </TreeView>
                        </TabPanel>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4 d-flex">
          <div className="col-md-12">
            <MUIDataTable
              title={
                <TextTranslation text={"settings:ACL.gridLabels.gridTitle"} />
              }
              data={ACLList}
              columns={columns}
              //options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACL;
