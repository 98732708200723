/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "react-sidebar";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

//Components;
import Header from "../Header/Header";
import SidebarContent from "../Sidebar";
import Footer from "../Footer/Footer";
import Tour from "../Tour";
import ThemeOptions from "../ThemeOptions/ThemeOptions";
import ModuleFooter from "components/ModuleFooter/ModuleFooter";
import ModuleHeader from "components/ModuleHeader/ModuleHeader";

import HorizontalMenu from "components/HorizontalMenu/HorizontalMenu";

// Preload Components
import PreloadHeader from "../PreloadLayout/PreloadHeader";
import PreloadSidebar from "../PreloadLayout/PreloadSidebar";

// Redux Modules
import { collapsedSidebarAction } from "modules/themes/stores/themeSettings";
import FullPageLoader from "components/FullPageLoader/FullPageLoader";

import { encrypt, decrypt } from "utils/pivot";

import { initAppConstants } from "modules/themes/stores/generalSettings";

export default function DefaultAppLayout({ children }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loadingHeader, setLoadingHeader] = useState(true);
  const [loadingSidebar, setLoadingSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(null);
  const [windowHeight, setHindowHeight] = useState(null);

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  const { navCollapsed, rtlLayout, miniSidebar, showLoader, menuType } =
    useSelector((store) => store.themes.themeSettings);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
    setHindowHeight(window.innerHeight);
  };

  const handleClickOutside = (event) => {
    if (event.target.id === "sidebar_overlay") {
      dispatch(collapsedSidebarAction(true));
    }
  };
  let rctPageClass = "";
  if (pathname === "/themes/applications") {
    rctPageClass = "moduleClass";
  }

  const onFocus = () => {
    //localStorage.setItem("comp_id", sessionStorage.getItem("comp_id"));
  };

  const onBlur = () => {
    //console.log("Tab is blurred");
  };

  const onVisibile = () => {
    if (
      localStorage.getItem("user_id") &&
      localStorage.getItem("comp_id") &&
      sessionStorage.getItem("user_id") &&
      sessionStorage.getItem("comp_id")
    ) {
      localStorage.setItem("user_id", sessionStorage.getItem("user_id"));
      localStorage.setItem("comp_id", sessionStorage.getItem("comp_id"));
    }
  };

  useEffect(() => {
    //Pass User ID & Company ID
    dispatch(
      initAppConstants(
        sessionStorage.getItem("user_id"),
        sessionStorage.getItem("comp_id")
      )
    )
      .catch((err) => {
        alert(err.msg);
      })
      .then(() => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    document.addEventListener("visibilitychange", onVisibile);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      document.removeEventListener("visibilitychange", onVisibile);
    };
  }, []);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    document.addEventListener("click", handleClickOutside, true);

    setLoadingHeader(false);
    setLoadingSidebar(false);

    window.addEventListener("load", setTabID);

    return () => {
      window.removeEventListener("resize", updateDimensions, true);
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("load", setTabID, true);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 1199) {
      dispatch(collapsedSidebarAction(true));
    } else {
      dispatch(collapsedSidebarAction(false));
    }
  }, [windowWidth]);

  useEffect(() => {
    if (
      sessionStorage.getItem("user_id") == "null" ||
      sessionStorage.getItem("comp_id") == "null"
    ) {
      //Redirect To Signin
    }

    function storageEventHandler(event) {
      if (
        typeof event.storageArea == "undefined" ||
        (event.key === "user_id" &&
          sessionStorage.getItem("user_id") == null) ||
        (event.key === "comp_id" &&
          (localStorage.getItem("comp_id") == null ||
            sessionStorage.getItem("comp_id") == null))
      ) {
        //Redirect To Signin
      }
    }

    window.addEventListener("storage", storageEventHandler);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const setTabID = () => {
    var iPageTabID = getWindowGUID();
    sessionStorage.setItem("tabID", iPageTabID);
  };
  const handleMouseMove = () => {};

  const UUID = () => {
    return Math.floor(Math.random() * 0x10000 /* 65536 */).toString(16);
  };
  const getWindowGUID = () => {
    return (
      UUID() +
      UUID() +
      "-" +
      UUID() +
      "-" +
      UUID() +
      "-" +
      UUID() +
      "-" +
      UUID() +
      UUID() +
      UUID()
    );
  };

  const renderHeader = (menuType) => {
    if (loadingHeader) {
      return <PreloadHeader />;
    }
    return <Header />;
  };

  const renderSidebar = () => {
    if (loadingSidebar) {
      return <PreloadSidebar />;
    }
    return <SidebarContent />;
  };

  const getScrollBarStyle = () => {
    return {
      height: "calc(100vh - 50px)",
    };
  };

  const renderPage = () => {
    return (
      <Scrollbars
        className="rct-scroll"
        autoHide
        autoHideDuration={100}
        style={getScrollBarStyle()}
      >
        <div className="rct-page-content">
          {children}
          {pathname == "/themes/applications" ? <ModuleFooter /> : <Footer />}
        </div>
      </Scrollbars>
    );
  };

  return (
    <div
      className={
        menuType == "hr" || pathname == "/themes/applications"
          ? "app-horizontal collapsed-sidebar"
          : "app"
      }
    >
      {appConstants && Object.keys(appConstants).length > 0 ? (
        <div className="app-main-container">
          {showLoader && <FullPageLoader />}
          <Tour />
          <Sidebar
            sidebar={renderSidebar()}
            open={windowWidth <= 1199 ? !navCollapsed : false}
            docked={windowWidth > 1199 ? !navCollapsed : false}
            pullRight={rtlLayout}
            onSetOpen={() => collapsedSidebarAction(false)}
            styles={{ content: { overflowY: "" } }}
            contentClassName={classnames({
              "app-conrainer-wrapper": miniSidebar,
            })}
            overlayId="sidebar_overlay"
          >
            <div className="app-container">
              <div className="rct-page-wrapper">
                <div className="rct-app-content">
                  {pathname == "/themes/applications" ? (
                    <ModuleHeader />
                  ) : (
                    <div className="app-header">{renderHeader()}</div>
                  )}

                  <div className={`rct-page ${rctPageClass}`}>
                    {menuType == "hr" && pathname != "/themes/applications" ? (
                      <HorizontalMenu />
                    ) : null}
                    {renderPage()}
                  </div>
                </div>
              </div>
            </div>
          </Sidebar>
          {pathname != "/themes/applications" ? <ThemeOptions /> : null}
        </div>
      ) : null}
    </div>
  );
}
