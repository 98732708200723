/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { combineReducers } from "redux";

import { generalSettings } from "./generalSettings";
import { themeSettings } from "./themeSettings";
import { socketIO } from "./socketIO";

const reducers = combineReducers({
  generalSettings,
  themeSettings,
  socketIO,
});

export default reducers;
