/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import __configureStore from "components/InitializeReducer";
import reducers from "./reducers";

__configureStore.injectReducer("themes", reducers);
