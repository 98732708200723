/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
import { Tooltip, IconButton } from "@mui/material";

// Helpers
import { getAppLayout } from "utils/helpers";

const QuickLinks = () => {
  const { pathname } = useLocation();

  return (
    <UncontrolledDropdown
      nav
      className="list-inline-item quciklink-dropdown tour-step-1"
    >
      <DropdownToggle nav className="p-0">
        <Tooltip
          title="Quick Links"
          placement="bottom"
          className="pointerCursor"
        >
          <IconButton aria-label="quicklink">
            <i className="zmdi zmdi-apps"></i>
          </IconButton>
        </Tooltip>
      </DropdownToggle>
      <DropdownMenu right>
        <Scrollbars
          className="rct-scroll"
          autoHeight
          autoHeightMin={100}
          autoHeightMax={350}
        >
          <div className="dropdown-content">
            <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
              <span className="text-white font-weight-bold">Quick Links</span>
              <Badge color="warning">1 NEW</Badge>
            </div>
            <ul className="list-unstyled mb-0 dropdown-list">
              <li>
                <Link to={`/${getAppLayout(pathname)}/pages/report`}>
                  <i className="ti-notepad text-primary mr-10"></i>
                  <span>Report</span>
                </Link>
              </li>
              <li>
                <Link to={`/${getAppLayout(pathname)}/tables/data-table`}>
                  <i className="ti-layout text-danger mr-10"></i>
                  <span>Tables</span>
                </Link>
              </li>
              <li>
                <Link to={`/${getAppLayout(pathname)}/users/user-management`}>
                  <i className="ti-user text-success mr-10"></i>
                  <span>User Management</span>
                </Link>
              </li>
              <li>
                <Link to={`/${getAppLayout(pathname)}/ecommerce/invoice`}>
                  <i className="text-info ti-agenda mr-10"></i>
                  <span>Invoice</span>
                </Link>
              </li>
              <li>
                <Link to={`/${getAppLayout(pathname)}/mail/folder/inbox`}>
                  <i className="ti-email text-danger  mr-10"></i>
                  <span>Inbox</span>
                </Link>
              </li>
              <li>
                <Link to={`/${getAppLayout(pathname)}/calendar/basic`}>
                  <i className="text-warning ti-calendar mr-10"></i>
                  <span>Calendar</span>
                </Link>
              </li>
            </ul>
          </div>
        </Scrollbars>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default QuickLinks;
