export default {
  "zmdi-3d-rotation": "3D Rotation",
  "zmdi-airplane-off": "Airplane Off",
  "zmdi-airplane": "Airplane",
  "zmdi-album": "Album",
  "zmdi-archive": "Archive",
  "zmdi-assignment-account": "Assignment Account",
  "zmdi-assignment-alert": "Assignment Alert",
  "zmdi-assignment-check": "Assignment Check",
  "zmdi-assignment-o": "Assignment O",
  "zmdi-assignment-return": "Assignment Return",
  "zmdi-assignment-returned": "Assignment Returned",
  "zmdi-assignment": "Assignment",
  "zmdi-attachment-alt": "Attachment",
  "zmdi-attachment": "Attchment",
  "zmdi-audio": "Audio",
  "zmdi-badge-check": "Badge Check",
  "zmdi-balance-wallet": "Balance Wallet",
  "zmdi-balance": "Balance",
  "zmdi-battery-alert": "Battery Alert",
  "zmdi-battery-flash": "Battery Flash",
  "zmdi-battery-unknown": "Battery Unknown",
  "zmdi-battery": "Battery",
  "zmdi-bike": "Bike",
  "zmdi-block-alt": "Block",
  "zmdi-block": "Block",
  "zmdi-boat": "Boat",
  "zmdi-book-image": "Book Image",
  "zmdi-book": "Book",
  "zmdi-bookmark-outline": "Bookmark Outline",
  "zmdi-bookmark": "Bookmark",
  "zmdi-brush": "Brush",
  "zmdi-bug": "Bug",
  "zmdi-bus": "Bus",
  "zmdi-cake": "Cake",
  "zmdi-car-taxi": "Taxi",
  "zmdi-car-wash": "Car Wash",
  "zmdi-car": "Car",
  "zmdi-card-giftcard": "Gift Card",
  "zmdi-card-membership": "Card Membership",
  "zmdi-card-travel": "Card Travel",
  "zmdi-card": "Card",
  "zmdi-case-check": "Case Check",
  "zmdi-case-download": "Case Download",
  "zmdi-case-play": "Case Play",
  "zmdi-case": "Case",
  "zmdi-cast-connected": "Cast Connected",
  "zmdi-cast": "Cast",
  "zmdi-chart-donut": "Chart Donut",
  "zmdi-chart": "Chart",
  "zmdi-city-alt": "City",
  "zmdi-city": "City",
  "zmdi-close-circle-o": "Close Circle",
  "zmdi-close-circle": "Close Circle",
  "zmdi-close": "Close",
  "zmdi-cocktail": "Cocktail",
  "zmdi-code-setting": "Code Setting",
  "zmdi-code-smartphone": "Smart Phone",
  "zmdi-code": "Code",
  "zmdi-coffee": "Coffe",
  "zmdi-collection-bookmark": "Collection Bookmark",
  "zmdi-collection-case-play": "Collection Case Play",
  "zmdi-collection-folder-image": "Collection Folder Image",
  "zmdi-collection-image-o": "Collection Image",
  "zmdi-collection-image": "Collection Image",
  "zmdi-collection-item-1": "Collection Item",
  "zmdi-collection-item-2": "Collection Item",
  "zmdi-collection-item-3": "Collection Item",
  "zmdi-collection-item-4": "Collection Item",
  "zmdi-collection-item-5": "Collection Item",
  "zmdi-collection-item-6": "Collection Item",
  "zmdi-collection-item-7": "Collection Item",
  "zmdi-collection-item-8": "Collection Item",
  "zmdi-collection-item-9-plus": "Collection Item",
  "zmdi-collection-item-9": "Collection Item",
  "zmdi-collection-item": "Collection Item",
  "zmdi-collection-music": "Collection Music",
  "zmdi-collection-pdf": "Collection PDF",
  "zmdi-collection-plus": "Collection Plus",
  "zmdi-collection-speaker": "Collection Speaker",
  "zmdi-collection-text": "Collection Text",
  "zmdi-collection-video": "Collection Video",
  "zmdi-compass": "Compass",
  "zmdi-cutlery": "Cutlery",
  "zmdi-delete": "Delete",
  "zmdi-dialpad": "Dialpad",
  "zmdi-dns": "Dns",
  "zmdi-drink": "Drink",
  "zmdi-edit": "Edit",
  "zmdi-email-open": "Email Open",
  "zmdi-email": "Email",
  "zmdi-eye-off": "Eye Off",
  "zmdi-eye": "Eye",
  "zmdi-eyedropper": "Eye Dropper",
  "zmdi-favorite-outline": "Favourite",
  "zmdi-favorite": "Favourite",
  "zmdi-filter-list": "Filter",
  "zmdi-fire": "Fire",
  "zmdi-flag": "Flag",
  "zmdi-flare": "Flare",
  "zmdi-flash-auto": "Flash Auto",
  "zmdi-flash-off": "Flash Off",
  "zmdi-flash": "Flash",
  "zmdi-flip": "Flip",
  "zmdi-flower-alt": "Flower",
  "zmdi-flower": "Flower",
  "zmdi-font": "Font",
  "zmdi-fullscreen-alt": "Full Screen",
  "zmdi-fullscreen-exit": "Full Screen",
  "zmdi-fullscreen": "Full Screen",
  "zmdi-functions": "Functions",
  "zmdi-gas-station": "Gas Station",
  "zmdi-gesture": "Gesture",
  "zmdi-globe-alt": "Globe",
  "zmdi-globe-lock": "Globe",
  "zmdi-globe": "Globe",
  "zmdi-graduation-cap": "Graduation Cap",
  "zmdi-home": "Home",
  "zmdi-hospital-alt": "Hospital",
  "zmdi-hospital": "Hospital",
  "zmdi-hotel": "Hotel",
  "zmdi-hourglass-alt": "Hourglass",
  "zmdi-hourglass-outline": "Hourglass",
  "zmdi-hourglass": "Hourglass",
  "zmdi-http": "HTTP",
  "zmdi-image-alt": "Image",
  "zmdi-image-o": "Image",
  "zmdi-image": "Image",
  "zmdi-inbox": "Inbox",
  "zmdi-invert-colors-off": "Invert Colors Off",
  "zmdi-invert-colors": "Invert Colors",
  "zmdi-key": "Key",
  "zmdi-label-alt-outline": "Label",
  "zmdi-label-alt": "Label",
  "zmdi-label-heart": "Label Heart",
  "zmdi-label": "Label",
  "zmdi-labels": "Labels",
  "zmdi-lamp": "Lamp",
  "zmdi-landscape": "Landscape",
  "zmdi-layers-off": "Layers Off",
  "zmdi-layers": "Layers",
  "zmdi-library": "Library",
  "zmdi-link": "Link",
  "zmdi-lock-open": "Lock Open",
  "zmdi-lock-outline": "Lock",
  "zmdi-lock": "Lock",
  "zmdi-mail-reply-all": "Reply All",
  "zmdi-mail-reply": "Reply",
  "zmdi-mail-send": "Send",
  "zmdi-mall": "Mall",
  "zmdi-map": "Map",
  "zmdi-menu": "Menu",
  "zmdi-money-box": "Money Box",
  "zmdi-money-off": "Money Off",
  "zmdi-money": "Money",
  "zmdi-more-vert": "More",
  "zmdi-more": "More",
  "zmdi-movie-alt": "Movie",
  "zmdi-movie": "Movie",
  "zmdi-nature-people": "Nature People",
  "zmdi-nature": "Nature",
  "zmdi-navigation": "Navigation",
  "zmdi-open-in-browser": "Open In Browser",
  "zmdi-open-in-new": "Open In New",
  "zmdi-palette": "Palette",
  "zmdi-parking": "Parking",
  "zmdi-pin-account": "Pin Account",
  "zmdi-pin-assistant": "Pin Assistant",
  "zmdi-pin-drop": "Pin Drop",
  "zmdi-pin-help": "Pin Help",
  "zmdi-pin-off": "Pin Off",
  "zmdi-pin": "Pin",
  "zmdi-pizza": "Pizza",
  "zmdi-plaster": "Plaster",
  "zmdi-power-setting": "Power Setting",
  "zmdi-power": "Power",
  "zmdi-print": "Print",
  "zmdi-puzzle-piece": "Puzzle Piece",
  "zmdi-quote": "Quote",
  "zmdi-railway": "Railway",
  "zmdi-receipt": "Receipt",
  "zmdi-refresh-alt": "Refresh",
  "zmdi-refresh-sync-alert": "Refresh Sync Alert",
  "zmdi-refresh-sync-off": "Refresh Sync Off",
  "zmdi-refresh-sync": "Refresh Sync",
  "zmdi-refresh": "Refresh",
  "zmdi-roller": "Roller",
  "zmdi-ruler": "Ruler",
  "zmdi-scissors": "Scissors",
  "zmdi-screen-rotation-lock": "Screen Rotation Lock",
  "zmdi-screen-rotation": "Screen Rotation",
  "zmdi-search-for": "Search",
  "zmdi-search-in-file": "Search In File",
  "zmdi-search-in-page": "Search In Page",
  "zmdi-search-replace": "Search Replace",
  "zmdi-search": "Search",
  "zmdi-seat": "Seat",
  "zmdi-settings-square": "Settings",
  "zmdi-settings": "Settings",
  "zmdi-shield-check": "Shield Check",
  "zmdi-shield-security": "Shield Security",
  "zmdi-shopping-basket": "Shopping Basket",
  "zmdi-shopping-cart-plus": "Shopping Cart",
  "zmdi-shopping-cart": "Shopping Cart",
  "zmdi-sign-in": "Sign In",
  "zmdi-sort-amount-asc": "Amount ASC",
  "zmdi-sort-amount-desc": "Amount Desc",
  "zmdi-sort-asc": "Sort ASC",
  "zmdi-sort-desc": "Sort Desc",
  "zmdi-spellcheck": "Spell Check",
  "zmdi-storage": "Storage",
  "zmdi-store-24": "Storage 24",
  "zmdi-store": "Store",
  "zmdi-subway": "Subway",
  "zmdi-sun": "Sun",
  "zmdi-tab-unselected": "Tab Unselected",
  "zmdi-tab": "Tab",
  "zmdi-tag-close": "Tag Close",
  "zmdi-tag-more": "Tag More",
  "zmdi-tag": "Tag",
  "zmdi-thumb-down": "Thumb Down",
  "zmdi-thumb-up-down": "Thumb Up Down",
  "zmdi-thumb-up": "Thumb Up",
  "zmdi-ticket-star": "Ticket Star",
  "zmdi-toll": "Toll",
  "zmdi-toys": "Toys",
  "zmdi-traffic": "Traffic",
  "zmdi-translate": "Translate",
  "zmdi-triangle-down": "Triangle Down",
  "zmdi-triangle-up": "Triangle Up",
  "zmdi-truck": "Truck",
  "zmdi-turning-sign": "Turning Sign",
  "zmdi-wallpaper": "Wallpaper",
  "zmdi-washing-machine": "Washing Machine",
  "zmdi-window-maximize": "Window Maximize",
  "zmdi-window-minimize": "Window Minimize",
  "zmdi-window-restore": "Window Restore",
  "zmdi-wrench": "Wrench",
  "zmdi-zoom-in": "Zoom In",
  "zmdi-zoom-out": "Zoom Out",
  "zmdi-alert-circle-o": "Alert Circle",
  "zmdi-alert-circle": "Alert Circle",
  "zmdi-alert-octagon": "Octagon",
  "zmdi-alert-polygon": "Polygon",
  "zmdi-alert-triangle": "Triangle",
  "zmdi-help-outline": "Help",
  "zmdi-help": "Help",
  "zmdi-info-outline": "Info",
  "zmdi-info": "Info",
  "zmdi-notifications-active": "Notifications Active",
  "zmdi-notifications-add": "Notifications Add",
  "zmdi-notifications-none": "Notifications None",
  "zmdi-notifications-off": "Notifications Off",
  "zmdi-notifications-paused": "Notifications Paused",
  "zmdi-notifications": "Notifications",
  "zmdi-account-add": "Account Add",
  "zmdi-account-box-mail": "Account Box Mail",
  "zmdi-account-box-o": "Account Box",
  "zmdi-account-box-phone": "Account Box Phone",
  "zmdi-account-box": "Account Box",
  "zmdi-account-calendar": "Account Calendar",
  "zmdi-account-circle": "Account Circle",
  "zmdi-account-o": "Account",
  "zmdi-account": "Account",
  "zmdi-accounts-add": "Accounts Add",
  "zmdi-accounts-alt": "Accounts",
  "zmdi-accounts-list-alt": "Accounts List All",
  "zmdi-accounts-list": "Accounts List",
  "zmdi-accounts-outline": "Accounts Outline",
  "zmdi-accounts": "Accounts",
  "zmdi-face": "Face",
  "zmdi-female": "Female",
  "zmdi-male-alt": "Male",
  "zmdi-male-female": "MAle Female",
  "zmdi-male": "Male",
  "zmdi-mood": "Mood",
  "zmdi-run": "Run",
  "zmdi-walk": "Walk",
  "zmdi-cloud-box": "Cloud Box",
  "zmdi-cloud-circle": "Cloud Circle",
  "zmdi-cloud-done": "Cloud Done",
  "zmdi-cloud-download": "Cloud Download",
  "zmdi-cloud-off": "Cloud Off",
  "zmdi-cloud-outline-alt": "Cloud Outline",
  "zmdi-cloud-outline": "Cloud Outline",
  "zmdi-cloud-upload": "Cloud Upload",
  "zmdi-cloud": "Cloud",
  "zmdi-download": "Download",
  "zmdi-file-plus": "File Plus",
  "zmdi-file-text": "File Text",
  "zmdi-file": "File",
  "zmdi-folder-outline": "Folder Outline",
  "zmdi-folder-person": "Folder Person",
  "zmdi-folder-star-alt": "Folder Star",
  "zmdi-folder-star": "Folder Star",
  "zmdi-folder": "Folder",
  "zmdi-gif": "GIF",
  "zmdi-upload": "Upload",
  "zmdi-border-all": "Border All",
  "zmdi-border-bottom": "Border Bottom",
  "zmdi-border-clear": "Border Clear",
  "zmdi-border-color": "Border Color",
  "zmdi-border-horizontal": "Border Horizontal",
  "zmdi-border-inner": "Border Inner",
  "zmdi-border-left": "Border Left",
  "zmdi-border-outer": "Border Outer",
  "zmdi-border-right": "Border Right",
  "zmdi-border-style": "Border Style",
  "zmdi-border-top": "Border Top",
  "zmdi-border-vertical": "Border Vertical",
  "zmdi-copy": "Copy",
  "zmdi-crop": "Crop",
  "zmdi-format-align-center": "Format Align Center",
  "zmdi-format-align-justify": "Format Align Justify",
  "zmdi-format-align-left": "Format Align Left",
  "zmdi-format-align-right": "Format Align Right",
  "zmdi-format-bold": "Format Bold",
  "zmdi-format-clear-all": "Format Clear All",
  "zmdi-format-clear": "Format Clear",
  "zmdi-format-color-fill": "Format Color Fill",
  "zmdi-format-color-reset": "Format Color Reset ",
  "zmdi-format-color-text": "Format Color Text",
  "zmdi-format-indent-decrease": "Format Indent Decrease",
  "zmdi-format-indent-increase": "Format Indent Increase",
  "zmdi-format-italic": "Italic",
  "zmdi-format-line-spacing": "Line Spacing",
  "zmdi-format-list-bulleted": "List Bulleted",
  "zmdi-format-list-numbered": "List Numbered",
  "zmdi-format-ltr": "Format LTR",
  "zmdi-format-rtl": "Format RTL",
  "zmdi-format-size": "Format Size",
  "zmdi-format-strikethrough-s": "Strikethrough S",
  "zmdi-format-strikethrough": "Strikethrough",
  "zmdi-format-subject": "Format Subject",
  "zmdi-format-underlined": "Underline",
  "zmdi-format-valign-bottom": "Align Bottom",
  "zmdi-format-valign-center": "Align Center",
  "zmdi-format-valign-top": "Align Top",
  "zmdi-redo": "Redo",
  "zmdi-select-all": "Select All",
  "zmdi-space-bar": "Space Bar",
  "zmdi-text-format": "Text Format",
  "zmdi-transform": "Transform",
  "zmdi-undo": "Undo",
  "zmdi-wrap-text": "Wrap Text",
  "zmdi-comment-alert": "Comment Alert",
  "zmdi-comment-alt-text": "Comment Alt Text",
  "zmdi-comment-alt": "Comment",
  "zmdi-comment-edit": "Comment Edit",
  "zmdi-comment-image": "Comment Image",
  "zmdi-comment-list": "Comment List",
  "zmdi-comment-more": "Comment More",
  "zmdi-comment-outline": "Comment Outline",
  "zmdi-comment-text-alt": "Comment Text Alt",
  "zmdi-comment-text": "Comment Text",
  "zmdi-comment-video": "Comment Video",
  "zmdi-comment": "Comment",
  "zmdi-comments": "Comments",
  "zmdi-check-all": "Check All",
  "zmdi-check-circle-u": "Check Circle",
  "zmdi-check-circle": "Check Circle",
  "zmdi-check-square": "Check Square",
  "zmdi-check": "Check",
  "zmdi-circle-o": "Circle",
  "zmdi-circle": "Circle",
  "zmdi-dot-circle-alt": "Dot Circle",
  "zmdi-dot-circle": "Dot Circle",
  "zmdi-minus-circle-outline": "Circle Outline",
  "zmdi-minus-circle": "Minus Circle",
  "zmdi-minus-square": "Minus Square",
  "zmdi-minus": "Minus",
  "zmdi-plus-circle-o-duplicate": "Plus Circle O Duplicate",
  "zmdi-plus-circle-o": "Plus Circle O",
  "zmdi-plus-circle": "Plus Circle",
  "zmdi-plus-square": "Plus Square",
  "zmdi-plus": "Plus",
  "zmdi-square-o": "Square",
  "zmdi-star-circle": "Star Circle",
  "zmdi-star-half": "Star Half",
  "zmdi-star-outline": "Star Outline",
  "zmdi-star": "Star",
  "zmdi-bluetooth-connected": "Bluetooth Connected",
  "zmdi-bluetooth-off": "Bluetooth Off",
  "zmdi-bluetooth-search": "Bluetooth Search",
  "zmdi-bluetooth-setting": "Bluetooth Setting",
  "zmdi-bluetooth": "Bluetooth",
  "zmdi-camera-add": "Camera Add",
  "zmdi-camera-alt": "Camera Alt",
  "zmdi-camera-bw": "Camera BW",
  "zmdi-camera-front": "Camera Front",
  "zmdi-camera-mic": "Camera MIC",
  "zmdi-camera-party-mode": "Camera Party Mode",
  "zmdi-camera-rear": "Camera Rear",
  "zmdi-camera-roll": "Camera Roll",
  "zmdi-camera-switch": "Camera Swicth",
  "zmdi-camera": "Camera",
  "zmdi-card-alert": "Card Alert",
  "zmdi-card-off": "Card Off",
  "zmdi-card-sd": "Card SD",
  "zmdi-card-sim": "Card Sim",
  "zmdi-desktop-mac": "Desktop MAC",
  "zmdi-desktop-windows": "Desktop Windows",
  "zmdi-device-hub": "Device Hub",
  "zmdi-devices-off": "Devices Off",
  "zmdi-devices": "Devices",
  "zmdi-dock": "Dock",
  "zmdi-floppy": "Floppy",
  "zmdi-gamepad": "Gamepad",
  "zmdi-gps-dot": "Dot",
  "zmdi-gps-off": "GPS Off",
  "zmdi-gps": "GPS",
  "zmdi-headset-mic": "Headset MIC",
  "zmdi-headset": "Headset",
  "zmdi-input-antenna": "Input Antenna",
  "zmdi-input-composite": "Input Composite",
  "zmdi-input-hdmi": "Input HDMI",
  "zmdi-input-power": "Input Power",
  "zmdi-input-svideo": "Input Video",
  "zmdi-keyboard-hide": "Keyboard Hide",
  "zmdi-keyboard": "Keyboard",
  "zmdi-laptop-chromebook": "Chromebook",
  "zmdi-laptop-mac": "Laptop MAC",
  "zmdi-laptop": "Laptop",
  "zmdi-mic-off": "MIC Off",
  "zmdi-mic-outline": "MIC",
  "zmdi-mic-setting": "MIC Setting",
  "zmdi-mic": "MIC",
  "zmdi-mouse": "Mouse",
  "zmdi-network-alert": "Network Alert",
  "zmdi-network-locked": "Network Locked",
  "zmdi-network-off": "Network Off",
  "zmdi-network-outline": "Network",
  "zmdi-network-setting": "Network Settings",
  "zmdi-network": "Network",
  "zmdi-phone-bluetooth": "Phone Bluetooth",
  "zmdi-phone-end": "Phone end",
  "zmdi-phone-forwarded": "Phone Forward",
  "zmdi-phone-in-talk": "Phone In Talk",
  "zmdi-phone-locked": "Phone Locked",
  "zmdi-phone-missed": "Phone Missed",
  "zmdi-phone-msg": "Phone Msg",
  "zmdi-phone-paused": "Phone Paused",
  "zmdi-phone-ring": "Phone Ring",
  "zmdi-phone-setting": "Phone Settings",
  "zmdi-phone-sip": "Phone Sip",
  "zmdi-phone": "Phone",
  "zmdi-portable-wifi-changes": "Wifi Changes",
  "zmdi-portable-wifi-off": "Portable Wifi Off",
  "zmdi-portable-wifi": "Portable Wifi",
  "zmdi-radio": "Radio",
  "zmdi-reader": "Reader",
  "zmdi-remote-control-alt": "Remote Control",
  "zmdi-remote-control": "Remote Control",
  "zmdi-router": "Router",
  "zmdi-scanner": "Scanner",
  "zmdi-smartphone-android": "Smartphone Android",
  "zmdi-smartphone-download": "Smartphone Download",
  "zmdi-smartphone-erase": "Smartphone Erase",
  "zmdi-smartphone-info": "Smartphone Info",
  "zmdi-smartphone-iphone": "Smartphone iPhone",
  "zmdi-smartphone-landscape-lock": "Smartphone landscape lock",
  "zmdi-smartphone-landscape": "Smartphone landscape",
  "zmdi-smartphone-lock": "Smartphone Lock",
  "zmdi-smartphone-portrait-lock": "Smartphone Portrait Lock",
  "zmdi-smartphone-ring": "Smartphone Ring",
  "zmdi-smartphone-setting": "Smartphone Setting",
  "zmdi-smartphone-setup": "Smartphone Setup",
  "zmdi-smartphone": "Smartphone",
  "zmdi-speaker": "Speaker",
  "zmdi-tablet-android": "Tablet Android",
  "zmdi-tablet-mac": "Tablet MAC",
  "zmdi-tablet": "Tablet",
  "zmdi-tv-alt-play": "TV Play",
  "zmdi-tv-list": "TV List",
  "zmdi-tv-play": "TV Play",
  "zmdi-tv": " TV",
  "zmdi-usb": " USB",
  "zmdi-videocam-off": " Videocam Off",
  "zmdi-videocam-switch": " Videocam Switch",
  "zmdi-videocam": " Videocam",
  "zmdi-watch": " Watch",
  "zmdi-wifi-alt-2": " Wifi",
  "zmdi-wifi-alt": " Wifi",
  "zmdi-wifi-info": " Wifi Info",
  "zmdi-wifi-lock": " Wifi Lock",
  "zmdi-wifi-off": " Wifi Off",
  "zmdi-wifi-outline": " Wifi",
  "zmdi-wifi": " Wifi",
  "zmdi-arrow-left-bottom": "Arrow Left Bottom",
  "zmdi-arrow-left": "Arrow Left",
  "zmdi-arrow-merge": "Arrow Merge",
  "zmdi-arrow-missed": "Arrow Missed",
  "zmdi-arrow-right-top": "Arrow Right Top",
  "zmdi-arrow-right": "Arrow Right",
  "zmdi-arrow-split": "Arrow Split",
  "zmdi-arrows": "Arrows",
  "zmdi-caret-down-circle": "Caret Down Circle",
  "zmdi-caret-down": " Caret Down",
  "zmdi-caret-left-circle": " Caret Left Circle",
  "zmdi-caret-left": " Caret Left",
  "zmdi-caret-right-circle": " Caret Right Circle",
  "zmdi-caret-right": " Caret Right",
  "zmdi-caret-up-circle": " Caret Up Circle",
  "zmdi-caret-up": " Caret Up",
  "zmdi-chevron-down": "Chevron Down",
  "zmdi-chevron-left": "Chevron Left",
  "zmdi-chevron-right": "Chevron Right",
  "zmdi-chevron-up": "Chevron Up",
  "zmdi-forward": "Forward",
  "zmdi-long-arrow-down": "Long Arrow Down",
  "zmdi-long-arrow-left": "Long Arrow Left",
  "zmdi-long-arrow-return": "Long Arrow Return",
  "zmdi-long-arrow-right": "Long Arrow Right",
  "zmdi-long-arrow-tab": "Long Arrow Tab",
  "zmdi-long-arrow-up": "Long Arrow Up",
  "zmdi-rotate-ccw": "Rotate CCW",
  "zmdi-rotate-cw": "Rotate CW",
  "zmdi-rotate-left": "Rotate Left",
  "zmdi-rotate-right": "Rotate Right",
  "zmdi-square-down": "Square Down",
  "zmdi-square-right": "Square Right",
  "zmdi-swap-alt": "Swap Alt",
  "zmdi-swap-vertical-circle": "Swap Vertical Circle",
  "zmdi-swap-vertical": "Swap Vertical",
  "zmdi-swap": "Swap",
  "zmdi-trending-down": "Down",
  "zmdi-trending-flat": "Trending Flat",
  "zmdi-trending-up": "Trending Up",
  "zmdi-unfold-less": "Unfold Less",
  "zmdi-unfold-more": "Unfold More",
  "zmdi-apps": "Apps",
  "zmdi-grid-off": "Grid Off",
  "zmdi-grid": "Grid",
  "zmdi-view-agenda": "View Agenda",
  "zmdi-view-array": "View Array",
  "zmdi-view-carousel": "View Carousel",
  "zmdi-view-column": "View Column",
  "zmdi-view-comfy": "View Comfy",
  "zmdi-view-compact": "View Compact",
  "zmdi-view-dashboard": "View Dashboard",
  "zmdi-view-day": "View Day",
  "zmdi-view-headline": "View Headline",
  "zmdi-view-list-alt": "View List",
  "zmdi-view-list": "View List",
  "zmdi-view-module": "View Module",
  "zmdi-view-quilt": "View Quilt",
  "zmdi-view-stream": "View Stream",
  "zmdi-view-subtitles": "View Subtitles",
  "zmdi-view-toc": "View Toc",
  "zmdi-view-web": "View Web",
  "zmdi-view-week": "View Week",
  "zmdi-widgets": "Widgets",
  "zmdi-alarm-check": "Alarm Check",
  "zmdi-alarm-off": "Alarm Off",
  "zmdi-alarm-plus": "Alarm Plus",
  "zmdi-alarm-snooze": "Alarm Snooze",
  "zmdi-alarm": "Alarm",
  "zmdi-calendar-alt": "Calendar",
  "zmdi-calendar-check": "Calendar Check",
  "zmdi-calendar-close": "Calendar Close",
  "zmdi-calendar-note": "Calendar Note",
  "zmdi-calendar": "Calendar",
  "zmdi-time-countdown": "Time Countdown",
  "zmdi-time-interval": "Time Interval",
  "zmdi-time-restore-setting": "Time",
  "zmdi-time-restore": "Time Restore",
  "zmdi-time": "Time",
  "zmdi-timer-off": "Timer Off",
  "zmdi-timer": "Timer",
  "zmdi-android-alt": "Android",
  "zmdi-android": "Android",
  "zmdi-apple": "Apple",
  "zmdi-behance": "Behance",
  "zmdi-codepen": "Codepen",
  "zmdi-dribbble": "Dribble",
  "zmdi-dropbox": "Dropbox",
  "zmdi-evernote": "Evernote",
  "zmdi-facebook-box": "Facebook",
  "zmdi-facebook": "Facebook",
  "zmdi-github-box": "Github",
  "zmdi-github": "Github",
  "zmdi-google-drive": "Google Drive",
  "zmdi-google-earth": "Google Earth",
  "zmdi-google-glass": "Google Glass",
  "zmdi-google-maps": "Google Maps",
  "zmdi-google-pages": "Google Pages",
  "zmdi-google-play": "Google Play",
  "zmdi-google-plus-box": "Google Plus",
  "zmdi-google-plus": "Google Plus",
  "zmdi-google": "Google",
  "zmdi-instagram": "Instagram",
  "zmdi-language-css3": "CSS3",
  "zmdi-language-html5": "HTML5",
  "zmdi-language-javascript": "Javascript",
  "zmdi-language-python-alt": "Python",
  "zmdi-language-python": "Python",
  "zmdi-lastfm": "Last FM",
  "zmdi-linkedin-box": "LinkedIn",
  "zmdi-paypal": "Paypal",
  "zmdi-pinterest-box": "Pinterest",
  "zmdi-pocket": "Pocket",
  "zmdi-polymer": "Polymer",
  "zmdi-share": "Share",
  "zmdi-stackoverflow": "Stack Overflow",
  "zmdi-steam-square": "Steam Square",
  "zmdi-steam": "Steam",
  "zmdi-twitter-box": "Twitter Box",
  "zmdi-twitter": "Twitter",
  "zmdi-vk": "VK",
  "zmdi-wikipedia": "Wikipedia",
  "zmdi-windows": "Windows",
  "zmdi-aspect-ratio-alt": "Aspect Ratio",
  "zmdi-aspect-ratio": "Aspect Ratio",
  "zmdi-blur-circular": "Blur Circular",
  "zmdi-blur-linear": "Blur Linear",
  "zmdi-blur-off": "Blurr Off",
  "zmdi-blur": "Blur",
  "zmdi-brightness-2": "Brightness 2",
  "zmdi-brightness-3": "Brightness 3",
  "zmdi-brightness-4": "Brightness 4",
  "zmdi-brightness-5": "Brightness 5",
  "zmdi-brightness-6": "Brightness 6",
  "zmdi-brightness-7": "Brightness 7",
  "zmdi-brightness-auto": "Brightness Auto",
  "zmdi-brightness-setting": "Brightness Settings",
  "zmdi-broken-image": "Broken Image",
  "zmdi-center-focus-strong": "Center Focus Strong",
  "zmdi-center-focus-weak": "Center Focus Weak",
  "zmdi-compare": "Compare",
  "zmdi-crop--9": "Crop - 9",
  "zmdi-crop-3-2": "Crop - 3- 2",
  "zmdi-crop-5-4": "Crop 5 - 4",
  "zmdi-crop-7-5": "Crop 7 - 5",
  "zmdi-crop-din": "Crop Din",
  "zmdi-crop-free": "Crop Free",
  "zmdi-crop-landscape": "Crop Landscape",
  "zmdi-crop-portrait": "Crop Portrait",
  "zmdi-crop-square": "Crop Square",
  "zmdi-exposure-alt": "Exposure",
  "zmdi-exposure": "Exposure",
  "zmdi-filter-b-and-w": "Filter B and W",
  "zmdi-filter-center-focus": "Center Focus",
  "zmdi-filter-frames": "Filter Frames",
  "zmdi-filter-tilt-shift": "Tilt Shift",
  "zmdi-gradient": "Gradient",
  "zmdi-grain": "Grain",
  "zmdi-graphic-eq": "Graphic EQ",
  "zmdi-hdr-off": "HDR Off",
  "zmdi-hdr-strong": "HDR Strong",
  "zmdi-hdr-weak": "HDR Weak",
  "zmdi-hdr": "HDR",
  "zmdi-mood-bad": "Mood Bad",
  "zmdi-iridescent": "Iridescent",
  "zmdi-leak-off": "Leak Off",
  "zmdi-leak": "Leak",
  "zmdi-looks": "Looks",
  "zmdi-loupe": "Loupe",
  "zmdi-panorama-horizontal": "Panorama Horizontal",
  "zmdi-panorama-vertical": "Panorama Vertical",
  "zmdi-panorama-wide-angle": "Panorama Wide Angle",
  "zmdi-photo-size-select-large": "Photo Size Select Large",
  "zmdi-photo-size-select-small": "Photo Size Select Small",
  "zmdi-picture-in-picture": "Picture In Picture",
  "zmdi-slideshow": "Slideshow",
  "zmdi-texture": "Texture",
  "zmdi-tonality": "Tonality",
  "zmdi-vignette": "Vignette",
  "zmdi-wb-auto": "WB Auto",
  "zmdi-eject-alt": "Eject Alt",
  "zmdi-eject": "Eject",
  "zmdi-equalizer": "Equalizer",
  "zmdi-fast-forward": "Fast Forward",
  "zmdi-fast-rewind": "Fast Rewind",
  "zmdi-forward-10": "Forward 10",
  "zmdi-forward-30": "Forward 30",
  "zmdi-forward-5": "Forward 5",
  "zmdi-hearing": "Hearing",
  "zmdi-pause-circle-outline": "Circle Outline",
  "zmdi-pause-circle": "Pause Circle",
  "zmdi-pause": "Pause",
  "zmdi-play-circle-outline": "Circle Outline",
  "zmdi-play-circle": "Play Circle",
  "zmdi-play": "Play",
  "zmdi-playlist-audio": "Playlist Audio",
  "zmdi-playlist-plus": "Playlist Plus",
  "zmdi-repeat-one": "Repaet One",
  "zmdi-repeat": "Replay",
  "zmdi-replay-10": "Replay 10",
  "zmdi-replay-30": "Replay 30",
  "zmdi-replay-5": "Replay 5",
  "zmdi-replay": "Replay",
  "zmdi-shuffle": "Shuffle",
  "zmdi-skip-next": "Skip Next",
  "zmdi-skip-previous": "Skip Previous",
  "zmdi-stop": "Stop",
  "zmdi-surround-sound": "Surround Sound",
  "zmdi-tune": "Tune",
  "zmdi-volume-down": "Volume Down",
  "zmdi-volume-mute": "Volume Mute",
  "zmdi-volume-off": "Volume Off",
  "zmdi-volume-up": "Volume Up",
  "zmdi-n-1-square": "n-1 Square",
  "zmdi-n-2-square": "n-2 Square",
  "zmdi-n-3-square": "n-3 Square",
  "zmdi-n-4-square": "n-4 Square",
  "zmdi-n-5-square": "n-5 Square",
  "zmdi-n-6-square": "n-6 Square",
  "zmdi-neg-1": "Neg 1",
  "zmdi-neg-2": "Neg 2",
  "zmdi-plus-1": "Plus 1",
  "zmdi-plus-2": "Plus 2",
  "zmdi-sec-10": "10 Econds",
  "zmdi-sec-3": "3 Seconds",
  "zmdi-zero": "Zero",
  "zmdi-airline-seat-flat-angled": "Airline Seat Flat Angled",
  "zmdi-airline-seat-flat": "Airline Seat Flat",
  "zmdi-airline-seat-individual-suite": "Airline Seat Individual Suite",
  "zmdi-airline-seat-legroom-extra": "Airline Seat Legroom Extra",
  "zmdi-airline-seat-legroom-normal": "Airline Seat Legroom Normal",
  "zmdi-airline-seat-legroom-reduced": "Airline Seat Legroom Reduced",
  "zmdi-airline-seat-recline-extra": "Airline Seat Recline Extra",
  "zmdi-airline-seat-recline-normal": "Airline Seat Recline Normal",
  "zmdi-airplay": "Airplay",
  "zmdi-closed-caption": "Closed Caption",
  "zmdi-confirmation-number": "Confirmation Number",
  "zmdi-developer-board": "Developer Board",
  "zmdi-disc-full": "Disc Full",
  "zmdi-explicit": "Explicit",
  "zmdi-flight-land": "Flight Land",
  "zmdi-flight-takeoff": "Take Off",
  "zmdi-flip-to-back": "Flip To Back",
  "zmdi-flip-to-front": "Flip To Front",
  "zmdi-group-work": "Group Work",
  "zmdi-hd": "HD",
  "zmdi-hq": "HQ",
  "zmdi-markunread-mailbox": "Mark Unread Mailbox",
  "zmdi-memory": "Memory",
  "zmdi-nfc": "NFC",
  "zmdi-play-for-work": "Play For Work",
  "zmdi-power-input": "Power Input",
  "zmdi-present-to-all": "Present To All",
  "zmdi-satellite": "Satellite",
  "zmdi-tap-and-play": "Tap and Play",
  "zmdi-vibration": "Vibration",
  "zmdi-voicemail": "Voicemail",
  "zmdi-group": "Group",
  "zmdi-rss": "RSS",
  "zmdi-shape": "Shape",
  "zmdi-spinner": "Spinner",
  "zmdi-ungroup": "Ungroup",
  "zmdi-500px": "500px",
  "zmdi-8tracks": "8 Tracks",
  "zmdi-amazon": "Amazon",
  "zmdi-blogger": "Blogger",
  "zmdi-delicious": "Delicious",
  "zmdi-disqus": "Disqus",
  "zmdi-flattr": "Flattr",
  "zmdi-flickr": "Flickr",
  "zmdi-github-alt": "Github",
  "zmdi-google-old": "Google",
  "zmdi-linkedin": "LinkedIn",
  "zmdi-odnoklassniki": "Odnoklassniki",
  "zmdi-outlook": "Outlook",
  "zmdi-paypal-alt": "Paypal",
  "zmdi-pinterest": "Pinterest",
  "zmdi-playstation": "Playstation",
  "zmdi-reddit": "Reddit",
  "zmdi-skype": "Skype",
  "zmdi-slideshare": "Slideshare",
  "zmdi-soundcloud": "Sound Cloud",
  "zmdi-tumblr": "Tumblr",
  "zmdi-twitch": "Twitch",
  "zmdi-vimeo": "Vimeo",
  "zmdi-whatsapp": "Whatsapp",
  "zmdi-xbox": "xbox",
  "zmdi-yahoo": "Yahoo",
  "zmdi-youtube-play": "Youtube Play",
  "zmdi-youtube": "Youtube",
  "zmdi-import-export": "Import / Export",
  "zmdi-swap-vertical-": "Swap Vertical",
  "zmdi-airplanemode-inactive": "Airplanemode Inactive",
  "zmdi-airplanemode-active": "Airplanemode Active",
  "zmdi-rate-review": "Rate Review",
  "zmdi-comment-sign": "Comment Sign",
  "zmdi-network-warning": "Network Warning",
  "zmdi-shopping-cart-add": "Cart Add",
  "zmdi-file-add": "File Add",
  "zmdi-network-wifi-scan": "Wifi Scan",
  "zmdi-collection-add": "Collection Add",
  "zmdi-format-playlist-add": "Format Playlist Add",
  "zmdi-format-queue-music": "Format Queue Music",
  "zmdi-plus-box": "Plus Box",
  "zmdi-tag-backspace": "Tag Backspace",
  "zmdi-alarm-add": "Alarm Add",
  "zmdi-battery-charging": "Battery Charging",
  "zmdi-daydream-setting": "Daydream Setting",
  "zmdi-more-horiz": "More Horiz",
  "zmdi-book-photo": "Book Photo",
  "zmdi-incandescent": "Incandescent",
  "zmdi-wb-iridescent": "Iridescent",
  "zmdi-calendar-remove": "Calendar Remove",
  "zmdi-refresh-sync-disabled": "Sync Disabled",
  "zmdi-refresh-sync-problem": "Sync Problem",
  "zmdi-crop-original": "Crop Original",
  "zmdi-power-off": "POwer Off",
  "zmdi-power-off-setting": "Power Off Setting",
  "zmdi-leak-remove": "Leak Remove",
  "zmdi-star-border": "Star Border",
  "zmdi-brightness-low": "Brightness Low",
  "zmdi-brightness-medium": "Brightness Medium",
  "zmdi-brightness-high": "Brightness High",
  "zmdi-smartphone-portrait": "Smartphone Portrait",
  "zmdi-live-tv": "Live TV",
  "zmdi-format-textdirection-l-to-r": "Format Textdirection l-to-r",
  "zmdi-format-textdirection-r-to-l": "Format Textdirection r-to-l",
  "zmdi-arrow-back": "Arrow Back",
  "zmdi-arrow-forward": "Arrow Forward",
  "zmdi-arrow-in": "Arrow In",
  "zmdi-arrow-out": "Arrow Out",
  "zmdi-rotate-90-degrees-ccw": "Rotate 90 - degrees ccw",
  "zmdi-adb": "ADB",
  "zmdi-network-wifi": "Wifi",
  "zmdi-network-wifi-alt": "Wifi Alt",
  "zmdi-network-wifi-lock": "Wifi Lock",
  "zmdi-network-wifi-off": "Wifi Off",
  "zmdi-network-wifi-outline": "Wifi Outline",
  "zmdi-network-wifi-info": "Wifi Info",
  "zmdi-layers-clear": "Layers Clear",
  "zmdi-colorize": "Colorize",
  "zmdi-format-paint": "Format Paint",
  "zmdi-format-quote": "Format Quote",
  "zmdi-camera-monochrome-photos": "Camera Monochrome",
  "zmdi-sort-by-alpha": "Sort By Alpha",
  "zmdi-folder-shared": "Folder Shared",
  "zmdi-folder-special": "Folder Special",
  "zmdi-comment-dots": "Comment Dots",
  "zmdi-reorder": "Reorder",
  "zmdi-dehaze": "Dehaze",
  "zmdi-sort": "Sort",
  "zmdi-pages": "Pages",
  "zmdi-stack-overflow": "Stack Overflow",
  "zmdi-calendar-account": "Calendar Account",
  "zmdi-paste": "Paste",
  "zmdi-cut": "Cut",
  "zmdi-save": "Save",
  "zmdi-smartphone-code": "Smartphone Code",
  "zmdi-directions-bike": "Bike",
  "zmdi-directions-boat": "Boat",
  "zmdi-directions-bus": "Bus",
  "zmdi-directions-car": "Car",
  "zmdi-directions-railway": "Railway",
  "zmdi-directions-run": "Run",
  "zmdi-directions-subway": "Subway",
  "zmdi-directions-walk": "Walk",
  "zmdi-local-hotel": "Hotel",
  "zmdi-local-activity": "Activity",
  "zmdi-local-play": "Play",
  "zmdi-local-airport": "Airport",
  "zmdi-local-atm": "ATM",
  "zmdi-local-bar": "Bar",
  "zmdi-local-cafe": "Cafe",
  "zmdi-local-car-wash": "Car Wash",
  "zmdi-local-convenience-store": "Convenience Store",
  "zmdi-local-dining": "Dining",
  "zmdi-local-drink": "Drink",
  "zmdi-local-florist": "Florist",
  "zmdi-local-gas-station": "Gas Station",
  "zmdi-local-grocery-store": "Grocery Store",
  "zmdi-local-hospital": "Hospital",
  "zmdi-local-laundry-service": "Laundry Service",
  "zmdi-local-library": "Library",
  "zmdi-local-mall": "Mall",
  "zmdi-local-movies": "Movies",
  "zmdi-local-offer": "Offers",
  "zmdi-local-parking": "Parking",
  "zmdi-local-pharmacy": "Pharmacy",
  "zmdi-local-phone": "Phone",
  "zmdi-local-pizza": "Pizza",
  "zmdi-local-post-office": "Post Office",
  "zmdi-local-printshop": "Print Shop",
  "zmdi-local-see": "",
  "zmdi-local-shipping": "Shipping",
  "zmdi-local-store": "Store",
  "zmdi-local-taxi": "Taxi",
  "zmdi-local-wc": "WC",
  "zmdi-my-location": "LOcation",
  "zmdi-directions": "Directions",
};
