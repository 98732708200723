/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";
import FieldError from "components/FieldError";

import { FormControl, Select, Button } from "@mui/material";

import JoditEditor from "jodit-react";

import {
  getTemplates,
  getTemplatesData,
  updateTemplatesData,
} from "modules/settings/stores/settings";
import { setLoader } from "modules/themes/stores/themeSettings";
import { AppTrlns } from "config";
import { AppConstants as MainAppConstants } from "config";

const ManageLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const editor = useRef(null);
  const [templateType, setTemplateType] = useState(1);
  const [content, setContent] = useState("");
  const [editId, setEditId] = useState(
    location?.state?.ID ? location.state.ID : ""
  );
  const dummyData = {
    "{{logo}}": `<img src="${MainAppConstants.fsPath}accounts/dummy-logo-transparent-png.png" alt="Logo" width="152px" height="91px"/>`,
    "{{no}}": "#0001",
    "{{date}}": "05.01.2022",
    "{{to_address}}":
      "Jennifer Richards <br>365 Bloor Street East, Toronto, <br>Ontario, M4W 3L4, <br>Canada",
    "{{pay_to}}":
      "Biman Airlines <br>237 Roanoke Road, North York, <br>Ontario, Canada <br>demo@email.com",
    "{{from_address}}":
      "Jennifer Richards <br>365 Bloor Street East, Toronto, <br>Ontario, M4W 3L4, <br>Canada",
    "{{shipping_address}}":
      "Biman Airlines <br>237 Roanoke Road, North York, <br>Ontario, Canada <br>demo@email.com",

    "{{rfq_terms}}": "Sample Terms",
    "{{payment_terms}}": "Immediate Payment",

    "{{payment_details}}":
      "<li><strong>Account No:</strong> 00 123 647 840</li>\
     <li><strong>Account Name:</strong> Biman Airlines</li>\
     <li><strong>Branch Name:</strong> xyz</li>",
    "{{itemHead}}":
      "<tr> \
       <th width='20%'>Item</th> \
       <th width='20%'>Description</th> \
       <th width='6%'>Qty</th> \
       <th width='12%'>UOM</th> \
       <th width='6%'>Pckg Qty</th> \
       <th width='20%'>Package</th> \
     </tr>",
    "{{itemRows}}":
      "<tr> \
     <th>Large Cabinet</th> \
     <th>Large Cabinet</th> \
     <th>1</th> \
     <th>Units</th> \
     <th>1</th> \
     <th>Box</th> \
   </tr>",
    "{{itemSummary}}":
      "<table> \
               <tbody> \
                 <tr> \
                   <td>Gross Total</td> \
                   <td>₹4261.00</td> \
                 </tr> \
                 <tr> \
                   <td>Total</td> \
                   <td>₹4261.00</td> \
                 </tr> \
               </tbody> \
             </table> ",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    dispatch(getTemplates(templateType));
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", trackBodyClick);

    return function cleanup() {
      window.removeEventListener("click", trackBodyClick);
    };
  }, []);

  const { templateList } = useSelector((store) => store.settings.settings);

  const replacePreviewHtml = (text) => {
    let replacedText = text;
    Object.keys(dummyData).forEach(function (key) {
      replacedText = replacedText.replaceAll(key, dummyData[key]);
    });
    return replacedText;
  };

  const trackBodyClick = () => {
    if (document.getElementsByClassName("jodit-dialog__content").length > 0) {
      let previewHtml = replacePreviewHtml(
        document.getElementsByClassName("jodit-dialog__content")[0].innerHTML
      );

      document.getElementsByClassName("jodit-dialog__content")[0].innerHTML =
        previewHtml;
    }
  };

  const handleOnSave = (form) => {
    // console.log("--------handleOnSave called------------", form);
    form["content"] = escapeHtml(content);
    dispatch(setLoader(true));
    dispatch(updateTemplatesData(form))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        setContent(null);
        reset();
        clearErrors();
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const escapeHtml = (text) => {
    var map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    };

    return text.replace(/[&<>"']/g, function (m) {
      return map[m];
    });
  };

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  const handleOnError = (errors, e) => {
    console.log("--------handleOnError called------------", errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });
    // console.log(name, value);
    if (name == "layoutType") {
      dispatch(getTemplates(value));
    }
    if (name == "title") {
      if (value != 0) {
        dispatch(setLoader(true));
        dispatch(getTemplatesData(value))
          .catch((err) => {
            alert(err.msg);
          })
          .then((response) => {
            setContent(htmlDecode(response[0]["ST_Content"]));
            setValue("layoutStatus", response[0]["ST_Status"]);
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      } else {
        setContent(null);
      }
    }
  };

  const config = {
    removeButtons: ["image", "video", "link", "about"],
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  return (
    <div className="container-fluid ecom-dashboard-wrapper mb-10">
      <Helmet>
        <title>{AppTrlns("settings:manageLayout.sceneTitle")}</title>
        <meta name="description" content="Layout" />
      </Helmet>
      <PageTitleBar
        title={<TextTranslation text={"settings:manageLayout.sceneTitle"} />}
        location={location}
      />

      <form onSubmit={handleSubmit(handleOnSave, handleOnError)}>
        <div className="row modulePageBody mb-4">
          <div className="col-md-12">
            <div className="card m-2">
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageLayout.formLabels.layoutType"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="layoutType"
                            error={errors?.layoutType ? true : false}
                            {...register("layoutType", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="1" value="1">
                              Invoice
                            </option>
                          </Select>
                          {errors?.layoutType?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageLayout.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={"settings:manageLayout.formLabels.title"}
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="title"
                            error={errors?.title ? true : false}
                            {...register("title", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="0" value="">
                              Select
                            </option>
                            {templateList &&
                              Object.keys(templateList).map((key) => {
                                return (
                                  <option
                                    key={templateList[key].ST_Id}
                                    value={templateList[key].ST_Id}
                                  >
                                    {templateList[key].ST_Name}
                                  </option>
                                );
                              })}
                          </Select>
                          {errors?.title?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageLayout.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-3">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageLayout.formLabels.layoutStatus"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            native
                            id="layoutStatus"
                            error={errors?.layoutStatus ? true : false}
                            {...register("layoutStatus", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e)}
                          >
                            <option key="1" value="1">
                              Active
                            </option>
                            <option key="2" value="2">
                              Blocked
                            </option>
                          </Select>
                          {errors?.layoutStatus?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageLayout.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group col-md-1">
                        <Button
                          type="submit"
                          size="small"
                          className="btn btn-success ml-auto mr-2 text-light"
                          variant="contained"
                          onClick={handleSubmit(handleOnSave)}
                        >
                          <TextTranslation
                            text={AppTrlns(
                              "settings:manageLayout.buttonText.Save"
                            )}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="inputLabelText">
                          <span>
                            <TextTranslation
                              text={
                                "settings:manageLayout.formLabels.layoutContent"
                              }
                            />
                          </span>
                        </div>
                        <FormControl variant="standard" fullWidth>
                          <JoditEditor
                            ref={editor}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)}
                            value={content}
                          />
                          {/* <TextField
                             hidden
                             //sx={{ visibility: "hidden" }}
                             error={errors?.layoutContent ? true : false}
                             {...register("layoutContent", {
                               //required: true,
                             })}
                             type="text"
                             rows={4}
                             id="layoutContent"
                             variant="outlined"
                             value={content}
                             fullWidth
                           /> */}
                          {errors?.layoutContent?.type === "required" && (
                            <FieldError
                              errorText={
                                <TextTranslation
                                  text={
                                    "settings:manageLayout.errorText.required"
                                  }
                                />
                              }
                            />
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ManageLayout;
