/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

// Components
import UserBlock from "./UserBlock";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  const { enableSidebarBackgroundImage, selectedSidebarImage, isDarkSidenav } =
    useSelector((store) => store.themes.themeSettings);

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  return (
    <Fragment>
      <div
        className={classNames("rct-sidebar", {
          "background-none": !enableSidebarBackgroundImage,
        })}
        style={{
          backgroundImage: enableSidebarBackgroundImage
            ? "url(" + require(`assets/img/${selectedSidebarImage}`) + ")"
            : "none",
        }}
      >
        <div
          className={classNames("rct-sidebar-content", {
            "sidebar-overlay-dark": isDarkSidenav,
            "sidebar-overlay-light": !isDarkSidenav,
          })}
        >
          <div className="site-logo">
            <Link to="/" className="logo-mini">
              <img
                src={require("assets/img/appLogo.png")}
                className="mr-15"
                alt="site logo"
                width="35"
                height="35"
              />
            </Link>
            <Link to="/" className="logo-normal">
              <img
                src={require("assets/img/appLogoText.png")}
                className="img-fluid"
                alt="site-logo"
                width="67"
                height="17"
              />
            </Link>
            <div className="companySection">{appConstants["CMP_Name"]}</div>
          </div>
          <div className="rct-sidebar-wrap">
            <Scrollbars
              className="rct-scroll"
              autoHide
              autoHideDuration={100}
              style={{ height: "calc(100vh - 60px)" }}
            >
              <UserBlock />
              <SidebarContent />
            </Scrollbars>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
