/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar AR
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/
import {
  COLLAPSED_SIDEBAR,
  DARK_MODE,
  RTL_LAYOUT,
  MINI_SIDEBAR,
  SEARCH_FORM_ENABLE,
  CHANGE_THEME_COLOR,
  TOGGLE_SIDEBAR_IMAGE,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  SET_USER_TOUR,
  TOGGLE_DARK_SIDENAV,
  TOGGLE_MENU,
  SET_SHOW_LOADER,
  SET_MENU_TYPE,
  SET_SIDEBAR_MENU,
  SET_APPLICATION_ACL,
  THIS_PAGE_ACL,
} from ".";

// App Config
import { AppConfig } from "config";

//--------- Initial Application Settings --------------
const INIT_STATE = {
  darkMode: AppConfig.darkMode,
  rtlLayout: AppConfig.rtlLayout,
  miniSidebar: AppConfig.miniSidebar,
  selectedSidebarImage: AppConfig.sidebarImage,
  activeTheme: { id: 1, name: "primary" },
  locale: AppConfig.locale,
  menuType: AppConfig.menuType,
  isDarkSidenav: AppConfig.isDarkSidenav,
  chatMode: AppConfig.chatMode,
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background

  showLoader: false,
  navCollapsed: AppConfig.navCollapsed,
  searchFormOpen: false, // search form by default false
  userTour: false,
  sidebarMENU: [],
  userACL: [],
  thisPageACL: "NA",
  themes: [
    { id: 1, name: "primary" },
    { id: 2, name: "secondary" },
    { id: 3, name: "warning" },
    { id: 4, name: "info" },
    { id: 5, name: "danger" },
    { id: 6, name: "success" },
  ],

  // sidebar background image
  sidebarBackgroundImages: [
    "sidebar-1.jpg",
    "sidebar-2.jpg",
    "sidebar-3.jpg",
    "sidebar-4.jpg",
  ],

  languages: [
    {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "en",
      mode: "ltr",
    },
  ],

  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      let menuOBJ = JSON.parse(state.sidebarMENU[action.payload.moduleName]);
      let index = menuOBJ
        .map((el) => el.MN_Id)
        .indexOf(action.payload.menu.menu_id);
      menuOBJ[index]["MN_Open"] = menuOBJ[index]["MN_Open"] ? 0 : 1;
      state.sidebarMENU[action.payload.moduleName] = JSON.stringify(menuOBJ);

    // collapse sidebar
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed };

    // start / stop user tour
    case SET_USER_TOUR:
      return { ...state, userTour: action.payload };

    // change theme color
    case CHANGE_THEME_COLOR:
      return { ...state, activeTheme: action.payload };

    // dark mode
    case DARK_MODE:
      return { ...state, darkMode: action.payload };

    // rtl layout
    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload };

    // mini sidebar
    case MINI_SIDEBAR:
      return { ...state, miniSidebar: action.payload };

    // search form
    case SEARCH_FORM_ENABLE:
      document.body.classList.toggle("search-active", !state.searchFormOpen);
      return { ...state, searchFormOpen: !state.searchFormOpen };

    // togglw sidebar image
    case TOGGLE_SIDEBAR_IMAGE:
      return {
        ...state,
        enableSidebarBackgroundImage: !state.enableSidebarBackgroundImage,
      };

    // set sidebar image
    case SET_SIDEBAR_IMAGE:
      return { ...state, selectedSidebarImage: action.payload };

    // set language
    case SET_LANGUAGE:
      return { ...state, locale: action.payload };

    // dark sidenav
    case TOGGLE_DARK_SIDENAV:
      return { ...state, isDarkSidenav: !state.isDarkSidenav };

    // set sidebar nenu
    case SET_SIDEBAR_MENU:
      return {
        ...state,
        sidebarMENU: {
          ...state.sidebarMENU,
          [action.payload.module]: action.payload.menu,
        },
      };

    // application ACL
    case SET_APPLICATION_ACL:
      return { ...state, userACL: action.payload };

    // Current Page ACL
    case THIS_PAGE_ACL:
      return { ...state, thisPageACL: action.payload };

    case SET_MENU_TYPE:
      return { ...state, menuType: action.payload };

    case SET_SHOW_LOADER:
      return { ...state, showLoader: action.payload };

    default:
      return { ...state };
  }
};
