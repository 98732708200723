/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect, useRef } from "react";

// import { Button } from "reactstrap";
import MUIDataTable from "mui-datatables";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";

import Anotation from "components/Anotation";

import {
  TableCell,
  Tooltip,
  IconButton,
  TableSortLabel,
  Button,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Print,
  CloudDownload,
  FormatListNumberedOutlined,
  ViewKanbanOutlined,
  Add,
  InfoOutlined,
  Close,
} from "@mui/icons-material";
import { border } from "@mui/system";

let iconFont = {
  DoneIcon: "✔",
  EditIcon: "✎",
  CloseIcon: "✖",
  DeleteIcon: "🗑️",
  VisibilityIcon: "👁",
};

const Datatable = (props) => {
  //console.log("page>>>>", props.page);

  const componentRef = useRef();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [jumpToPage, setJumpToPage] = useState(0);
  const [showAnotation, setShowAnotation] = useState(false);

  const columns = [];

  useEffect(() => {
    if (dataLoad) {
      props.handleFilter(page, rowsPerPage, searchText, filterList, sortOrder);
      setDataLoad(false);
    }
  }, [dataLoad]);

  // useEffect(() => {
  //   if (props.tabIndex) {
  //     console.log(document.getElementById("printDIV"));
  //     let printDivLength = document.getElementById("printDIV")
  //       ? document.getElementById("printDIV").length
  //       : 0;
  //     console.log(printDivLength);
  //     if (printDivLength == 0) {
  //       document.getElementsByClassName("muiDataTableCustom")[0].innerHTML +=
  //         "<div></div>";
  //     }
  //   }
  // }, [props.tabIndex]);

  props.column.map((value, i) => {
    columns.push({
      name: value["name"],
      options: {
        //filterList: filterList[i],
        display: value["display"] ? value["display"] : true,
        customHeadRender: ({ index, ...column }, updateDirection) => {
          // console.log(value["columnArray"]);

          return (
            <TableCell
              className=" p-0"
              key={index}
              align={
                value["style"] && value["style"]["textAlign"]
                  ? value["style"]["textAlign"]
                  : "inherit"
              }
              onClick={() => (value["sort"] ? updateDirection(index) : null)}
              style={{ cursor: value["sort"] ? "pointer" : "default" }}
            >
              {/* {console.log(value["columnArray"])} */}
              {column.name === "TotalBillAmount" ? (
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        textAlign: "center",

                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      Total Bill Amount
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      Base Currency
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      Transaction Currency
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : column.name === "GST" && value["columnArray"].length == 0 ? (
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      GST
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      CGST
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      SGST
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      IGST
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : column.name === "GST" && value["columnArray"].length > 0 ? (
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{
                        textAlign: "center",
                        width: "10%",
                        borderBottom: 0,
                        borderTop: 0,
                        border: 0,
                      }}
                    >
                      GST
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {value["columnArray"].map((val, key) => {
                      return (
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "10%",
                            borderBottom: 0,
                            borderTop: 0,
                            border: 0,
                          }}
                        >
                          {val}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              ) : (
                column.name
              )}

              {value["sort"] ? (
                <TableSortLabel
                  className="tableSortIcon"
                  active={
                    sortOrder["name"] === column.name &&
                    sortOrder["direction"] !== null
                  }
                  direction={
                    (sortOrder["name"] === column.name &&
                      sortOrder["direction"]) ||
                    "asc"
                  }
                />
              ) : null}
            </TableCell>
          );
        },
        setCellProps: () => ({
          style: value["style"],
        }),
        filter: value["filter"] ? true : false,
        sort: value["sort"] ? true : false,
        filterOptions: {
          names: value["filter"] ? value["filter"] : null,
        },
      },
    });
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printDocumentType = () => {
    let tableData;
    if (props.tabIndex) {
      tableData = document
        .querySelector('div[tabid="' + props.tabIndex + '"]')
        .querySelectorAll(".MuiTable-root")[0].innerHTML;
      // console.log(
      //   document
      //     .querySelector('div[tabid="' + props.tabIndex + '"]')
      //     .querySelectorAll(".MuiTable-root")
      // );
    } else {
      tableData = document.querySelectorAll(".MuiTable-root")[0].innerHTML;
    }
    // console.log(
    //   tableData,
    //   "-------------",
    //   document.getElementById("printDIV")
    // );
    document.getElementById("printDIV").innerHTML = tableData;
    //setTimeout(() => {
    //alert("print");
    handlePrint();
    //}, 3000);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: componentRef.current,
    filename: props.title,
    sheet: props.title,
  });

  const exportToExcel = () => {
    document.getElementById("printDIV").innerHTML =
      document.querySelectorAll(".MuiTable-root")[0].innerHTML;

    document
      .getElementById("printDIV")
      .querySelectorAll("svg")
      .forEach((nodeSVG) => {
        let thisIcon = nodeSVG.getAttribute("data-testid");
        if (iconFont[thisIcon]) {
          nodeSVG.parentElement.innerHTML = iconFont[thisIcon];
        }
      });
    onDownload();
  };

  const viewAnotationData = () => {
    setShowAnotation(true);
  };

  const options = {
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Print" className="pointerCursor">
            <IconButton
              className="customizedDatatableBtn1"
              onClick={(e) => printDocumentType()}
            >
              <Print />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download CSV" className="pointerCursor">
            <IconButton
              className="customizedDatatableBtn1"
              onClick={(e) => exportToExcel()}
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>

          {props.displayType ? (
            <Tooltip
              title={props.displayType == "list" ? "Kanban" : "List"}
              className="pointerCursor"
            >
              <IconButton
                onClick={() => {
                  props.manageView(
                    props.displayType == "list" ? "kanban" : "list"
                  );
                }}
              >
                {props.displayType == "list" ? (
                  <ViewKanbanOutlined />
                ) : (
                  <FormatListNumberedOutlined />
                )}
              </IconButton>
            </Tooltip>
          ) : null}

          {props.showAnotationTooltip ? (
            <Tooltip title="Anotation Data" className="pointerCursor">
              <IconButton
                className="customizedDatatableBtn1"
                onClick={(e) => viewAnotationData()}
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          ) : null}
          {props.createNewDatatTable ? (
            <Button
              className={props.hideCreateNewDataTable ? "d-none" : ""}
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={(e) => props.createNewDatatTable()}
            >
              Create New
            </Button>
          ) : null}

          {props.excelImport ? props.excelImport() : null}
          {props.finalizeButton ? props.finalizeButton() : null}
        </>
      );
    },
    customRowRender: props.customRowRender ? props.customRowRender : null,
    rowsExpanded: props.rowsExpanded
      ? props.data.map((el, i) => {
          return i;
        })
      : null,
    pagination: props.pagination === false ? false : true,
    rowHover: true,
    filterType: "dropdown",
    selectableRows: props.selectableRows ? props.selectableRows : "none",
    customTableBodyFooterRender: (opts) => {
      return props.customTableBodyFooterRender
        ? props.customTableBodyFooterRender(opts)
        : null;
    },
    customToolbarSelect: (selectedRows) => {
      return props.customToolbarSelect(selectedRows);
    },
    onRowSelectionChange: (selectedRows, displayData, setSelectedRows) => {
      return props.onRowSelectionChange
        ? props.onRowSelectionChange(selectedRows, displayData, setSelectedRows)
        : null;
    },
    isRowSelectable: function (dataIndex) {
      return props.isRowSelectable ? props.isRowSelectable(dataIndex) : true;
    },
    rowsSelected: props.rowsSelected ? props.rowsSelected : [],
    print: false,
    download: false,
    search: props.search === false ? false : true,
    // search: props.search ? props.search : true,
    searchPlaceholder: props.searchPlaceHolder
      ? props.searchPlaceHolder
      : "Search....",
    //searchText: props.initDataTable ? null : searchText,
    filter: props.filter === false ? false : true,
    responsive: "standard",
    serverSide: props.serverSide ? props.serverSide : true,
    count: props.totalCount,
    rowsPerPage: props.rowsPerPage ? props.rowsPerPage : rowsPerPage,
    //page: props.initDataTable ? 0 : page,
    filterList: props.filterList ? props.filterList : [],
    rowsPerPageOptions: props.rowsPerPageOptions
      ? props.rowsPerPageOptions
      : [
          { label: "10", value: 10 },
          { label: "25", value: 25 },
          { label: "50", value: 50 },
          { label: "ALL", value: props.totalCount },
        ],
    sortFilterList: true,
    sort: true,
    sortOrder: sortOrder,
    confirmFilters: true,
    viewColumns: props.hideColumns
      ? false
      : props.displayType && props.displayType == "kanban"
      ? false
      : true,
    expandableRows: props.expandableRows ? props.expandableRows : false,
    renderExpandableRow: props.renderExpandableRow
      ? props.renderExpandableRow
      : null,
    jumpToPage: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => {
              setFilterList(applyNewFilters);
              setPage(0);
              setDataLoad(true);
            }}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onTableInit: (action, tableState) => {
      //console.log(action, tableState);
    },
    onTableChange: (action, tableState) => {
      //console.log("action", action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          setDataLoad(true);
          break;
        case "search":
          setPage(0);
          tableState.page = 0;
          setSearchText(
            tableState.searchText ? tableState.searchText.trim() : ""
          );
          setDataLoad(true);
          break;
        case "sort":
          setPage(0);
          tableState.page = 0;
          let sortDetails = tableState.sortOrder;
          sortDetails["index"] = tableState.activeColumn;
          setSortOrder(sortDetails);
          setDataLoad(true);
          break;
        case "changeRowsPerPage":
          setPage(0);
          tableState.page = 0;
          setRowsPerPage(tableState.rowsPerPage);
          setDataLoad(true);
          break;
        case "propsUpdate":
          if (props.initDataTable) {
            //console.log("iam innn");
            tableState.page = 0;
            tableState.filterData = [];
            tableState.filterList = [];
            tableState.sortOrder = {};
            tableState.searchText = null;
            setPage(0);
            setRowsPerPage(10);
            //setSortOrder({});
            //setSearchText(null);
            //setFilterList([]);
          }
          setDataLoad(false);
          break;
        case "onSearchClose":
          setPage(0);
          tableState.page = 0;
          break;
        default:
          break;
      }
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      setPage(0);
      setFilterList(filterList);
      setDataLoad(true);
    },
  };

  return (
    <>
      <div className="muiDataTableCustom">
        <MUIDataTable
          title={props.title}
          data={props.data}
          columns={columns}
          options={options}
          className="table table-bordered"
        />
        {props.defaultTab ? (
          <div className="printSection" style={{ display: "none" }}>
            <table id="printDIV" ref={componentRef} className="w-100"></table>
          </div>
        ) : !props.tabIndex ? (
          <div className="printSection" style={{ display: "none" }}>
            <table id="printDIV" ref={componentRef} className="w-100"></table>
          </div>
        ) : null}
      </div>
      <Anotation
        showAnotation={showAnotation}
        setShowAnotation={setShowAnotation}
      />
    </>
  );
};
export default Datatable;
