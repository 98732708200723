/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useEffect } from "react";

const ModuleFooter = () => {
  return (
    <footer className="footer moduleFooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12 footer-copyright">
            <p className="mb-0">
              Copyright 2023 © SIMPLE PLANS All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ModuleFooter;
