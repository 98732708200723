/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { AppTrlns } from "config";

import { setLoader } from "modules/themes/stores/themeSettings";
import { initMyProfileDetails } from "modules/resources/stores/resources";
import { FormControl, TextField } from "@mui/material";

const MyProfile = () => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((store) => store.resources.resources);

  const [myProfileImage, setMyProfileImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initMyProfileDetails())
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {})
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, []);

  useEffect(() => {
    if (userDetails) {
      console.log("response_______________", userDetails);
      setValue("myProfile_username", userDetails["UserName"]);
      setValue("myProfile_firstname", userDetails["FirstName"]);
      setValue("myProfile_lastname", userDetails["LastName"]);
      setValue("myProfile_email", userDetails["Email"]);
      setValue("myProfile_contactNumber", userDetails["ContactNumber"]);
      setValue("myProfile_alternateNumber", userDetails["AltNumber"]);
      setValue("myProfile_personalAddress", userDetails["PersonalAddress"]);
      setValue("myProfile_flatNumber", userDetails["HouseorFlat"]);
      setValue("myProfile_street", userDetails["Street"]);
      setValue("myProfile_suburbName", userDetails["Suburb"]);
      setValue("myProfile_state", userDetails["State"]);
      setValue("myProfile_suburbId", userDetails["SuburbID"]);
      setValue(
        "myProfile_status",
        parseInt(userDetails["Status"]) == 1 ? "Active" : "Inactive"
      );
      setMyProfileImage(userDetails["ProfileImage"]);
      setFirstName(userDetails["FirstName"]);
      setLastName(userDetails["LastName"]);
      setUsername(userDetails["UserName"]);
      setUserPhone(userDetails["ContactNumber"]);
      setUserAddress(userDetails["PersonalAddress"]);
    }
  }, [userDetails]);

  return (
    <div className="container-fluid ecom-dashboard-wrapper mb-10">
      <Helmet>
        <title>{AppTrlns("resources:myProfile.sceneTitle")}</title>
        <meta name="description" content="Manage Users" />
      </Helmet>

      <form className="form-horizontal" id="invoice_line_items">
        <div className="formWrapper">
          <div className="formContent">
            <div className="form_view">
              <div className="formSheetBg pl-0 pr-0">
                <div className="formStatusBar pl-1">
                  <div className="formStatusBarButtons">
                    <h3>{AppTrlns("resources:myProfile.sceneTitle")}</h3>
                  </div>
                </div>
                <div className="clearfix position-relative transactionFormSheet">
                  <div className="transactionFormGroup">
                    <table className="transactionFormInnerGroup">
                      <tbody>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Username"
                                  label="Username"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_username")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="First Name"
                                  label="First Name"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_firstname")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Last Name"
                                  label="Last Name"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_lastname")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Email"
                                  label="Email"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_email")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Contact Number"
                                  label="Contact Number"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_contactNumber")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Alternate Number"
                                  label="Alternate Number"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_alternateNumber")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Personal Address"
                                  label="Personal Address"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_personalAddress")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="House/Unit/Flat Number"
                                  label="House/Unit/Flat Number"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_flatNumber")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Street Name"
                                  label="Street Name"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_street")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Suburb Name"
                                  label="Suburb Name"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_suburbName")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="State Name"
                                  label="State Name"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_state")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Suburb Id"
                                  label="Suburb Id"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_suburbId")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="td_input">
                            <div className="fieldWidget">
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Status"
                                  label="Status"
                                  inputProps={{ readOnly: true }}
                                  {...register("myProfile_status")}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="transactionFormInnerGroup">
                      <tbody>
                        <div className="container mt-4 mb-4 p-3 d-flex justify-content-center w-100">
                          <div className="card p-4 w-100">
                            <div className=" image d-flex flex-column justify-content-center align-items-center">
                              <button className="btn btn-secondary">
                                <img
                                  src={myProfileImage}
                                  height={100}
                                  width={100}
                                />
                              </button>
                              <span className="name mt-3">{`${firstName} ${lastName}`}</span>
                              <span className="idd">{username}</span>
                              <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                <span className="idd1">{userEmailId}</span>
                                <span>
                                  <i className="fa fa-copy" />
                                </span>
                              </div>
                              <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                                <span className="number">{userPhone}</span>
                              </div>
                              <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                                <span className="number">{userAddress}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default MyProfile;
