/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment, useEffect } from "react";

import ThemesNavigation from "modules/themes/navigation";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// import { popuateMenu } from "modules/themes/stores/generalSettings";

import "modules/themes/assets/css/custom.scss";

import "./stores";
import "./translations";

const Themes = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let moduleName = pathname.split("/")[1];

  // useEffect(() => {
  //   dispatch(popuateMenu(moduleName))
  //     .catch((err) => {
  //       alert(err.msg); // eslint-disable-line no-alert
  //     })
  //     .then(() => {})
  //     .finally(() => {});
  // }, []);

  return (
    <Fragment>
      <ThemesNavigation />
    </Fragment>
  );
};
export default Themes;
