export const alpha_numeric = () => {
  return /^[a-zA-Z0-9]*$/;
};

export const alpha_numeric_space = () => {
  return /^[a-zA-Z0-9\d\-_\s]+$/i;
};

export const price_validation = (value) => {
  return /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(value);
};

export const integer_validation = (value) => {
  return /^[0-9]+$/.test(value);
};

export const validate_date = (dateValue, minDate = "", maxDate = "") => {
  if (dateValue) {
    if (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(dateValue)) {
      if (minDate) {
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0, 0);
      }
      if (maxDate) {
        maxDate.setHours(0);
        maxDate.setMinutes(0);
        maxDate.setSeconds(0, 0);
      }
      if (dateValue) {
        dateValue = new Date(dateValue);
        dateValue.setHours(0);
        dateValue.setMinutes(0);
        dateValue.setSeconds(0, 0);
      }
      if (minDate && dateValue.getTime() < minDate.getTime()) {
        return false;
      }
      if (maxDate && dateValue.getTime() > maxDate.getTime()) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
};

export const minDate = (dateValue, minDate) => {
  if (dateValue && new Date(dateValue).getTime() >= minDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

export const pan_validation = (value) => {
  return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value);
};

export const cin_validation = (value) => {
  return /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/.test(
    value
  );
};
export const tin_validation = (value) => {
  return /^\d{2}-\d{7}$/.test(
    // ssn or tin use this -> /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/
    value
  );
};
// ------------------------------------------------------> vat validation for UAE
export const vat_validation = (value) => {
  return /^(\d{12}[A-Z0-9]{1})$/.test(value);
};
export const gst_validation = (value) => {
  return /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
    value
  );
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
