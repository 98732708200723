/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  LinearProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";

import { useForm } from "react-hook-form";

import { Navigate, useNavigate } from "react-router-dom";

// app config
import { AppConfig } from "config";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";

import FieldError from "components/FieldError";

const TermsOfService = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState(useRef({}));
  const [confirmPassword, setConfirmPassword] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  password.current = watch("password", "");

  const onSubmit = (form) => {
    //   dispatch(login())
    //     .catch((err) => {
    //       alert(err.msg); // eslint-disable-line no-alert
    //     })
    //     .then(() => {
    //       navigate("/");
    //     })
    //     .finally(() => {});
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src={AppConfig.appLogo}
                      alt="session-logo"
                      className="img-fluid"
                      width="175"
                    />
                  </Link>
                </div>
                <div>
                  <a
                    className="mr-15"
                    onClick={() => navigate("/resources/signin")}
                  >
                    Already have an account?
                  </a>
                  <Button
                    variant="contained"
                    className="btn-light text-dark"
                    onClick={() => navigate("/resources/signin")}
                  >
                    Sign In
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-7 col-md-7 col-lg-8 mx-auto">
                <div className="session-body text-center">
                  <div>Terms Of Service</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};
export default TermsOfService;
