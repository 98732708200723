/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar AR
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/
import {
  COLLAPSED_SIDEBAR,
  DARK_MODE,
  RTL_LAYOUT,
  TOGGLE_MENU,
  MINI_SIDEBAR,
  SEARCH_FORM_ENABLE,
  CHANGE_THEME_COLOR,
  TOGGLE_SIDEBAR_IMAGE,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  SET_USER_TOUR,
  TOGGLE_DARK_SIDENAV,
  SET_SHOW_LOADER,
  SET_MENU_TYPE,
  THIS_PAGE_ACL,
} from ".";

import {
  __setLanguage,
  __setSidebarBgImageAction,
  __darkModeAction,
  __setDarkSideNav,
  __rtlLayoutAction,
  __miniSidebarAction,
  __changeThemeColor,
  __updateMenuTable,
  __setMenuType,
  __setForceMaintenance,
} from "./modules";

import { setSidebarMenu } from "utils/pivot";
import { batch } from "react-redux";

/**
 * Redux Action To Emit Collapse Sidebar
 * @param {*boolean} isCollapsed
 */
export const collapsedSidebarAction = (isCollapsed) => ({
  type: COLLAPSED_SIDEBAR,
  isCollapsed,
});

/**
 * Redux Action To Start / Stop User Tour
 */
export const setUserTour = (isUserTour) => ({
  type: SET_USER_TOUR,
  payload: isUserTour,
});

/**
 * Redux Action To Emit Dark Mode
 * @param {*boolean} isDarkMode
 */

export const darkModeAction = (isDarkMode) => (dispatch) => {
  return __darkModeAction(isDarkMode).then((response) => {
    dispatch({
      type: DARK_MODE,
      payload: isDarkMode,
    });
  });
};
/**
 * Redux Action To Emit Rtl Layout
 *  @param {*boolean} isRtlLayout
 */
export const rtlLayoutAction = (isRtlLayout) => (dispatch) => {
  return __rtlLayoutAction(isRtlLayout).then((response) => {
    dispatch({
      type: RTL_LAYOUT,
      payload: isRtlLayout,
    });
  });
};
/**
 * Redux Action To Toggle Sidebar Menus
 */
export const onToggleMenu = (selectedMenu) => (dispatch) => {
  dispatch({
    type: TOGGLE_MENU,
    payload: selectedMenu,
  });
};

/**
 * Redux Action To Emit Mini Sidebar
 */
export const miniSidebarAction = (isMiniSidebar) => (dispatch) => {
  return __miniSidebarAction(isMiniSidebar).then((response) => {
    dispatch({
      type: MINI_SIDEBAR,
      payload: isMiniSidebar,
    });
  });
};

/**
 * Reduc Action To Change Theme Colors
 */
export const changeThemeColor = (theme) => (dispatch) => {
  return __changeThemeColor(theme).then((response) => {
    dispatch({
      type: CHANGE_THEME_COLOR,
      payload: theme,
    });
  });
};

/**
 * Redux Action To Enable/Disable Sidebar Background Image
 */
export const toggleSidebarImage = () => ({
  type: TOGGLE_SIDEBAR_IMAGE,
});

/**
 * Redux Action To Set Sidebar Background Image
 */
export const setSidebarBgImageAction = (sidebarImage) => (dispatch) => {
  return __setSidebarBgImageAction(sidebarImage).then((response) => {
    dispatch({
      type: SET_SIDEBAR_IMAGE,
      payload: sidebarImage,
    });
  });
};

/**
 * Redux Action To Set Language
 */
export const setLanguage = (language) => (dispatch) => {
  return __setLanguage(language).then((response) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
  });
};

/**
 * Redux Action To Toggle Dark Sidenav
 */
export const setDarkSideNav = (isDarkSideNav) => (dispatch) => {
  return __setDarkSideNav(isDarkSideNav).then((response) => {
    dispatch({
      type: TOGGLE_DARK_SIDENAV,
      payload: isDarkSideNav,
    });
  });
};

/**
 * Redux Action To Load Full Page Loader
 */
export const setLoader = (showLoader) => ({
  type: SET_SHOW_LOADER,
  payload: showLoader,
});

/**
 * Redux Action To Toggle Menu Type
 */
export const setMenuType = (isHrMenu) => (dispatch) => {
  return __setMenuType(isHrMenu).then((response) => {
    dispatch({
      type: SET_MENU_TYPE,
      payload: isHrMenu == true ? "hr" : "vr",
    });
  });
};

/**
 * Redux Action To Set Current Page ACL
 */
export const setCurrentPageACL = (dataACL) => ({
  type: THIS_PAGE_ACL,
  payload: dataACL,
});
