/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Scrollbars } from "react-custom-scrollbars";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { AppConfig } from "config";

// Redux Modules
import {
  toggleSidebarImage,
  setSidebarBgImageAction,
  miniSidebarAction,
  darkModeAction,
  setMenuType,
  rtlLayoutAction,
  changeThemeColor,
  toggleDarkSidebar,
  setDarkSideNav,
  collapsedSidebarAction,
  setLoader,
} from "modules/themes/stores/themeSettings";

const ThemeOptions = () => {
  const dispatch = useDispatch();

  const [themeOptionPanelOpen, setThemeOptionPanelOpen] = useState(false);

  const {
    darkMode,
    rtlLayout,
    miniSidebar,
    themes,
    activeTheme,
    isDarkSidenav,
    enableSidebarBackgroundImage,
    sidebarBackgroundImages,
    selectedSidebarImage,
    navCollapsed,
    user,
    menuType,
  } = useSelector((store) => store.themes.themeSettings);

  const menuTypeHanlder = (isTrue) => {
    if (isTrue) {
      document.body.classList.add("menu-type");
    } else {
      document.body.classList.remove("menu-type");
    }
    dispatch(setMenuType(isTrue));
    dispatch(setLoader(true));
    setTimeout(function () {
      dispatch(setLoader(false));
      document.body.classList.remove("mini-sidebar");
      dispatch(collapsedSidebarAction(isTrue));
    }, 350);
  };

  const darkModeHanlder = (isTrue) => {
    if (isTrue) {
      //Set Primary Theme For Dark Mode
      for (const appTheme of themes) {
        if (document.body.classList.contains(`theme-${appTheme.name}`)) {
          document.body.classList.remove(`theme-${appTheme.name}`);
        }
      }
      document.body.classList.add(`theme-primary`);
      setTimeout(() => {
        document.body.classList.add("dark-mode");
      }, 500);
    } else {
      document.body.classList.remove("dark-mode");
    }
    dispatch(darkModeAction(isTrue));
  };

  const darkSidebarHanlder = (isTrue) => {
    if (isTrue) {
      document.body.classList.add("dark-sidebar");
    } else {
      document.body.classList.remove("dark-sidebar");
    }
    dispatch(setDarkSideNav(isTrue));
  };

  const rtlLayoutHanlder = (isTrue) => {
    var root = document.getElementsByTagName("html")[0];
    if (isTrue) {
      root.setAttribute("dir", "rtl");
      document.body.classList.add("rtl");
    } else {
      root.setAttribute("dir", "ltr");
      document.body.classList.remove("rtl");
    }
    dispatch(rtlLayoutAction(isTrue));
  };

  const miniSidebarHanlder = (isTrue) => {
    if (isTrue) {
      document.body.classList.add("mini-sidebar");
    } else {
      document.body.classList.remove("mini-sidebar");
    }
    setTimeout(() => {
      dispatch(miniSidebarAction(isTrue));
    }, 100);
  };

  const setSidebarBgImage = (sidebarImage, e) => {
    e.preventDefault();
    dispatch(setSidebarBgImageAction(sidebarImage));
  };

  const toggleThemePanel = () => {
    setThemeOptionPanelOpen(!themeOptionPanelOpen);
  };

  const setThemeColor = (theme) => {
    for (const appTheme of themes) {
      if (document.body.classList.contains(`theme-${appTheme.name}`)) {
        document.body.classList.remove(`theme-${appTheme.name}`);
      }
    }
    document.body.classList.add(`theme-${theme.name}`);
    darkModeHanlder(false);
    dispatch(changeThemeColor(theme));
  };

  useEffect(() => {
    if (!document.body.classList.contains(`theme-${activeTheme.name}`)) {
      document.body.classList.add(`theme-${activeTheme.name}`);
    }
  }, [activeTheme]);

  useEffect(() => {
    if (darkMode) darkModeHanlder(true);
  }, [darkMode]);

  useEffect(() => {
    if (rtlLayout) rtlLayoutHanlder(true);
  }, [rtlLayout]);

  useEffect(() => {
    if (miniSidebar) miniSidebarHanlder(true);
  }, [miniSidebar]);

  return (
    <div className="fixed-plugin">
      {AppConfig.enableThemeOptions && (
        <Dropdown
          isOpen={themeOptionPanelOpen}
          toggle={() => toggleThemePanel()}
        >
          <DropdownToggle className="bg-primary">
            <Tooltip
              title="Theme Options"
              placement="left"
              className="pointerCursor"
            >
              <i className="zmdi zmdi-settings fs-18 tour-step-6"></i>
            </Tooltip>
          </DropdownToggle>
          <DropdownMenu>
            <Scrollbars
              className="rct-scroll"
              autoHeight
              autoHeightMin={100}
              autoHeightMax={530}
              autoHide
              autoHideDuration={100}
            >
              <ul className="list-unstyled text-center mb-0">
                <li className="header-title mb-10">Theme Color</li>
                <li className="adjustments-line mb-10">
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <div>
                      {themes.map((theme, key) => (
                        <Tooltip
                          title={theme.name}
                          placement="top"
                          key={key}
                          className="pointerCursor"
                        >
                          <img
                            onClick={() => setThemeColor(theme)}
                            src={require(`assets/img/${theme.name}-theme.png`)}
                            alt="theme"
                            className={classnames("img-fluid mr-5", {
                              active: theme.id === activeTheme.id,
                            })}
                          />
                        </Tooltip>
                      ))}
                    </div>
                  </a>
                </li>
                {menuType == "vr" && (
                  <>
                    <li className="header-title sidebar-overlay">
                      Sidebar Overlay
                    </li>
                    <li className="sidebar-color">
                      Light
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Switch
                            checked={isDarkSidenav}
                            onClick={(e) =>
                              darkSidebarHanlder(e.target.checked)
                            }
                            className="switch-btn"

                            // checked={isDarkSidenav}
                            // onClick={() => dispatch(toggleDarkSidebar())}
                            // color="primary"
                            // className="switch-btn"
                          />
                        }
                        label=""
                      />
                      Dark
                    </li>
                  </>
                )}
                {menuType == "vr" && (
                  <li className="header-title sidebar-img-check">
                    <FormControlLabel
                      className="m-0"
                      control={
                        <Switch
                          checked={enableSidebarBackgroundImage}
                          onClick={() => dispatch(toggleSidebarImage())}
                          color="primary"
                          className="switch-btn"
                        />
                      }
                      label="Sidebar Image"
                    />
                  </li>
                )}
                {enableSidebarBackgroundImage && (
                  <li className="background-img">
                    {sidebarBackgroundImages.map((sidebarImage, key) => (
                      <a
                        className={classnames("img-holder", {
                          active: selectedSidebarImage === sidebarImage,
                        })}
                        href="#"
                        key={key}
                        onClick={(e) => setSidebarBgImage(sidebarImage, e)}
                      >
                        <img
                          src={require(`assets/img/${sidebarImage}`)}
                          alt="sidebar"
                          className="img-fluid"
                          width=""
                          height=""
                        />
                      </a>
                    ))}
                  </li>
                )}
              </ul>
              <ul className="list-unstyled mb-0 p-10 app-settings">
                <li className="header-title mb-10">App Settings</li>

                <li className="header-title mini-sidebar-option">
                  <FormControlLabel
                    control={
                      <Switch
                        // disabled={!navCollapsed}
                        checked={miniSidebar}
                        onChange={(e) => miniSidebarHanlder(e.target.checked)}
                        className="switch-btn"
                      />
                    }
                    label="Mini Sidebar"
                    className="m-0"
                  />
                </li>

                <li className="header-title">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rtlLayout}
                        onChange={(e) => rtlLayoutHanlder(e.target.checked)}
                        className="switch-btn"
                      />
                    }
                    label="RTL Layout"
                    className="m-0"
                  />
                </li>
                {/* <li className="header-title">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={darkMode}
                        onChange={(e) => darkModeHanlder(e.target.checked)}
                        className="switch-btn"
                      />
                    }
                    label="Dark Mode"
                    className="m-0"
                  />
                </li> */}
                <li className="header-title">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={menuType == "hr" ? true : false}
                        onChange={(e) => menuTypeHanlder(e.target.checked)}
                        className="switch-btn"
                      />
                    }
                    label="Horizontal Menu"
                    className="m-0"
                  />
                </li>
              </ul>
            </Scrollbars>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

export default ThemeOptions;
