/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { populateData, populateOptions } from "components/Charts/Pivot";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  return <Bar data={populateData(props)} options={populateOptions(props)} />;
};

export default BarChart;
