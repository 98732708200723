/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const DeleteConfirmationDialog = ({
  title,
  message,
  info,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={true}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div dangerouslySetInnerHTML={{ __html: info }} />
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} className="btn-danger text-white">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className="btn-primary text-white"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
