/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

export * from "./actions";
export { default as settings } from "./reducers";

export const SET_MODULES_LIST_DATA = "SET_MODULES_LIST_DATA";
export const SET_MENU_LIST_DATA = "SET_MENU_LIST_DATA";
export const SET_MENU_LIST_DUMMY = "SET_MENU_LIST_DUMMY";

export const ACL_LIST = "ACL_LIST";

export const TEMPLATE_LIST = "TEMPLATE_LIST";
export const EMAIL_TEMPLATE_LIST = "EMAIL_TEMPLATE_LIST";

export const USER_UI_SETTINGS = "USER_UI_SETTINGS";

export const COMPANY_SETTINGS = "COMPANY_SETTINGS";
