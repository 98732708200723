/**
 * Form Dialog
 */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TextTranslation from "components/TextTranslation/TextTranslation";

import { setLoader } from "modules/themes/stores/themeSettings";

const Anotation = (props) => {
  //console.log(props);
  const dispatch = useDispatch();

  const handleOnCloseModal = () => {
    props.setShowAnotation(false);
  };

  return (
    <Modal isOpen={props.showAnotation} toggle={handleOnCloseModal}>
      <ModalHeader toggle={handleOnCloseModal}>
        <TextTranslation
          text={"administration:manageAnotation.gridLabels.anotations"}
        />
      </ModalHeader>
      <ModalBody>Markup Data</ModalBody>
    </Modal>
  );
};
export default Anotation;
