/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import axios from "axios";
import { AppTrlns, AppConstants } from "config";
import { pURL } from "utils/pivot";

//----------------------- User Authentication Section ------------------------
export const __signIn = async (user) => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePathCRM, {
        page: "resources/signIn.php",
        username: user.username,
        password: user.password,
      })
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.length > 0 &&
          response.data[0].US_Id
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
        sessionStorage.removeItem("sessionFail");
      });
  });
};

//----------------------- User Authentication With Google Section ------------------------
export const __signInWithGoogle = async (email, google_key) => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePathCRM, {
        page: "resources/checkAuthentication.php",
        username: email,
        google_key: google_key,
      })
      .then(function (response) {
        // console.log(response);
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
        sessionStorage.removeItem("sessionFail");
      });
  });
};

//----------------------- Remove User Authentication ------------------------
export const __logout = async (users) => {
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};

//----------------------- Init SignUp Data ------------------------
export const __initSignUp = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePathCRM, {
        page: "resources/getServices.php",
      })
      .then(function (response) {
        // console.log(response);
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
        sessionStorage.removeItem("sessionFail");
      });
  });
};

//---------------------------- User SignUp Section ---------------------------
export const __signUpNewUser = async (formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(AppConstants.nodePathCRM, {
        page: "resources/newCompanyReg.php",
        formData: JSON.stringify(formData),
      })
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
        sessionStorage.removeItem("sessionFail");
      });
  });
};

//---------------------------- User SignUp Section ---------------------------
export const __initMyProfileDetails = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "resources/getMyProfile.php",
        })
      )
      .then(function (response) {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "no user found" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};
