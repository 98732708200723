/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

const scene = {
  public: {
    signIn: "/resources/signIn",
    signUp: "/resources/signUp",
    forgotPassword: "/resources/forgotPassword",
    termsofservice: "/resources/termsofservice",
  },
  protected: {
    // menu: "/themes/menu",
    moduleThemes: "/themes/*",
    moduleInvoices: "/invoices/*",
    moduleResources: "/resources/*",
    moduleSettings: "/settings/*",
  },
};

const APIKey = {
  googleKey: "AIzaSyDTyAdxEKLcsRWypeFaa6hrIc4Q_0pN4X4",
  aesKey: "finobase crypto aes",
};

const defaultValues = {
  defaultMenuIcon: ["zmdi-code", "Code"],
};

let nodePath = null;
let nodePathCRM = null;
let uploadPath = null;
let fsPath = null;
let fileBrowser = null;

// console.log("process.env", process.env);

if (process.env.REACT_APP_ENV === "development") {
  nodePath = "http://localhost:9000/";
  nodePathCRM = "https://crm.simpleplans.com.au/portal/";
  fsPath = "http://localhost:9000/fs/";
  uploadPath = "uploads";
} else if (process.env.REACT_APP_ENV === "staging") {
  nodePath = "https://server-portal.simpleplans.com.au/";
  nodePathCRM = "https://crm.simpleplans.com.au/portal/";
  fsPath = "https://server-portal.simpleplans.com.au/fs/";
  uploadPath = "uploads";
} else if (process.env.REACT_APP_ENV === "production") {
  nodePath = "https://server-portal.simpleplans.com.au/";
  nodePathCRM = "https://crm.simpleplans.com.au/portal/";
  fsPath = "https://server-portal.simpleplans.com.au/fs/";
  uploadPath = "uploads";
}

const dataTableDefaultOptions = {
  download: false,
  rowHover: true,
  filterType: "dropdown",
  selectableRows: "none",
  setRowProps: () => {
    return {
      style: { borderBottom: "1px solid #CCC" },
    };
  },
  setTableProps: () => {
    return {
      style: { border: "1px solid #dee2e6" },
    };
  },
};

const AppConstants = {
  scene,
  APIKey,
  defaultValues,
  dataTableDefaultOptions,
  nodePath,
  nodePathCRM,
  uploadPath,
  fsPath,
  fileBrowser,
};

export default AppConstants;
