/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import {
  EDITABLE_GRID_DATA,
  NDIS_PARTICIPANT_DETAILS,
  INVOICE_COMPANY_DETAILS,
  INVOICE_ACTIVE_ITEMS,
  INVOICE_PLAN_MANAGER,
  INVOICE_TAXES,
  INVOICE_UNITS,
  INVOICE_CLAIM_TYPES,
  INVOICE_TYPES,
  INVOICE_PRINT_DATA,
  INVOICE_LIST,
  ACTIVE_INVOICE_LIST,
  INVOICE_EMAIL_RECIPIENTS,
  INVOICE_EMAIL_CONTENT,
  INVOICE_EMAIL_TEMPLATE,
  INVOICE_FORM_DATA,
  INVOICE_DASHBOARD_DATA,
  INVOICE_EMAIL_HISTORY,
} from ".";
import {
  __downloadRFQ,
  __initInvoiceForm,
  __updateTransactionData,
  __initListInvoices,
  __generatePrintForTransaction,
  __generatePdfForTransaction,
  __updatePDFNameInSP,
  __initEmailInvoice,
  __initEditInvoiceForm,
  __updateCompany,
  __initInvoiceDashboard,
  __exportInvoicesToCSV,
  __sendEmailHistory,
  __getEmailHistory,
  __deleteUserInvoices,
} from "./modules";

import { batch } from "react-redux";

export const getEditableGridData = (data) => (dispatch, getState) => {
  dispatch({
    type: EDITABLE_GRID_DATA,
    payload: data,
  });
};

export const downloadRFQ = () => (dispatch, getState) => {
  return __downloadRFQ().then((response) => {
    return response;
  });
};

/* INIT Invoice Form Data*/
export const initInvoiceForm = (selectedCompany) => (dispatch) => {
  return __initInvoiceForm(selectedCompany).then((response) => {
    const [
      invoiceNDISParticipant,
      invoiceCompanyDetails,
      invoiceActiveItems,
      invoicePlanManager,
      invoiceTaxes,
      invoiceUnits,
      invoiceClaimTypes,
      invoiceTypes,
    ] = response;

    batch(() => {
      dispatch({
        type: NDIS_PARTICIPANT_DETAILS,
        payload: invoiceNDISParticipant?.data || [],
      });
      dispatch({
        type: INVOICE_COMPANY_DETAILS,
        payload: invoiceCompanyDetails?.data || [],
      });
      dispatch({
        type: INVOICE_ACTIVE_ITEMS,
        payload: invoiceActiveItems?.data || [],
      });
      dispatch({
        type: INVOICE_PLAN_MANAGER,
        payload: invoicePlanManager?.data || [],
      });
      dispatch({
        type: INVOICE_TAXES,
        payload: invoiceTaxes?.data || [],
      });
      dispatch({
        type: INVOICE_UNITS,
        payload: invoiceUnits?.data || [],
      });
      dispatch({
        type: INVOICE_CLAIM_TYPES,
        payload: invoiceClaimTypes?.data || [],
      });
      dispatch({
        type: INVOICE_TYPES,
        payload: invoiceTypes?.data || [],
      });
    });
  });
};

/* INIT Invoice Edit Form Data*/
export const initEditInvoiceForm =
  (selectedTransactionId, selectedTransactionCompId) => (dispatch) => {
    return __initEditInvoiceForm(
      selectedTransactionId,
      selectedTransactionCompId
    ).then((response) => {
      const [invoiceFormDetails] = response;

      batch(() => {
        dispatch({
          type: INVOICE_FORM_DATA,
          payload: invoiceFormDetails?.data || [],
        });
      });
    });
  };

/*Update Invoice Data*/
export const updateTransactionData =
  (formData, transData) => (dispatch, getState) => {
    return __updateTransactionData(formData, transData).then((response) => {
      return response;
    });
  };

/*Update Company*/
export const updateCompany = (companyId) => (dispatch, getState) => {
  return __updateCompany(companyId).then((response) => {
    return response;
  });
};

/*Init Invoice Listing*/
export const initListInvoices =
  (
    listPage,
    rowsPerPage,
    searchText,
    filterList,
    sortOrder,
    cmp_Id,
    fromDate,
    toDate
  ) =>
  (dispatch) => {
    return __initListInvoices(
      listPage,
      rowsPerPage,
      searchText,
      filterList,
      sortOrder,
      cmp_Id,
      fromDate,
      toDate
    ).then((response) => {
      const [
        invoiceList,
        activeInvoiceList,
        invoiceNDISParticipant,
        invoicePlanManager,
        invoiceCompanyDetails,
      ] = response;

      batch(() => {
        dispatch({
          type: INVOICE_LIST,
          payload: invoiceList?.data?.invDetails || [],
        });
        dispatch({
          type: ACTIVE_INVOICE_LIST,
          payload: activeInvoiceList?.data || [],
        });
        dispatch({
          type: NDIS_PARTICIPANT_DETAILS,
          payload: invoiceNDISParticipant?.data || [],
        });
        dispatch({
          type: INVOICE_PLAN_MANAGER,
          payload: invoicePlanManager?.data || [],
        });
        dispatch({
          type: INVOICE_COMPANY_DETAILS,
          payload: invoiceCompanyDetails?.data || [],
        });
      });
      return invoiceList?.data?.totalRows ? invoiceList?.data?.totalRows : 0;
    });
  };

//-------------------generate print for transaction----------------
export const generatePrintForTransaction =
  (transId, selectedCompanyId) => (dispatch) => {
    return __generatePrintForTransaction(transId, selectedCompanyId).then(
      (response) => {
        dispatch({
          type: INVOICE_PRINT_DATA,
          payload: response?.data || [],
        });
        return JSON.stringify(response?.data) || [];
      }
    );
  };

//-------------------generate PDF for transaction----------------
export const generatePdfForTransaction = (pdfDetails) => (dispatch) => {
  return __generatePdfForTransaction(pdfDetails).then((response) => {
    return response?.data;
  });
};

//-------------------update PDF name in simple plans db----------------
export const updatePDFNameInSP = (InvID, fileName) => (dispatch) => {
  return __updatePDFNameInSP(InvID, fileName).then((response) => {
    return response?.data;
  });
};

/* INIT Invoice Email Datas*/
export const initEmailInvoice =
  (selectedInvId, selectedCompanyId) => (dispatch) => {
    return __initEmailInvoice(selectedInvId, selectedCompanyId).then(
      (response) => {
        const [
          invoiceEmailRecipients,
          invoiceEmailContent,
          invoiceEmailTemplate,
        ] = response;

        const INV_ToMails = [];
        const INV_CcMails = [];
        const INV_BccMails = [];

        let toMailsIndex = 0;
        // For INV_ToMails
        invoiceEmailRecipients?.data["INV_ToMails"].forEach((val, ind) => {
          if (val !== "" && val.includes("@")) {
            INV_ToMails.push({
              RCP_Id: ind,
              RCP_Name: val,
              selected: "selected",
            });
            toMailsIndex++;
          }
        });

        let ccMailsIndex = 0;
        // For INV_CcMails
        invoiceEmailRecipients?.data["INV_CcMails"].forEach((val, ind) => {
          if (val !== "" && val.includes("@")) {
            INV_CcMails.push({
              CCP_Id: ind,
              CCP_Name: val,
              selected: "selected",
            });
            ccMailsIndex++;
          }
        });

        let bccMailsIndex = 0;
        // For INV_BccMails
        invoiceEmailRecipients?.data["INV_BccMails"].forEach((val, ind) => {
          if (val !== "" && val.includes("@")) {
            INV_BccMails.push({
              BCCP_Id: ind,
              BCCP_Name: val,
              selected: "selected",
            });
            bccMailsIndex++;
          }
        });

        // Combine all three arrays
        const combinedData = {
          INV_ToMails: [
            ...INV_ToMails,
            ...invoiceEmailRecipients?.data["OTHR_Mails"].map(
              (item, index) => ({
                RCP_Id: index + toMailsIndex,
                RCP_Name: item.CTM_Mail,
                selected: "",
              })
            ),
          ],
          INV_CcMails: [
            ...INV_CcMails,
            ...invoiceEmailRecipients?.data["OTHR_Mails"].map(
              (item, index) => ({
                CCP_Id: index + ccMailsIndex,
                CCP_Name: item.CTM_Mail,
                selected: "",
              })
            ),
          ],
          INV_BccMails: [
            ...INV_BccMails,
            ...invoiceEmailRecipients?.data["OTHR_Mails"].map(
              (item, index) => ({
                BCCP_Id: index + bccMailsIndex,
                BCCP_Name: item.CTM_Mail,
                selected: "",
              })
            ),
          ],
        };

        batch(() => {
          dispatch({
            type: INVOICE_EMAIL_RECIPIENTS,
            payload: combinedData || [],
          });
          dispatch({
            type: INVOICE_EMAIL_CONTENT,
            payload: invoiceEmailContent?.data || [],
          });
          dispatch({
            type: INVOICE_EMAIL_TEMPLATE,
            payload: invoiceEmailTemplate?.data || [],
          });

          return invoiceEmailContent?.data?.PdfFile;
        });
      }
    );
  };

/* Initialize Invoice Dashboard */
export const initInvoiceDashboard = () => (dispatch, getState) => {
  return __initInvoiceDashboard().then((response) => {
    dispatch({
      type: INVOICE_DASHBOARD_DATA,
      payload: response || [],
    });
    return response;
  });
};

/* Export Invoices To CSV */
export const exportInvoicesToCSV =
  (invoices, csvFileName) => (dispatch, getState) => {
    return __exportInvoicesToCSV(invoices, csvFileName).then((response) => {
      return response;
    });
  };

/* Send Email History */
export const sendEmailHistory =
  (emailParameters, invoiceId, companyId) => (dispatch, getState) => {
    return __sendEmailHistory(emailParameters, invoiceId, companyId).then(
      (response) => {
        return response;
      }
    );
  };

/* Get Email History */
export const getEmailHistory = (invoiceId) => (dispatch, getState) => {
  return __getEmailHistory(invoiceId).then((response) => {
    dispatch({
      type: INVOICE_EMAIL_HISTORY,
      payload: response || [],
    });
    return response;
  });
};

/* Delete Invoices */
export const deleteUserInvoices = (deleteInvoices) => (dispatch, getState) => {
  return __deleteUserInvoices(deleteInvoices).then((response) => {
    return response;
  });
};
