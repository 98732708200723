/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { IconButton, Drawer, AppBar, Toolbar, Tooltip } from "@mui/material";
import screenfull from "screenfull";
import { Home, Menu } from "@mui/icons-material";
import { Badge } from "reactstrap";

// Redux Modules
import {
  collapsedSidebarAction,
  setUserTour,
} from "modules/themes/stores/themeSettings";

// Components
import Notifications from "./Notifications";
import LanguageSelector from "./LanguageSelector";
import SearchForm from "./SearchForm";
//import MobileSearchForm from './MobileSearchForm';

import UserBlock from "components/HorizontalMenu/UserBlock";
import QuickLinks from "./QuickLinks";

const DefaultAppLayout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [customizer, setCustomizer] = useState(false);

  const { navCollapsed, menuType } = useSelector(
    (store) => store.themes.themeSettings
  );

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  // Function to change the state of collapsed sidebar
  const onToggleNavCollapsed = (event) => {
    dispatch(collapsedSidebarAction(!navCollapsed));
  };

  // Toggle screen full
  const toggleScreenFull = () => {
    screenfull.toggle();
  };

  // Start tour
  const startTour = () => {
    dispatch(setUserTour(true));
  };

  return (
    <AppBar position="static" className="rct-header">
      <Toolbar className="d-flex justify-content-between w-100 pl-0">
        <div className="d-inline-flex align-items-center">
          {menuType == "hr" ? (
            <div className="site-logo">
              <a href="/themes/applications" className="logo-mini">
                <img
                  src={require("assets/img/appLogo.png")}
                  className="mr-15"
                  alt="site logo"
                  width="35"
                  height="35"
                />
              </a>
              <a href="/themes/applications" className="logo-normal">
                <img
                  src={require("assets/img/appLogoText.png")}
                  className="img-fluid"
                  alt="site-logo"
                  width="102"
                />
              </a>
              <div title="" className="companySection">
                {appConstants["CMP_Name"]}
              </div>
            </div>
          ) : null}

          <ul className="list-inline mb-0 navbar-left">
            <li
              className="list-inline-item"
              onClick={(e) => onToggleNavCollapsed(e)}
            >
              <Tooltip
                title="Sidebar Toggle"
                placement="bottom"
                className="pointerCursor"
              >
                <IconButton
                  color="inherit"
                  mini="true"
                  aria-label="Menu"
                  className="humburger p-0"
                >
                  <Menu />
                </IconButton>
              </Tooltip>
            </li>
            <li className="list-inline-item">
              {/* onClick={(e) => navigateToHome(e)} */}
              <a href="/themes/applications">
                <Tooltip
                  title="Home"
                  placement="bottom"
                  className="pointerCursor"
                >
                  <IconButton
                    color="inherit"
                    mini="true"
                    aria-label="Home"
                    className="humburger p-0"
                  >
                    <Home />
                  </IconButton>
                </Tooltip>
              </a>
            </li>
            <li className="list-inline-item search-icon d-inline-block">
              <SearchForm />
            </li>
          </ul>
        </div>
        <ul className="navbar-right list-inline mb-0">
          <LanguageSelector />
          {/* <Notifications />
          <QuickLinks /> */}
          <li className="list-inline-item">
            <Tooltip
              title="Start Tour"
              placement="bottom"
              className="pointerCursor"
            >
              <IconButton aria-label="settings" onClick={() => startTour()}>
                <i className="zmdi zmdi-airplay"></i>
              </IconButton>
            </Tooltip>
          </li>
          <li className="list-inline-item">
            <Tooltip
              title="Full Screen"
              placement="bottom"
              className="pointerCursor"
            >
              <IconButton
                aria-label="settings"
                onClick={() => toggleScreenFull()}
              >
                <i className="zmdi zmdi-crop-free"></i>
              </IconButton>
            </Tooltip>
          </li>

          {menuType == "hr" ? (
            <li className="list-inline-item horizontal-menu">
              <IconButton aria-label="settings">
                <UserBlock />
              </IconButton>
            </li>
          ) : null}
        </ul>
      </Toolbar>
    </AppBar>
  );
};
export default DefaultAppLayout;
