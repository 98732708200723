/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment, useEffect } from "react";

import InvoicesNavigation from "modules/invoices/navigation";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { popuateMenu } from "modules/themes/stores/generalSettings";

import "modules/invoices/assets/css/custom.scss";

import "./stores";
import "./translations";

const Invoices = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let moduleName = pathname.split("/")[1];

  useEffect(() => {
    dispatch(popuateMenu(moduleName))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then(() => {})
      .finally(() => {});
  }, []);

  return (
    <Fragment>
      <InvoicesNavigation />
    </Fragment>
  );
};
export default Invoices;
