/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Tooltip from "@mui/material/Tooltip";

import { useTranslation } from "react-i18next";

// Redux Modules
import {
  setLanguage,
  rtlLayoutAction,
} from "modules/themes/stores/themeSettings";

const LanguageProvider = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [langDropdownOpen, setLangDropdownOpen] = useState(false);

  const { locale, languages } = useSelector(
    (store) => store.themes.themeSettings
  );

  // Function to toggle dropdown menu
  const toggle = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  // On change language
  const onChangeLanguage = (lang) => {
    setLangDropdownOpen(false);
    dispatch(setLanguage(lang.locale, lang.mode));
    i18n.changeLanguage(lang.locale);
    if (lang.mode === "rtl") {
      rtlLayoutHanlder(true);
    } else {
      rtlLayoutHanlder(false);
    }
  };

  const rtlLayoutHanlder = (isTrue) => {
    var root = document.getElementsByTagName("html")[0];
    if (isTrue) {
      root.setAttribute("dir", "rtl");
      document.body.classList.add("rtl");
    } else {
      root.setAttribute("dir", "ltr");
      document.body.classList.remove("rtl");
    }
    dispatch(rtlLayoutAction(isTrue));
  };

  return (
    <Dropdown
      nav
      className="list-inline-item language-dropdown tour-step-5"
      isOpen={langDropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret nav className="header-icon language-icon">
        <Tooltip title="Languages" placement="bottom" className="pointerCursor">
          <img
            src={require(`assets/flag-icons/${locale}.png`)}
            className="mr-10"
            width="25"
            height="16"
            alt="lang-icon"
          />
        </Tooltip>
      </DropdownToggle>
      <DropdownMenu>
        <div className="dropdown-content">
          <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
            <span className="text-white font-weight-bold">Languages</span>
            {/* <Badge color="warning">3 NEW</Badge> */}
          </div>
          <Scrollbars
            className="rct-scroll"
            autoHeight
            autoHeightMin={100}
            autoHeightMax={280}
          >
            <ul className="list-unstyled mb-0 dropdown-list">
              {languages.map((language, key) => (
                <li key={key} onClick={() => onChangeLanguage(language)}>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <img
                      src={require(`assets/flag-icons/${language.icon}.png`)}
                      className="mr-10"
                      width="25"
                      height="16"
                      alt="lang-icon"
                    />
                    {language.name}
                  </a>
                </li>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageProvider;
