/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import styles from "./styles.module.css";

export default function FieldError(props) {
  return <div className={styles.errorView}>{props.errorText}</div>;
}
