import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppConstants } from "modules/themes/config";

import Dashboard from "modules/themes/routes/";
import Applications from "modules/themes/routes/Applications";
import Notifications from "modules/themes/routes/Notifications";
import NotFound from "modules/themes/routes/NotFound";

const ThemesNavigation = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path={AppConstants.scene.dashboard} element={<Dashboard />} />
      <Route
        path={AppConstants.scene.applications}
        element={<Applications />}
      />
      <Route
        path={AppConstants.scene.notifications}
        element={<Notifications />}
      />

      <Route index element={<Navigate to={`${pathname}/modules`} replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default ThemesNavigation;
