/**
 * Nav Menu Item
 */
import React, { Fragment, Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// Translation Modules
import { AppTrlns } from "config";

import { setCurrentPageACL } from "modules/themes/stores/themeSettings";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const NavMenuItem = ({ menu }) => {
  const dispatch = useDispatch();

  const triggerMenu = (menuACL) => {
    dispatch(setCurrentPageACL(menuACL));
  };

  return (
    <li className={menu.child_routes ? "hmenu-dropdown" : "hmenu-no-dropdown"}>
      {menu.child_routes !== null ? (
        <Fragment>
          <a href="#" onClick={(e) => e.preventDefault()} className="nav-link">
            <i className={menu.menu_icon}></i>
            {AppTrlns(menu.menu_title)}
          </a>
          <ul className="hmenu-drop-nav hmenu-drop-nav-left">
            {menu.child_routes.map((subMenu, subKey) => {
              if (!subMenu.child_routes) {
                return (
                  <li key={subKey}>
                    <NavLink
                      to={subMenu.path.trim()}
                      onClick={() => triggerMenu(subMenu.menu_acl)}
                    >
                      {AppTrlns(subMenu.menu_title)}
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </Fragment>
      ) : (
        <NavLink to={menu.path} onClick={() => triggerMenu(menu.menu_acl)}>
          <BootstrapTooltip
            title={
              menu.menu_title.split(/[.]+/).pop() == "dashboard"
                ? "Dashboard"
                : ""
            }
            placement="right"
            enterDelay={
              menu.menu_title.split(/[.]+/).pop() == "dashboard" ? 0 : 500000
            }
          >
            <i
              className={`${menu.menu_icon} ${
                menu.menu_title.split(/[.]+/).pop() == "dashboard"
                  ? "dashboard-icon"
                  : null
              }`}
            ></i>
          </BootstrapTooltip>

          {menu.menu_title.split(/[.]+/).pop() == "dashboard"
            ? null
            : AppTrlns(menu.menu_title)}
        </NavLink>
      )}
    </li>
  );
};

export default NavMenuItem;
