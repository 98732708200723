/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";

import { AppTrlns, ChartConfig } from "config";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const AboutUs = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("settings:AboutUS.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:AboutUS.sceneTitle")}
        location={location}
      />
      <div className="row">About Us</div>
    </div>
  );
};

export default AboutUs;
