/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";

import { Tooltip } from "@mui/material";
import { Done, Close } from "@mui/icons-material";

import { AppTrlns, ChartConfig } from "config";
import Datatable from "components/DataTable";

const data = [
  ["Joe James", "Test Corp", "Yonkers", "NY", 1],
  ["John Walsh", "Test Corp", "Hartford", "CT", 1],
  ["Bob Herm", "Test Corp", "Tampa", "FL", 2],
  ["James Houston", "Test Corp", "Dallas", "TX", 1],
];

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [count, setCount] = useState(100);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const renderTableData = (notifData) => {
    let notifOBJ = [];

    notifData &&
      notifData.map((value, i) => {
        notifOBJ.push([
          <div>
            <img
              //src={require("../assets/img/user.png")}
              src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
              alt="user prof"
              className="rounded-circle mr-15"
              width="50"
              height="50"
            />
          </div>,
          value[1],
          value[2],
          value[4] == 1 ? (
            <Tooltip title="Active" className="pointerCursor">
              <Done color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Blocked" className="pointerCursor">
              <Close color="error" />
            </Tooltip>
          ),
        ]);
      });

    return notifOBJ;
  };

  const handleFilter = (
    page,
    rowsPerPage,
    searchText,
    filterList,
    sortOrder
  ) => {
    setPage(page);
    setRowsPerPage(rowsPerPage);
  };

  const customToolbarSelect = (selectedRows) => {};

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>Notifications</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("notifications.sceneTitle")}
        location={location}
      />

      <div className="row">
        <Datatable
          data={renderTableData(data)}
          handleFilter={handleFilter}
          title={AppTrlns("notifications.gridLabels.gridTitle")}
          selectableRows="multiple"
          selectableRowsHeader={true}
          customToolbarSelect={customToolbarSelect}
          totalCount={count}
          column={[
            {
              name: AppTrlns("accounts:DocumentType.gridLabels.DT_Name"),
              style: {
                minWidth: "40%",
                textAlign: "left",
              },
              sort: true,
            },
            {
              name: AppTrlns("accounts:DocumentType.gridLabels.modified"),
              style: {
                minWidth: "30%",
                textAlign: "left",
              },
              sort: true,
            },
            {
              name: AppTrlns("accounts:DocumentType.gridLabels.modifiedDate"),
              style: {
                minWidth: "10%",
                textAlign: "left",
              },
              sort: true,
            },
            {
              name: AppTrlns("accounts:DocumentType.gridLabels.status"),
              style: {
                textAlign: "center",
              },
              sort: true,
              filter: ["Active", "Blocked"],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Dashboard;
