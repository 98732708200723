/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { createTheme } from "@mui/material/styles";
import { AppConfig } from "config";

const theme = createTheme({
  palette: {
    primary: {
      main: AppConfig.themeColors.primary,
    },
    secondary: {
      main: AppConfig.themeColors.warning,
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "Helvetica", "Arial", "Heebo", "sans-serif"].join(
      ","
    ),
    htmlFontSize: 16,
    h2: {
      fontSize: 21,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
  },
});

export default theme;
