/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState } from "react";
import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  LinearProgress,
} from "@mui/material";

import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

// app config
import { AppConfig } from "config";

import FieldError from "components/FieldError";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (form) => {
    //   dispatch(login())
    //     .catch((err) => {
    //       alert(err.msg); // eslint-disable-line no-alert
    //     })
    //     .then(() => {
    //       navigate("/");
    //     })
    //     .finally(() => {});
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src={AppConfig.appLogo}
                      alt="session-logo"
                      className="img-fluid"
                      width="175"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-7 col-md-7 col-lg-8 mx-auto">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <h2 className="font-weight-bold">
                      Get started with {AppConfig.brandName}
                    </h2>
                    {/* <p className="mb-0">Most powerful ReactJS admin panel</p> */}
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="mb-3">
                      <TextField
                        error={errors?.username ? true : false}
                        label="Enter Your Registered Email Address"
                        variant="outlined"
                        // focused
                        size="small"
                        fullWidth
                        {...register("username", {
                          required: true,
                          pattern: /\S+@\S+\.\S+/,
                        })}
                      />
                      {errors?.username?.type === "required" && (
                        <FieldError errorText="This Field Is Required" />
                      )}
                      {errors?.username?.type === "pattern" && (
                        <FieldError errorText="Invalid Email" />
                      )}
                    </div>
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                    <div className="mt-4 mb-4 text-center">
                      <a
                        className="text-muted"
                        onClick={() => navigate("/resources/signin")}
                      >
                        <i className="mdi mdi-lock me-1" /> Already Having
                        Account? Login
                      </a>
                    </div>
                  </form>
                  <p className="text-muted">
                    By signing up you agree to {AppConfig.brandName}
                  </p>
                  <p className="mb-0">
                    <a
                      onClick={() => navigate("/resources/termsofservice")}
                      className="text-muted"
                    >
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};
export default ForgotPassword;
