/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import TRANSLATIONS_EN from "./en.json";

i18next
  .use(initReactI18next)
  .addResourceBundle("en", "resources", TRANSLATIONS_EN);

export default i18next;
