/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import axios from "axios";
import { AppTrlns, AppConstants } from "config";
import { pURL } from "utils/pivot";

//----------------------- Set Application Language ------------------------
export const __setLanguage = (language) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setLanguage.php",
          locale: language,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Sidebar Image ------------------------
export const __setSidebarBgImageAction = (sidebarImage) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setSidebarImage.php",
          sidebarImage: sidebarImage,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Dark Mode ------------------------
export const __darkModeAction = (isDarkMode) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setDarkMode.php",
          isDarkMode: isDarkMode,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Dark Sidebar ------------------------
export const __setDarkSideNav = (isDarkSideNav) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setDarkSideNav.php",
          isDarkSideNav: isDarkSideNav,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set RTL ------------------------
export const __rtlLayoutAction = (isRtlLayout) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setRTL.php",
          isRtlLayout: isRtlLayout,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Mini Sidebar ------------------------
export const __miniSidebarAction = (isMiniSidebar) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setMiniSidebar.php",
          isMiniSidebar: isMiniSidebar,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Theme ------------------------
export const __changeThemeColor = (theme) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setThemeColor.php",
          theme: theme,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};

//----------------------- Set Menu Type ------------------------
export const __setMenuType = (isHrMenu) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConstants.nodePathCRM,
        pURL({
          page: "themes/setMenuType.php",
          isHrMenu: isHrMenu,
        })
      )
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.affectedRows > 0
        ) {
          resolve(response.data);
        } else {
          reject({ msg_stat: "0", msg: "UnExpected Error. Please ReTry" });
        }
      })
      .catch(function (error) {
        reject({ msg: AppTrlns("General.somethingWentWrong") });
      });
  });
};
