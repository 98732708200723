/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import moment from "moment";

import { APPS_LIST, SET_SEARCH_DATA, INIT_APP_CONSTANTS } from ".";

import {
  CHANGE_THEME_COLOR,
  DARK_MODE,
  RTL_LAYOUT,
  MINI_SIDEBAR,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  SET_MENU_TYPE,
  TOGGLE_DARK_SIDENAV,
  SET_SIDEBAR_MENU,
  SET_APPLICATION_ACL,
} from "modules/themes/stores/themeSettings";

import {
  __initAppConstants,
  __popuateMenu,
  __getSearchData,
  __getApps,
} from "./modules";

import { batch } from "react-redux";
import { decrypt, encrypt } from "utils/pivot";

//----------- Initialize All Constants In Reducer ------------
// All Settings Including Theme, User Details, Company Details, etc should be loaded from here.
export const initAppConstants = (usrID, cmpID) => (dispatch, getState) => {
  // console.log("initAppConstants", decrypt(usrID), decrypt(cmpID));
  return __initAppConstants(usrID, cmpID).then((response) => {
    batch(() => {
      dispatch({
        type: CHANGE_THEME_COLOR,
        //payload: { id: 1, name: "primary" },
        payload: response[0].THS_Theme,
      });
      dispatch({
        type: DARK_MODE,
        payload: response[0].THS_DarkMode == 1 ? true : false,
      });
      dispatch({
        type: RTL_LAYOUT,
        payload: response[0].THS_RTL == 1 ? true : false,
      });
      dispatch({
        type: MINI_SIDEBAR,
        payload: response[0].THS_MiniSidebar == 1 ? true : false,
      });
      dispatch({
        type: SET_SIDEBAR_IMAGE,
        payload: response[0].THS_SidebarImage,
      });
      dispatch({
        type: SET_LANGUAGE,
        payload: response[0].THS_Locale,
      });
      dispatch({
        type: SET_MENU_TYPE,
        payload: response[0].THS_MenuType,
      });
      dispatch({
        type: TOGGLE_DARK_SIDENAV,
        payload: response[0].THS_DarkMode == 1 ? true : false,
      });
      dispatch({
        type: INIT_APP_CONSTANTS,
        payload: response[0],
      });
    });
  });
};

//----------- Update App Constants In Reducer ------------
export const updateAppConstants =
  (updatedAppConstant) => (dispatch, getState) => {
    dispatch({
      type: INIT_APP_CONSTANTS,
      payload: updatedAppConstant,
    });
  };

//----------------------- Populate Menu ------------------------
export const popuateMenu = (moduleName) => (dispatch, getState) => {
  return __popuateMenu(moduleName, getState().users).then((response) => {
    let aclDataOBJ = {};
    let aclType = ["NA", "AC", "VO", "VM"];

    response.aclObj.map((aclOBJ, key) => {
      let ACLOBJ = JSON.parse(aclOBJ.SACL_Data);

      for (var key of Object.keys(ACLOBJ)) {
        if (key.includes("__")) {
          const [module, menu] = key.split("__");
          if (typeof aclDataOBJ[module] == "undefined") {
            aclDataOBJ[module] = [];
          }

          if (typeof aclDataOBJ[module][menu] == "undefined") {
            aclDataOBJ[module][menu] = ACLOBJ[key];
          } else {
            if (
              aclType.indexOf(ACLOBJ[key]) >
              aclType.indexOf(aclDataOBJ[module][menu])
            ) {
              aclDataOBJ[module][menu] = ACLOBJ[key];
            }
          }
        }
      }
    });

    dispatch({
      type: SET_SIDEBAR_MENU,
      payload: { menu: JSON.stringify(response.menuObj), module: moduleName },
    });
    dispatch({
      type: SET_APPLICATION_ACL,
      payload: aclDataOBJ,
    });
  });
};

//----------------------- GET Search Data ------------------------
export const getSearchData = (searchString) => (dispatch) => {
  return __getSearchData(searchString).then((response) => {
    dispatch({
      type: SET_SEARCH_DATA,
      payload: response,
    });
  });
};

//----------------------- GET List Of Modules ------------------------
export const getApps = () => (dispatch) => {
  return __getApps().then((response) => {
    dispatch({
      type: APPS_LIST,
      payload: response,
    });
  });
};
