/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  Fragment,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import FieldError from "components/FieldError";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

import {
  ImportExport,
  Delete,
  ArrowRight,
  Save,
  Email,
  Print,
  ArrowBack,
  OpenInFull,
  CloseFullscreen,
  InfoOutlined,
} from "@mui/icons-material";
import {
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Popper,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

import * as validationPattern from "utils/validationPattern";
import { formatDate } from "utils/pivot";
import {
  initInvoiceForm,
  initEditInvoiceForm,
  updateTransactionData,
  generatePrintForTransaction,
  generatePdfForTransaction,
  initEmailInvoice,
  updatePDFNameInSP,
  updateCompany,
  getEmailHistory,
} from "modules/invoices/stores/invoices";
import ComposeEmailModal from "modules/settings/routes/ComposeEmailModal";
import { setLoader } from "modules/themes/stores/themeSettings";
import BasicDatePicker from "components/DatePicker/DatePicker";

import { AppConstants as MainAppConstants } from "config";

import { saveAs } from "file-saver";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { encrypt, decrypt } from "utils/pivot";

let focussedItem = null;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: ".9rem",
    border: "1px solid #dadde9",
  },
}));

const ManageInvoice = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({});

  const { appConstants } = useSelector((store) => store.themes.generalSettings);
  console.log("____________", appConstants?.guest);
  const {
    ndisParticipantDetails,
    invoiceCompanyDetails,
    invoiceActiveItems,
    invoicePlanManager,
    invoiceTaxes,
    invoiceUnits,
    invoiceClaimTypes,
    invoiceTypes,
    invoicePrintData,
    invoiceFormDetails,
    invoiceEmailRecipients,
    invoiceEmailContent,
    invoiceEmailTemplate,
    invoiceEmailHistory,
  } = useSelector((store) => store.invoices.invoices);

  const [selectedTransactionId, setSelectedTransactionId] = useState(
    location?.state?.transaction_id ? location.state.transaction_id : ""
  );
  const [selectedTransactionCompId, setSelectedTransactionCompId] = useState(
    location?.state?.CompanyId ? location.state.CompanyId : ""
  );
  const [planManagerDetailsVisible, setPlanManagerDetailsVisible] =
    useState(false);
  const [bankDetailsVisible, setBankDetailsVisible] = useState(false);
  const [invItemsCount, setInvItemsCount] = useState(1);
  const [transData, setTransData] = useState([]);
  const [dummy, setDummy] = useState(null);
  const [taxAmount, setTaxAmount] = useState(0);
  const [selectedNDISParticipant, setSelectedNDISParticipant] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCustomerState, setSelectedCustomerState] = useState(null);
  const [selectedPlanManager, setSelectedPlanManager] = useState([]);
  const [selectedPurchaseItems, setSelectedPurchaseItems] = useState([]);
  const [selectedInvDate, setSelectedInvDate] = useState(null);
  const [selectedSupportStartDate, setSelectedSupportStartDate] =
    useState(null);
  const [selectedSupportEndDate, setSelectedSupportEndDate] = useState(null);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState([]);
  const [selectedPlanMngrNew, setSelectedPlanMngrNew] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(appConstants?.guest ?? false);
  const [selectedCompanyLogo, setSelectedCompanyLogo] = useState(null);

  const [maxWidthValue, setMaxWidthValue] = useState("1200px");

  const [composeEmailModalOpen, setComposeEmailModalOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [ccRecipients, setCCRecipients] = useState([]);
  const [bccRecipients, setBCCRecipients] = useState([]);

  const [subject, setSubject] = useState("");
  const [emailData, setEmailData] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [invoicePdfFileName, setInvoicePdfFileName] = useState("");

  const [openMailHistory, setOpenMailHistory] = useState(false);

  useEffect(() => {
    const currentDate = formatDate(new Date(), "YYYY-MM-DD");
    setValue("invDate", currentDate, {
      shouldValidate: true,
    });
    setSelectedInvDate(currentDate);
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", trackBodyClick);
    window.addEventListener("resize", trackDimensionsChange);

    setTimeout(() => {
      trackDimensionsChange();
    }, "500");

    return function cleanup() {
      window.removeEventListener("click", trackBodyClick);
      window.removeEventListener("resize", trackDimensionsChange);
    };
  }, []);

  useLayoutEffect(() => {
    if (document.querySelector("#inv_line_spinner")) {
      document.querySelector("#inv_line_spinner").style.display = "none";
    }
  });

  useEffect(() => {
    if (appConstants?.BWidth) {
      setMaxWidthValue(`${appConstants["BWidth"]}px`);
    }
    if (appConstants?.guest) {
      console.log("im guest user______");
      setValue("guestUser", true);
      setIsGuestUser(true);
    }
  }, [appConstants]);

  useEffect(() => {
    if (
      !selectedCompany &&
      appConstants &&
      !isGuestUser &&
      !selectedTransactionId &&
      invoiceCompanyDetails &&
      invoiceCompanyDetails.length > 0
    ) {
      const invoiceCompanyIndex = invoiceCompanyDetails.findIndex(
        (p) => p.CMP_Select === "select"
      );
      console.log("im the working_________");
      handleCompanyChange(invoiceCompanyDetails[invoiceCompanyIndex]);
    }
  }, [invoiceCompanyDetails, appConstants]);

  useEffect(() => {
    if (
      invoiceEmailRecipients &&
      Object.keys(invoiceEmailRecipients).length > 0
    ) {
      setRecipients(invoiceEmailRecipients["INV_ToMails"]);
      setCCRecipients(invoiceEmailRecipients["INV_CcMails"]);
      setBCCRecipients(invoiceEmailRecipients["INV_BccMails"]);
    }
  }, [invoiceEmailRecipients]);
  useEffect(() => {
    if (
      invoiceEmailContent &&
      Object.keys(invoiceEmailContent).length > 0 &&
      invoiceEmailTemplate &&
      Object.keys(invoiceEmailTemplate).length > 0 > 0
    ) {
      const logoHtml = `<img src="${invoiceEmailContent["LogoPath"]}" />`;
      let invoiceVariableData = {
        "{cmp_logo}": <div dangerouslySetInnerHTML={{ __html: logoHtml }} />,
        "{cmp_name}": invoiceEmailContent["Company"],
        "{cmp_abn}": invoiceEmailContent["ABN"],
        "{doc_amt}": invoiceEmailContent["Total"],
        "{doc_date}": invoiceEmailContent["INV_Date"],
        "{doc_no}": invoiceEmailContent["INVPrefix"],
        "{user_part_name}": invoiceEmailContent["NDIS_Name"],
        "{adt_cnt}": "",
        "{mail_footer}": invoiceEmailContent["Footer"],
      };
      setEmailData(
        replacePreviewHtml(
          invoiceEmailTemplate[0]["SET_Content"],
          invoiceVariableData
        )
      );

      let attachmentData = [];
      if (invoiceEmailContent["PdfFile"]) {
        attachmentData.push({
          filename: invoiceEmailContent["PdfFile"],
          path: `${MainAppConstants.fsPath}invoices/${invoiceEmailContent["PdfFile"]}`,
        });
      }

      console.log("attachmentData___________", attachmentData);
      setAttachments(attachmentData);

      setInvoicePdfFileName(invoiceEmailContent["PdfFile"]);

      setSubject(invoiceEmailContent["Email_subject"]);
    }
  }, [invoiceEmailContent, invoiceEmailTemplate]);

  const replacePreviewHtml = (text, dummyData) => {
    let replacedText = text;
    Object.keys(dummyData).forEach(function (key) {
      replacedText = replacedText.replaceAll(key, dummyData[key]);
    });
    return replacedText;
  };

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initInvoiceForm(selectedCompany?.CMP_Id))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {})
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, [selectedCompany]);

  useEffect(() => {
    if (
      !selectedCompany &&
      selectedTransactionId &&
      selectedTransactionCompId
    ) {
      dispatch(setLoader(true));
      dispatch(
        initEditInvoiceForm(selectedTransactionId, selectedTransactionCompId)
      )
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {})
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  }, [selectedTransactionId, selectedTransactionCompId]);

  useEffect(() => {
    if (
      selectedTransactionId &&
      invoiceFormDetails &&
      Object.keys(invoiceFormDetails).length > 0
    ) {
      if (!selectedCompany) {
        const invoiceCompanyIndex = invoiceCompanyDetails.findIndex(
          (p) =>
            p.CMP_Id.toString() ===
            invoiceFormDetails["Invoice"]["CompanyID"].toString()
        );

        if (invoiceCompanyIndex > -1) {
          const companyDetails = invoiceCompanyDetails[invoiceCompanyIndex];

          const fieldsToSet = [
            { name: "companyName_Options", value: companyDetails },
            { name: "companyName", value: companyDetails },
            { name: "companyAbn", value: `ABN : ${companyDetails?.CMP_ABN}` },
            { name: "custAddress", value: companyDetails?.CMP_Address },
            { name: "custState", value: companyDetails?.CMP_State_Name },
            { name: "countryName", value: "Australia" },
            { name: "custMobile", value: companyDetails?.CMP_Phone },
            { name: "custEmail", value: companyDetails?.CMP_Email },
            { name: "bankAccName", value: companyDetails?.CMP_BankName },
            { name: "bankAccBSB", value: companyDetails?.CMP_BankBSB },
            { name: "bankAccNumber", value: companyDetails?.CMP_AccNo },
          ];

          fieldsToSet.forEach(({ name, value }) => {
            setValue(name, value, { shouldValidate: true });
          });
          console.log("@1");
          console.log("setSelectedCompany__________", companyDetails);
          setSelectedCompany(companyDetails);
          setSelectedCustomerState(companyDetails?.CMP_State_Name);
          setBankDetailsVisible(companyDetails?.CMP_AccNo);
        }
      }
      if (Array.isArray(invoiceTypes)) {
        const invTypeIndex = invoiceTypes.findIndex(
          (p) => parseInt(p.INV_Type) === parseInt(selectedCompany?.CMP_InvType)
        );

        if (invTypeIndex > -1) {
          const selectedInvoiceType = invoiceTypes[invTypeIndex];
          setValue("invoicetitle", selectedInvoiceType, {
            shouldValidate: true,
          });
          setValue("invoicetitle_Options", selectedInvoiceType, {
            shouldValidate: true,
          });
          setSelectedInvoiceType(selectedInvoiceType);
        }
      }

      setValue("custState", invoiceFormDetails["Invoice"]["CMPState"], {
        shouldValidate: true,
      });
      setSelectedCustomerState(invoiceFormDetails["Invoice"]["CMPState"]);

      setValue("invoiceNo", invoiceFormDetails["Invoice"]["INVPrefix"], {
        shouldValidate: true,
      });

      setValue("invDate", invoiceFormDetails["Invoice"]["INV_Date"], {
        shouldValidate: true,
      });
      setSelectedInvDate(invoiceFormDetails["Invoice"]["INV_Date"]);

      setValue(
        "supportStartDate",
        invoiceFormDetails["Invoice"]["Support_Start"],
        {
          shouldValidate: true,
        }
      );
      setSelectedSupportStartDate(
        invoiceFormDetails["Invoice"]["Support_Start"]
      );

      setValue("supportEndDate", invoiceFormDetails["Invoice"]["Support_End"], {
        shouldValidate: true,
      });
      setSelectedSupportEndDate(invoiceFormDetails["Invoice"]["Support_End"]);

      setValue("INVID", selectedTransactionId);
      setValue("action", "edit");

      const invoicePlanManagerIndex = invoicePlanManager.findIndex(
        (p) =>
          parseInt(p.PM_Id) ===
          parseInt(invoiceFormDetails["Invoice"]["BillToId"])
      );
      if (invoicePlanManagerIndex > -1) {
        const planManagerDetails = invoicePlanManager[invoicePlanManagerIndex];
        const fieldsToSet = [
          { name: "planMngrName", value: planManagerDetails },
          { name: "planMngrName_Options", value: planManagerDetails },
          { name: "billToAddress", value: planManagerDetails?.PM_Address },
        ];
        fieldsToSet.forEach(({ name, value }) => {
          setValue(name, value, { shouldValidate: true });
        });
        setSelectedPlanManager(planManagerDetails);
      }

      const invoiceNdisParticipantIndex = ndisParticipantDetails.findIndex(
        (p) =>
          parseInt(p.NDIS_Id) ===
          parseInt(invoiceFormDetails["Invoice"]["NDIS_Id"])
      );
      if (invoiceNdisParticipantIndex > -1) {
        const ndisParticipantDetailedInfo =
          ndisParticipantDetails[invoiceNdisParticipantIndex];
        const fieldsToSet = [
          { name: "customerRef", value: ndisParticipantDetailedInfo },
          { name: "customerRef_Options", value: ndisParticipantDetailedInfo },
          {
            name: "customerRefNumber",
            value: ndisParticipantDetailedInfo?.NDIS_No,
          },
          {
            name: "customerRefAddress",
            value: ndisParticipantDetailedInfo?.NDIS_Address,
          },
        ];
        fieldsToSet.forEach(({ name, value }) => {
          setValue(name, value, { shouldValidate: true });
        });
        setSelectedNDISParticipant(ndisParticipantDetailedInfo);
      }

      setValue(
        "invoiceParticulars",
        invoiceFormDetails["Invoice"]["INVParticualr"],
        { shouldValidate: true }
      );
      setValue(
        "invoiceReferenceNo",
        invoiceFormDetails["Invoice"]["INVRemarks"],
        { shouldValidate: true }
      );

      const transactionDataObject = invoiceFormDetails["Invoice"]["TransData"];
      if (
        transactionDataObject &&
        JSON.parse(transactionDataObject) &&
        typeof JSON.parse(transactionDataObject) === "object"
      ) {
        setTransData(JSON.parse(transactionDataObject));
      } else {
        const transactionData = invoiceFormDetails["Invoice_Items"];
        let subData = [];
        if (transactionData && Array.isArray(transactionData)) {
          for (var i = 0; i < transactionData.length; i++) {
            subData.push({
              type: "line",
              lineid: Date.now() + i,
              product: transactionData[i]["ItemId"],
              description: transactionData[i]["ItemDesc"],
              supportStartDate: transactionData[i]["StDate"],
              supportEndDate: transactionData[i]["EnDate"],
              claimType: transactionData[i]["ClaimType"],
              unit: transactionData[i]["UnitID"],
              quantity: transactionData[i]["Qty"],
              price: transactionData[i]["UnitPrice"],
              subtotal: transactionData[i]["PayAmt"],
              taxes: transactionData[i]["ItemGST_ID"],
              taxAmount: 0,
              grandTotal: transactionData[i]["PayAmt"],
              error: false,
            });
          }
        }
        setTransData(subData);
      }
    }
  }, [
    invoiceFormDetails,
    invoiceTypes,
    invoicePlanManager,
    ndisParticipantDetails,
  ]);

  const trackDimensionsChange = () => {};

  const trackBodyClick = (event) => {};

  const togglePlanManagerDetails = () => {
    setPlanManagerDetailsVisible((prevVisible) => !prevVisible);
  };

  const toggleBankDetails = () => {
    setBankDetailsVisible((prevVisible) => !prevVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });

    if (name === "invDate") {
      setSelectedInvDate(value);
    }
    if (name === "supportStartDate") {
      setSelectedSupportStartDate(value);
    }
    if (name === "supportEndDate") {
      setSelectedSupportEndDate(value);
    }
  };

  const displayInvoiceForm = () => {
    return (
      <div className="transactionFormGroup">
        <table className="transactionFormInnerGroup">
          <tbody>
            <tr>
              <td>
                {selectedCompanyLogo ? (
                  <>
                    <img
                      src={selectedCompanyLogo}
                      alt="Uploaded"
                      style={{ width: "150px" }}
                    />
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  {console.log("isGuestUser______________", isGuestUser)}
                  {isGuestUser ? (
                    <>
                      <TextField
                        type="text"
                        placeholder="Company"
                        {...register("companyName", {
                          required: true,
                        })}
                        variant="standard"
                        size="small"
                        style={{ height: "auto" }}
                        defaultValue={""}
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                        onChange={(event) =>
                          setValue("companyName", event.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {errors?.companyName?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        freeSolo
                        options={invoiceCompanyDetails || []}
                        getOptionLabel={(option) =>
                          option && option.CMP_Name ? option.CMP_Name : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.CMP_Id === parseInt(value.CMP_Id)
                        }
                        onChange={(event, value) => {
                          console.log("im working");
                          handleCompanyChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            multiline
                            error={errors?.companyName ? true : false}
                            {...register("companyName", {
                              required: true,
                            })}
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                            variant="standard"
                            placeholder="Your Company"
                            // className="new-info-icon"
                          />
                        )}
                        value={selectedCompany}
                      />
                      {errors?.companyName?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </>
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="ABN Number"
                    {...register("companyAbn", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    style={{ height: "auto" }}
                    defaultValue={"ABN :"}
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                    onChange={(event) =>
                      setValue("companyAbn", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.companyAbn?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Address"
                    {...register("custAddress", {
                      required: true,
                    })}
                    variant="standard"
                    size="small"
                    multiline
                    rows={2}
                    style={{ height: "auto" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("custAddress", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                  />
                  {errors?.custAddress?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td className="td_input p-0">
                <div className="d-flex">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <Select
                        {...register("custState", {
                          required: true,
                        })}
                        onChange={(e) => {
                          setValue("custState", e.target.value, {
                            shouldValidate: true,
                          });
                          setSelectedCustomerState(e.target.value);
                        }}
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                        placeholder="State"
                        label="State"
                        fullWidth
                        sx={{ width: "100%" }}
                        size="small"
                        value={selectedCustomerState}
                      >
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <span className="formLabel mx-3"></span>
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <TextField
                        type="text"
                        placeholder="Country"
                        {...register("countryName", {
                          required: true,
                        })}
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ width: "100%" }}
                        style={{ height: "auto" }}
                        defaultValue={"Australia"}
                        onChange={(event) =>
                          setValue("countryName", event.target.value, {
                            shouldValidate: true,
                          })
                        }
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                      />
                      {errors?.countryName?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </FormControl>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Mobile Number"
                    {...register("custMobile")}
                    variant="standard"
                    size="small"
                    style={{ height: "auto" }}
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("custMobile", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                  />
                  {errors?.custMobile?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Email Id"
                    {...register("custEmail")}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    style={{ height: "auto" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("custEmail", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                  />
                  {errors?.custEmail?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr className="bg-primary">
              <th>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    className="th-form-control"
                    {...register("billToTitle", {
                      required: true,
                    })}
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%" }}
                    style={{ height: "auto" }}
                    defaultValue={"BILL TO"}
                    onChange={(event) =>
                      setValue("billToTitle", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                  />
                  {errors?.billToTitle?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </th>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  {isGuestUser ? (
                    <>
                      <TextField
                        type="text"
                        placeholder="Bill To"
                        {...register("planMngrName")}
                        variant="standard"
                        size="small"
                        style={{ height: "auto" }}
                        sx={{ width: "100%" }}
                        defaultValue={""}
                        onChange={(event) =>
                          setValue("planMngrName", event.target.value, {
                            shouldValidate: true,
                          })
                        }
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                      />
                      {errors?.planMngrName?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        options={invoicePlanManager || []}
                        getOptionLabel={(option) =>
                          option?.PM_Temp_Name
                            ? option.PM_Temp_Name
                            : option?.PM_Name || ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.PM_Id === parseInt(value.PM_Id)
                        }
                        renderOption={(props, option, { inputValue }) => {
                          let thisOption = option.PM_Name;
                          if (option["PM_Temp_Name"] !== undefined) {
                            thisOption = (
                              <div className="create-new-option">
                                {option.PM_Name}
                              </div>
                            );
                          }
                          return (
                            <li {...props} key={option.PM_Id}>
                              {thisOption}
                            </li>
                          );
                        }}
                        filterOptions={(options, params) => {
                          let newOptions = [];
                          let filtered = [];
                          options.forEach((element) => {
                            if (
                              element.PM_Name.replace(",", "")
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            )
                              newOptions.push(element);
                          });
                          filtered = newOptions;
                          // const filtered = options;
                          const isExisting = options.some(
                            (option) => params.inputValue === option["PM_Name"]
                          );
                          if (params.inputValue !== "" && !isExisting) {
                            filtered.push({
                              PM_Name: `Create "${params.inputValue}"`,
                              PM_Id: 0,
                              PM_Temp_Name: params.inputValue,
                            });
                            setSelectedPlanMngrNew(true);
                          }
                          return filtered;
                        }}
                        onChange={(e, options) => {
                          setValue("planMngrName", options, {
                            shouldValidate: true,
                          });
                          setValue("planMngrName_Options", options, {
                            shouldValidate: true,
                          });
                          setSelectedPlanManager(options);
                          setValue("billToAddress", options?.PM_Address, {
                            shouldValidate: true,
                          });
                          setSelectedPlanMngrNew(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            multiline
                            maxRows={5}
                            error={errors?.planMngrName ? true : false}
                            {...register("planMngrName", {
                              required: true,
                            })}
                            variant="standard"
                            placeholder="Bill To"
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                            className={
                              parseInt(selectedPlanManager?.PM_Id) > 0
                                ? "" // Empty class
                                : parseInt(selectedPlanManager?.PM_Id) === 0
                                ? "new-info-icon" // Class "new-info-icon"
                                : null // No class
                            }
                          />
                        )}
                        value={selectedPlanManager}
                      />

                      {errors?.planMngrName?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </>
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Address"
                    {...register("billToAddress")}
                    variant="standard"
                    size="small"
                    sx={{ width: "100%" }}
                    defaultValue={""}
                    onChange={(event) =>
                      setValue("billToAddress", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                  />
                  {errors?.billToAddress?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td className="td_input p-0">
                <div className="d-flex">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <BasicDatePicker
                        id="supportStartDate"
                        name="supportStartDate"
                        format="dd-MM-yyyy"
                        variant="standard"
                        error={errors?.supportStartDate ? true : false}
                        {...register("supportStartDate", {
                          required: true,
                        })}
                        maxDate={
                          selectedSupportEndDate !== null
                            ? new Date(selectedSupportEndDate)
                            : ""
                        }
                        placeholder="Support Start Date"
                        label="Support Start Date"
                        handleChange={handleChange}
                        value={selectedSupportStartDate}
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                      />
                      {errors?.supportStartDate?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </FormControl>
                  </div>
                  <span className="formLabel mx-3"></span>
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <BasicDatePicker
                        id="supportEndDate"
                        name="supportEndDate"
                        format="dd-MM-yyyy"
                        variant="standard"
                        error={errors?.supportEndDate ? true : false}
                        {...register("supportEndDate", {
                          required: true,
                        })}
                        placeholder="Support End Date"
                        label="Support End Date"
                        handleChange={handleChange}
                        value={selectedSupportEndDate}
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                      />
                      {errors?.supportEndDate?.type === "required" && (
                        <FieldError errorText="This field Is required" />
                      )}
                    </FormControl>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="transactionFormInnerGroupGap"></table>
        <table className="transactionFormInnerGroupRight">
          <tbody>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <Autocomplete
                    options={invoiceTypes || []}
                    getOptionLabel={(option) =>
                      option && option.INV_Label ? option.INV_Label : ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.INV_Type === parseInt(value.INV_Type)
                    }
                    onChange={(e, options) => {
                      setValue("invoicetitle", options, {
                        shouldValidate: true,
                      });
                      setValue("invoicetitle_Options", options, {
                        shouldValidate: true,
                      });
                      setSelectedInvoiceType(options);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        error={errors?.invoicetitle ? true : false}
                        {...register("invoicetitle", {
                          required: true,
                        })}
                        variant="standard"
                        placeholder="Select Invoice Type"
                        onFocus={(event) => {
                          invoiceLineItemFocus(event, 0, null);
                        }}
                      />
                    )}
                    value={selectedInvoiceType}
                  />

                  {errors?.invoicetitle?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%" className="text-center mt-20">
                  <tbody>
                    <tr className="bg-primary">
                      <th width="50%" className="p-0 theadClass">
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            type="text"
                            className="bg-primary"
                            placeholder="INV No"
                            {...register("invNoTitle", {
                              required: true,
                            })}
                            variant="outlined"
                            size="small"
                            sx={{ width: "100%" }}
                            defaultValue={"INVOICE#"}
                            onChange={(event) =>
                              setValue("invNoTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.invNoTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </th>
                      <th width="50%" className="p-0 theadClass">
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            type="text"
                            className="bg-primary"
                            placeholder="INV Date"
                            {...register("invDateTitle", {
                              required: true,
                            })}
                            variant="outlined"
                            size="small"
                            sx={{ width: "100%" }}
                            defaultValue={"DATE"}
                            onChange={(event) =>
                              setValue("invDateTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.invDateTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </th>
                    </tr>
                    <tr>
                      <td width="50%">
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="Invoice No"
                            {...register("invoiceNo", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue("invoiceNo", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.invoiceNo?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                      <td width="50%">
                        <FormControl variant="standard" fullWidth>
                          <BasicDatePicker
                            id="invDate"
                            name="invDate"
                            format="dd-MM-yyyy"
                            variant="standard"
                            error={errors?.invDate ? true : false}
                            {...register("invDate", {
                              required: true,
                            })}
                            placeholder="Invoice Date"
                            handleChange={handleChange}
                            value={selectedInvDate}
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.invDate?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%" className="mt-20">
                  <tbody>
                    <tr className="bg-primary">
                      <th className="theadClass">
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            className="th-form-control bg-primary"
                            placeholder="Title"
                            {...register("custRefTitle", {
                              required: true,
                            })}
                            variant="outlined"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={"NDIS Participant"}
                            onChange={(event) =>
                              setValue("custRefTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.custRefTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <FormControl variant="standard" fullWidth>
                          {isGuestUser ? (
                            <>
                              <TextField
                                type="text"
                                placeholder="NDIS Participant Name"
                                {...register("customerRef")}
                                variant="standard"
                                size="small"
                                style={{ height: "auto" }}
                                sx={{ width: "100%" }}
                                defaultValue={""}
                                onChange={(event) =>
                                  setValue("customerRef", event.target.value, {
                                    shouldValidate: true,
                                  })
                                }
                                onFocus={(event) => {
                                  invoiceLineItemFocus(event, 0, null);
                                }}
                              />
                              {errors?.customerRef?.type === "required" && (
                                <FieldError errorText="This field Is required" />
                              )}
                            </>
                          ) : (
                            <>
                              <Autocomplete
                                options={ndisParticipantDetails || []}
                                getOptionLabel={(option) =>
                                  option?.NDIS_Temp_Name
                                    ? option.NDIS_Temp_Name
                                    : option?.NDIS_Name || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.NDIS_Id === parseInt(value.NDIS_Id)
                                }
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  let thisOption = option.NDIS_Name;
                                  if (option["NDIS_Temp_Name"] !== undefined) {
                                    thisOption = (
                                      <div className="create-new-option">
                                        {option.NDIS_Name}
                                      </div>
                                    );
                                  }
                                  return (
                                    <li {...props} key={option.NDIS_Id}>
                                      {thisOption}
                                    </li>
                                  );
                                }}
                                filterOptions={(options, params) => {
                                  let newOptions = [];
                                  let filtered = [];
                                  options.forEach((element) => {
                                    if (
                                      element.NDIS_Name.replace(",", "")
                                        .toLowerCase()
                                        .includes(
                                          params.inputValue.toLowerCase()
                                        )
                                    )
                                      newOptions.push(element);
                                  });
                                  filtered = newOptions;
                                  // const filtered = options;
                                  const isExisting = options.some(
                                    (option) =>
                                      params.inputValue === option["NDIS_Name"]
                                  );
                                  if (params.inputValue !== "" && !isExisting) {
                                    filtered.push({
                                      NDIS_Name: `Create "${params.inputValue}"`,
                                      NDIS_Id: 0,
                                      NDIS_Temp_Name: params.inputValue,
                                    });
                                    setSelectedPlanMngrNew(true);
                                  }
                                  return filtered;
                                }}
                                onChange={(e, options) => {
                                  setValue("customerRef", options, {
                                    shouldValidate: true,
                                  });
                                  setValue("customerRef_Options", options, {
                                    shouldValidate: true,
                                  });
                                  setSelectedNDISParticipant(options);
                                  setValue(
                                    "customerRefNumber",
                                    options?.NDIS_No,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                  setValue(
                                    "customerRefAddress",
                                    options?.NDIS_Address,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                    multiline
                                    maxRows={5}
                                    error={errors?.customerRef ? true : false}
                                    {...register("customerRef", {
                                      required: true,
                                    })}
                                    variant="standard"
                                    placeholder="NDIS Participant Name"
                                    onFocus={(event) => {
                                      invoiceLineItemFocus(event, 0, null);
                                    }}
                                    className={
                                      parseInt(
                                        selectedNDISParticipant?.NDIS_Id
                                      ) > 0
                                        ? "" // Empty class
                                        : parseInt(
                                            selectedNDISParticipant?.NDIS_Id
                                          ) === 0
                                        ? "new-info-icon" // Class "new-info-icon"
                                        : null // No class
                                    }
                                  />
                                )}
                                value={selectedNDISParticipant}
                              />
                              {errors?.customerRef?.type === "required" && (
                                <FieldError errorText="This field Is required" />
                              )}
                            </>
                          )}
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="NDIS Participant No"
                            {...register("customerRefNumber", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue(
                                "customerRefNumber",
                                event.target.value,
                                {
                                  shouldValidate: true,
                                }
                              )
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.customerRefNumber?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="NDIS Participant Address"
                            {...register("customerRefAddress")}
                            variant="standard"
                            size="small"
                            multiline
                            rows={2}
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue(
                                "customerRefAddress",
                                event.target.value,
                                {
                                  shouldValidate: true,
                                }
                              )
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.customerRefAddress?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const handleCompanyChange = (value) => {
    console.log("handleCompanyChange working___________________");
    dispatch(setLoader(true));
    dispatch(updateCompany(value?.CMP_Id))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        console.log("isGuestUser__________", isGuestUser);
        if (!isGuestUser) {
          setValue("companyName_Options", value, {
            shouldValidate: true,
          });
          setValue("companyName", value, {
            shouldValidate: true,
          });
          console.log("@2");
          console.log("setSelectedCompany__________", value);
          setSelectedCompany(value);
        }
        setSelectedCompanyLogo(value?.CMPLogo);

        setValue("companyAbn", `ABN : ${value?.CMP_ABN}`, {
          shouldValidate: true,
        });
        setValue("custAddress", value?.CMP_Address, {
          shouldValidate: true,
        });
        setValue("custState", value?.CMP_State_Name, {
          shouldValidate: true,
        });
        setSelectedCustomerState(value?.CMP_State_Name);
        setValue("countryName", "Australia", {
          shouldValidate: true,
        });
        setValue("custMobile", value?.CMP_Phone, {
          shouldValidate: true,
        });
        setValue("custEmail", value?.CMP_Email, {
          shouldValidate: true,
        });

        setValue("bankAccName", value?.CMP_BankName, {
          shouldValidate: true,
        });
        setValue("bankAccBSB", value?.CMP_BankBSB, {
          shouldValidate: true,
        });
        setValue("bankAccNumber", value?.CMP_AccNo, {
          shouldValidate: true,
        });
        setBankDetailsVisible(value?.CMP_AccNo ? true : false);

        if (Array.isArray(invoiceTypes)) {
          const invTypeIndex = invoiceTypes.findIndex(
            (p) => parseInt(p.INV_Type) === parseInt(value?.CMP_InvType)
          );

          if (invTypeIndex > -1) {
            const selectedInvoiceType = invoiceTypes[invTypeIndex];

            setValue("invoicetitle", selectedInvoiceType, {
              shouldValidate: true,
            });
            setValue("invoicetitle_Options", selectedInvoiceType, {
              shouldValidate: true,
            });
            setSelectedInvoiceType(selectedInvoiceType);
          }
        }

        setValue("invoiceNo", value?.CMP_INVPrefix_Id, {
          shouldValidate: true,
        });

        setValue("planMngrName", [], {
          shouldValidate: true,
        });
        setValue("planMngrName_Options", [], {
          shouldValidate: true,
        });
        setSelectedPlanManager([]);
        setValue("billToAddress", null, {
          shouldValidate: true,
        });
        setSelectedPlanMngrNew(false);

        setValue("customerRef", [], {
          shouldValidate: true,
        });
        setValue("customerRef_Options", [], {
          shouldValidate: true,
        });
        setSelectedNDISParticipant([]);
        setValue("customerRefNumber", null, {
          shouldValidate: true,
        });
        setValue("customerRefAddress", null, {
          shouldValidate: true,
        });
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const displayInvSecForm = () => {
    return (
      <div className="transactionFormGroup">
        <table className="transactionFormSecGroup">
          <tbody>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Particulars"
                    {...register("invoiceParticulars")}
                    variant="standard"
                    multiline
                    size="small"
                    style={{ height: "auto" }}
                    defaultValue={""}
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                    onChange={(event) =>
                      setValue("invoiceParticulars", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.invoiceParticulars?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="transactionFormSecGroupGap"></table>
        <table className="transactionFormSecGroupRight">
          <tbody>
            <tr>
              <td>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    type="text"
                    placeholder="Reference Number"
                    {...register("invoiceReferenceNo")}
                    variant="standard"
                    size="small"
                    style={{ height: "auto" }}
                    defaultValue={""}
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, 0, null);
                    }}
                    onChange={(event) =>
                      setValue("invoiceReferenceNo", event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  {errors?.invoiceReferenceNo?.type === "required" && (
                    <FieldError errorText="This field Is required" />
                  )}
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const invoiceLinesHead = () => {
    return (
      <thead className="trns-entry-container bg-primary">
        <tr>
          <th className="trns-entry-hash">#</th>
          <th className="trns-entry-hash">SlNo</th>
          <th className="trns-entry-product">ITEM & DESC</th>
          <th className="trns-entry-label">CLAIM</th>
          <th className="trns-entry-quantity">QTY</th>
          <th className="trns-entry-unit">UNIT</th>
          <th className="trns-entry-price">RATE</th>
          {/* <th className="trns-entry-subtotal">SUB TOTAL</th> */}
          <th className="trns-entry-label">GST</th>
          {/* <th className="trns-entry-subtotal">TAX AMNT</th> */}
          <th className="trns-entry-subtotal">TOTAL</th>
          <th className="trns-entry-menu"></th>
        </tr>
      </thead>
    );
  };

  const invoiceLinesSummary = () => {
    let invLineSum = 0;
    let invTotal = 0;
    let invTaxAmount = 0;

    if (transData) {
      Array.from(transData).forEach((value) => {
        if (value) {
          invLineSum += parseFloat(value["subtotal"]);

          let taxArr =
            invoiceTaxes[
              findIndexOfArray(invoiceTaxes, value["taxes"], "GST_Id")
            ];
          if (taxArr && taxArr["GST_Percentage"]) {
            let taxPercentage = parseFloat(taxArr["GST_Percentage"]);
            let totalPrice =
              parseFloat(value["price"]) * parseFloat(value["quantity"]);

            invTaxAmount += totalPrice * (taxPercentage / 100);

            invTotal +=
              parseFloat(value["subtotal"]) +
              totalPrice * (taxPercentage / 100);
          }
        }
      });
    }

    return (
      <table className="invoiceSummary">
        <tbody>
          <tr className="space-between-rows">
            <td style={{ width: "60%" }}>
              <Button onClick={toggleBankDetails}>
                {bankDetailsVisible
                  ? "Remove Bank Details"
                  : "Add Bank Details"}
              </Button>
              {bankDetailsVisible && (
                <table width="100%" className="bankDetailsTbl mt--10">
                  <tbody>
                    <tr className="bg-primary">
                      <th className="p-0 theadClass">
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            type="text"
                            className="th-form-control bg-primary"
                            placeholder="Title"
                            {...register("bankDetailTitle", {
                              required: true,
                            })}
                            variant="outlined"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={"BANK DETAILS"}
                            onChange={(event) =>
                              setValue("bankDetailTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankDetailTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </th>
                      <th className="p-0 theadClass"></th>
                    </tr>
                    <tr>
                      <td>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="Account Name"
                            {...register("bankAccNameTitle", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={"Account Name : "}
                            onChange={(event) =>
                              setValue("bankAccNameTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccNameTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="BSB"
                            {...register("bankAccBSBTitle", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={"BSB : "}
                            onChange={(event) =>
                              setValue("bankAccBSBTitle", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccBSBTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="Account Number"
                            {...register("bankAccNumberTitle", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={"Account Number : "}
                            onChange={(event) =>
                              setValue(
                                "bankAccNumberTitle",
                                event.target.value,
                                {
                                  shouldValidate: true,
                                }
                              )
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccNumberTitle?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                      <td>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="Account Name"
                            {...register("bankAccName", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue("bankAccName", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccName?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="BSB"
                            {...register("bankAccBSB", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue("bankAccBSB", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccBSB?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            type="text"
                            placeholder="Account Number"
                            {...register("bankAccNumber", {
                              required: true,
                            })}
                            variant="standard"
                            size="small"
                            style={{ height: "auto" }}
                            defaultValue={""}
                            onChange={(event) =>
                              setValue("bankAccNumber", event.target.value, {
                                shouldValidate: true,
                              })
                            }
                            onFocus={(event) => {
                              invoiceLineItemFocus(event, 0, null);
                            }}
                          />
                          {errors?.bankAccNumber?.type === "required" && (
                            <FieldError errorText="This field Is required" />
                          )}
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </td>

            <td className="invoiceAmountSummary" style={{ width: "40%" }}>
              <table className="invoiceAmountSummaryTbl">
                <tbody>
                  <tr>
                    <td className="invoiceAmountLabel">SUB TOTAL</td>
                    <td className="invoiceAmountValue">
                      {invLineSum.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="invoiceAmountLabel">TAX AMOUNT</td>
                    <td className="invoiceAmountValue">
                      {invTaxAmount.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="invoiceAmountLabel">GRAND TOTAL</td>
                    <td className="invoiceAmountValue">
                      {invTotal.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="space-between-rows">
            <td>
              <FormControl variant="standard" fullWidth>
                <TextField
                  type="text"
                  placeholder=""
                  {...register("notestiltle", {
                    required: true,
                  })}
                  variant="standard"
                  size="small"
                  style={{ height: "auto" }}
                  defaultValue={"Notes"}
                  onChange={(event) =>
                    setValue("notestiltle", event.target.value, {
                      shouldValidate: true,
                    })
                  }
                  onFocus={(event) => {
                    invoiceLineItemFocus(event, 0, null);
                  }}
                />
                {errors?.notestiltle?.type === "required" && (
                  <FieldError errorText="This field Is required" />
                )}
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <TextField
                  type="text"
                  placeholder="Notes"
                  {...register("notes", {
                    required: true,
                  })}
                  variant="standard"
                  multiline
                  rows={2}
                  size="small"
                  style={{ height: "auto" }}
                  defaultValue={"Thank you for your business"}
                  onChange={(event) =>
                    setValue("notes", event.target.value, {
                      shouldValidate: true,
                    })
                  }
                  onFocus={(event) => {
                    invoiceLineItemFocus(event, 0, null);
                  }}
                />
                {errors?.notes?.type === "required" && (
                  <FieldError errorText="This field Is required" />
                )}
              </FormControl>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setTransData((transData) =>
      arrayMoveImmutable(transData, oldIndex, newIndex)
    );
  }, []);

  const invoiceLinesItems = () => {
    return (
      <SortableCont
        onSortEnd={onSortEnd}
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset={["30%", "50%"]}
        helperClass="helperContainerClass"
        useDragHandle={true}
      >
        {transData
          ? Array.from(transData).map((value, index) => {
              if (value)
                return value.type == "line" ? (
                  <SortableItem
                    key={`item-${index}`}
                    value={value}
                    index={index}
                    position={index}
                  />
                ) : null;
            })
          : null}
      </SortableCont>
    );
  };

  const findIndexOfArray = (searchArray, value, key) => {
    let index = searchArray.findIndex((arrayItem) => arrayItem[key] == value);
    return index;
  };

  const CustomPopper = function (props) {
    return (
      <Popper {...props} style={styles.popper} placement="bottom-start">
        {props.children}
      </Popper>
    );
  };

  const styles = (theme) => ({
    popper: {
      width: "fit-content",
    },
  });

  const SortableItem = SortableElement((props) => {
    return (
      <TrWrapper
        id={`inv_line_${props.position}`}
        className={props.value.error ? "error-inv-line" : null}
      >
        <td className="trns-entry-hash">
          <RowHandler />
        </td>
        <td className="trns-entry-hash">{parseInt(props.position) + 1}</td>

        <td className="trns-entry-product">
          <div className="trns-entry-product-cell-left">
            <FormControl variant="standard" fullWidth>
              <Autocomplete
                freeSolo
                key={`${props.position}_product`}
                disableClearable
                defaultValue={
                  invoiceActiveItems[
                    findIndexOfArray(
                      invoiceActiveItems,
                      props.value.product,
                      "ItemID"
                    )
                  ]
                }
                size="small"
                options={
                  invoiceActiveItems && selectedPurchaseItems
                    ? invoiceActiveItems.filter(function (e) {
                        return selectedPurchaseItems.indexOf(e.ItemID) == -1;
                      })
                    : invoiceActiveItems
                }
                getOptionLabel={(option) =>
                  option.ItemTempName ? option.ItemTempName : option.ItemName
                }
                renderOption={(props, option, { inputValue }) => {
                  let thisOption = option.ItemName;
                  if (option["ItemTempName"] !== undefined) {
                    thisOption = (
                      <div className="create-new-option">{option.ItemName}</div>
                    );
                  }
                  return (
                    <li {...props} key={option.ItemID}>
                      <span className="autocomplete-option-text">
                        {thisOption}
                      </span>
                    </li>
                  );
                }}
                sx={{ width: "100%" }}
                PopperComponent={CustomPopper}
                onChange={(e, value) => {
                  handleLineItemChange(
                    "product",
                    value.ItemID,
                    props.position,
                    e
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Item"
                    multiline
                    autoFocus={
                      focussedItem == `${props.position}_product` ? true : false
                    }
                    onFocus={(event) => {
                      invoiceLineItemFocus(event, props.position, "product");
                    }}
                    className={`no-border`}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <TextField
                type="text"
                key={`${props.position}_description`}
                defaultValue={props.value.description}
                className="no-border"
                placeholder="Description"
                multiline
                variant="standard"
                sx={{ width: "100%" }}
                onBlur={(e) =>
                  handleLineItemChange(
                    "description",
                    e.target.value,
                    props.position,
                    e
                  )
                }
                autoFocus={
                  focussedItem == `${props.position}_description` ? true : false
                }
                onFocus={(event) => {
                  invoiceLineItemFocus(event, props.position, "description");
                }}
              />
            </FormControl>
          </div>
          <div className="transactionFormGroup">
            <div className="transactionFormInnerGroup">
              <FormControl variant="standard" fullWidth>
                <BasicDatePicker
                  key={`${props.position}_supportStartDate`}
                  id={`${props.position}_supportStartDate`}
                  name={`${props.position}_supportStartDate`}
                  format="dd-MM-yyyy"
                  variant="standard"
                  placeholder="Support Start Date"
                  label="Support Start Date"
                  lineItem={true}
                  minDate={
                    selectedSupportStartDate !== null
                      ? new Date(selectedSupportStartDate)
                      : ""
                  }
                  maxDate={
                    selectedSupportEndDate !== null
                      ? new Date(selectedSupportEndDate)
                      : ""
                  }
                  handleChange={handleLineItemChange}
                  handleOnFocus={(event) => {
                    invoiceLineItemFocus(
                      event,
                      props.position,
                      "supportStartDate"
                    );
                  }}
                  autoFocus={
                    focussedItem == `${props.position}_supportStartDate`
                      ? true
                      : false
                  }
                  value={props.value.supportStartDate}
                />
              </FormControl>
            </div>
            <div className="transactionFormInnerGroupGap"></div>
            <div className="transactionFormInnerGroupRight">
              <FormControl variant="standard" fullWidth>
                <BasicDatePicker
                  key={`${props.position}_supportEndDate`}
                  id={`${props.position}_supportEndDate`}
                  name={`${props.position}_supportEndDate`}
                  format="dd-MM-yyyy"
                  variant="standard"
                  placeholder="Support End Date"
                  label="Support End Date"
                  lineItem={true}
                  minDate={
                    selectedSupportStartDate !== null
                      ? new Date(selectedSupportStartDate)
                      : ""
                  }
                  maxDate={
                    selectedSupportEndDate !== null
                      ? new Date(selectedSupportEndDate)
                      : ""
                  }
                  handleChange={handleLineItemChange}
                  handleOnFocus={(event) => {
                    invoiceLineItemFocus(
                      event,
                      props.position,
                      "supportEndDate"
                    );
                  }}
                  autoFocus={
                    focussedItem == `${props.position}_supportEndDate`
                      ? true
                      : false
                  }
                  value={props.value.supportEndDate}
                />
              </FormControl>
            </div>
          </div>
          <div
            className="spinner-product"
            id={`product_spinner_wrapper_${props.position}`}
          >
            <span
              className="spinner-border spinner-border-sm"
              id={`product_spinner_${props.position}`}
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </td>

        <td className="trns-entry-label">
          <div className="trns-entry-product-cell-left">
            <Autocomplete
              freeSolo
              key={`${props.position}_claimType`}
              disableClearable
              defaultValue={
                invoiceClaimTypes[
                  findIndexOfArray(
                    invoiceClaimTypes,
                    props.value.claimType,
                    "ClaimID"
                  )
                ]
              }
              size="small"
              options={invoiceClaimTypes || []}
              getOptionLabel={(option) => (option ? option.ClaimName : null)}
              renderOption={(props, option) => (
                <li {...props} key={option.ClaimID}>
                  {option ? option.ClaimName : null}
                </li>
              )}
              sx={{ width: "100%" }}
              PopperComponent={CustomPopper}
              onChange={(e, value, key) => {
                handleLineItemChange(
                  "claimType",
                  value.ClaimID,
                  props.position,
                  e
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  multiline
                  autoFocus={
                    focussedItem == `${props.position}_claimType` ? true : false
                  }
                  onFocus={(event) => {
                    invoiceLineItemFocus(event, props.position, "claimType");
                  }}
                  className={`no-border`}
                />
              )}
            />
          </div>
        </td>

        <td className="trns-entry-quantity">
          <TextField
            type="number"
            key={`${props.position}_quantity`}
            defaultValue={props.value.quantity}
            className="no-border"
            inputProps={{
              min: 0,
            }}
            variant="standard"
            sx={{ width: "100%" }}
            onChange={(e) =>
              validationPattern.integer_validation(e.target.value)
                ? (e.target.value = parseInt(e.target.value))
                : (e.target.value = 0)
            }
            onBlur={(e) =>
              handleLineItemChange(
                "quantity",
                e.target.value,
                props.position,
                e
              )
            }
            autoFocus={
              focussedItem == `${props.position}_quantity` ? true : false
            }
            onFocus={(event) => {
              invoiceLineItemFocus(event, props.position, "quantity");
            }}
          />
        </td>

        <td className="trns-entry-unit">
          <div className="trns-entry-product-cell-left">
            <Autocomplete
              freeSolo
              key={`${props.position}_unit`}
              disableClearable
              defaultValue={
                invoiceUnits[
                  findIndexOfArray(invoiceUnits, props.value.unit, "UnitID")
                ]
              }
              size="small"
              options={invoiceUnits}
              getOptionLabel={(option) => (option ? option.UnitName : null)}
              renderOption={(props, option) => (
                <li {...props} key={option.UnitID}>
                  {option ? option.UnitName : null}
                </li>
              )}
              sx={{ width: "100%" }}
              PopperComponent={CustomPopper}
              onChange={(e, value, key) => {
                handleLineItemChange("unit", value.UnitID, props.position, e);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  autoFocus={
                    focussedItem == `${props.position}_unit` ? true : false
                  }
                  onFocus={(event) => {
                    invoiceLineItemFocus(event, props.position, "unit");
                  }}
                  className={`no-border`}
                />
              )}
            />
          </div>
        </td>

        <td className="trns-entry-price">
          <TextField
            type="number"
            key={`${props.position}_price`}
            defaultValue={props.value.price}
            className="no-border"
            inputProps={{
              min: 0,
              style: { textAlign: "right" },
            }}
            variant="standard"
            sx={{ width: "100%" }}
            onChange={(event) =>
              validationPattern.price_validation(event.target.value)
                ? null
                : (event.target.value = 0)
            }
            onBlur={(e) =>
              handleLineItemChange("price", e.target.value, props.position, e)
            }
            autoFocus={focussedItem == `${props.position}_price` ? true : false}
            onFocus={(event) => {
              invoiceLineItemFocus(event, props.position, "price");
              event.target.select();
            }}
          />
        </td>

        {/* <td className="trns-entry-subtotal" style={{ textAlign: "right" }}>
          {calculateItemTotal(props.value.price, props.value.quantity)}
        </td> */}

        <td className="trns-entry-taxes">
          <Autocomplete
            key={`${props.position}_taxes`}
            disableClearable
            defaultValue={
              invoiceTaxes[
                findIndexOfArray(invoiceTaxes, props.value.taxes, "GST_Id")
              ]
            }
            size="small"
            options={invoiceTaxes}
            getOptionLabel={(option) => (option ? option.GST_Slab : null)}
            renderOption={(props, option) => (
              <li {...props} key={option.GST_Id}>
                {option ? option.GST_Slab : null}
              </li>
            )}
            sx={{ width: "100%" }}
            PopperComponent={CustomPopper}
            openOnFocus
            onChange={(e, value, key) => {
              handleLineItemChange("taxes", value.GST_Id, props.position, e);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                multiline
                autoFocus={
                  focussedItem == `${props.position}_taxes` ? true : false
                }
                onFocus={(event) => {
                  invoiceLineItemFocus(event, props.position, "taxes");
                }}
                className={`no-border`}
              />
            )}
          />
        </td>

        {/* <td className="trns-entry-subtotal" style={{ textAlign: "right" }}>
          {calculateItemTaxAmountTotal(
            props.value.price,
            props.value.quantity,
            props.value.taxes
          )}
        </td> */}
        <td className="trns-entry-subtotal" style={{ textAlign: "right" }}>
          {calculateItemGrandTotalAmount(
            props.value.price,
            props.value.quantity,
            props.value.taxes
          )}
        </td>

        <td className="trns-entry-menu">
          <Delete
            fontSize="small"
            onClick={() => removeInvoiceRows(props.position)}
            id={`inv_line_delete_${props.position}`}
          />
          <span
            id={`inv_line_spinner_${props.position}`}
            className="spinner-border spinner-border-sm text-danger"
            role="status"
            aria-hidden="true"
          ></span>
        </td>
      </TrWrapper>
    );
  });

  const calculateItemTotal = (price, quantity) => {
    let totalPrice = parseFloat(price) * parseFloat(quantity);
    return parseFloat(totalPrice).toFixed(2);
  };

  const calculateItemTaxAmountTotal = (price, quantity, taxes) => {
    let taxArr = invoiceTaxes[findIndexOfArray(invoiceTaxes, taxes, "GST_Id")];
    let taxPercentage = parseFloat(taxArr["GST_Percentage"]);
    let totalPrice = parseFloat(price) * parseFloat(quantity);
    let taxAmount = totalPrice * (taxPercentage / 100);
    return parseFloat(taxAmount).toFixed(2);
  };

  const calculateItemGrandTotalAmount = (price, quantity, taxes) => {
    let taxArr = invoiceTaxes[findIndexOfArray(invoiceTaxes, taxes, "GST_Id")];
    if (taxArr && taxArr["GST_Percentage"]) {
      let taxPercentage = parseFloat(taxArr["GST_Percentage"]).toFixed(2);
      let totalPrice =
        parseFloat(price).toFixed(2) * parseFloat(quantity).toFixed(2);
      let taxAmount = totalPrice * (taxPercentage / 100);
      let grandTotal = totalPrice + taxAmount;
      return parseFloat(grandTotal).toFixed(2);
    } else {
      return 0;
    }
  };

  const invoiceColumnCount = (tableID) => {
    return document.getElementById(tableID)
      ? document.getElementById(tableID).rows[0].cells.length
      : 0;
  };

  const handleLineItemChange = (name, value, position, e) => {
    console.log("name_________-", name);
    console.log("value_________-", value);
    document
      .querySelector(`#inv_line_${position}`)
      .classList.remove("error-inv-line");

    if (transData[position][name] != value) {
      transData[position][name] = value;

      if (name == "quantity" || name == "price" || name == "taxes") {
        transData[position]["subtotal"] = calculateItemTotal(
          transData[position]["price"],
          transData[position]["quantity"]
        );
        transData[position]["grandTotal"] = calculateItemGrandTotalAmount(
          transData[position]["price"],
          transData[position]["quantity"],
          transData[position]["taxes"]
        );
      }

      if (name == "supportStartDate") {
        transData[position]["supportEndDate"] = value;
      }

      if (name == "product") {
        let thisItemData =
          invoiceActiveItems[
            findIndexOfArray(invoiceActiveItems, value, "ItemID")
          ];

        setSelectedPurchaseItems((oldArray) => [
          ...oldArray,
          thisItemData.ItemID,
        ]);

        transData[position]["unit"] = thisItemData.ItemUnit;
      }

      document
        .querySelector(`#inv_line_${position}`)
        .classList.remove("error-inv-line");
      transData[position]["error"] = false;

      setTimeout(() => {
        setTransData(transData);
        setTimeout(() => {
          setDummy(Date.now);
        }, "500");
      }, 100);
    }
  };

  const setScrollWidth = (className) => {
    return document.getElementsByClassName(className)[1]
      ? document.getElementsByClassName(className)[1].scrollWidth + 1
      : 10;
  };

  const TrWrapper = styled.tr`
    cursor: default;
    &.helperContainerClass {
      border: 1px solid #efefef;
      box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 3px;

      &:active {
        cursor: grabbing;
      }

      & > td {
        padding: 0.4rem;
        border: 0.25px solid #efefef;
      }
      & > td.trns-entry-hash {
        width: ${setScrollWidth("trns-entry-hash")}px;
      }
      & > td.trns-entry-product {
        width: ${setScrollWidth("trns-entry-product")}px;
      }
      & > td.trns-entry-unit {
        width: ${setScrollWidth("trns-entry-label")}px;
      }
      & > td.trns-entry-quantity {
        width: ${setScrollWidth("trns-entry-quantity")}px;
      }
      & > td.trns-entry-price {
        width: ${setScrollWidth("trns-entry-price")}px;
      }
      & > td.trns-entry-subtotal {
        width: ${setScrollWidth("trns-entry-subtotal")}px;
      }
      & > td.trns-entry-taxes {
        width: ${setScrollWidth("trns-entry-taxes")}px;
      }
      & > td.trns-entry-menu {
        width: ${setScrollWidth("trns-entry-menu")}px;
      }
    }
  `;

  const RowHandler = SortableHandle(() => (
    <ImportExport className="import-export-move" />
  ));

  const invoiceLineItemFocus = (event, position, item) => {
    if (event.target.type == "text") {
      event.target.select();
    }
    if (item) {
      focussedItem = `${position}_${item}`;
    } else {
      focussedItem = item;
    }
  };

  const removeInvoiceRows = (index) => {
    document.querySelector(`#inv_line_delete_${index}`).style.display = "none";
    document.querySelector(`#inv_line_spinner_${index}`).style.display =
      "inline-block";

    transData.splice(index, 1);

    setTransData(transData);
    setTimeout(() => {
      setDummy(Date.now);
    }, "500");
  };

  const invoiceLinesButtons = () => {
    return (
      <thead className="">
        <tr>
          <td colSpan={invoiceColumnCount("invoice_lines_table")}>
            <Select
              value={invItemsCount}
              variant="standard"
              label="line"
              className="no-border line-count-select"
              onChange={(event) => {
                setInvItemsCount(event.target.value);
              }}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
            <span className="line-count-seperator">
              <ArrowRight className="line-count-arrow" />
            </span>

            <button
              className="btn btn-primary loading-button add-line-button"
              type="button"
              onClick={() => {
                document.querySelector("#inv_line_spinner").style.display =
                  "block";
                addInvoiceRows("line");
              }}
            >
              Add {invItemsCount == 1 ? "a" : invItemsCount} line
              {invItemsCount == 1 ? null : "s"}
              <span
                className="spinner-border spinner-border-sm spinner-footer-btn"
                role="status"
                aria-hidden="true"
                id="inv_line_spinner"
              ></span>
            </button>
          </td>
        </tr>
      </thead>
    );
  };

  const addInvoiceRows = (addType) => {
    if (selectedSupportStartDate && selectedSupportEndDate) {
      let rowsCount = addType == "line" ? invItemsCount : 1;
      for (var i = 0; i < rowsCount; i++) {
        transData.push({
          type: addType,
          lineid: Date.now() + i,
          product: "",
          description: "",
          supportStartDate: selectedSupportStartDate
            ? formatDate(new Date(selectedSupportStartDate), "YYYY-MM-DD")
            : "",
          supportEndDate: selectedSupportEndDate
            ? formatDate(new Date(selectedSupportEndDate), "YYYY-MM-DD")
            : "",
          claimType: invoiceClaimTypes
            .filter((claim) => claim.select === "select")
            .map((claim) => claim.ClaimID),
          unit: "",
          quantity: "1",
          price: "0",
          subtotal: "0.0",
          taxes: 1,
          taxAmount: 0,
          grandTotal: 0,
          error: false,
        });
      }
      setTransData(transData);
      setTimeout(() => {
        setDummy(Date.now);
      }, "500");
    } else {
      NotificationManager.warning("Support Start / End Dates Are Mandatory");
    }
  };

  const saveInvoicePDF = (formData) => {
    dispatch(setLoader(true));
    sessionStorage.setItem("inv_comp_id", selectedCompany?.CMP_Id || null);
    dispatch(updateTransactionData(formData, transData))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        if (response?.data?.details?.INVID) {
          NotificationManager.success("Invoice Saved Successfully");
        }

        if (isGuestUser) {
          setSelectedTransactionId(response?.data?.details?.INVID);
        }

        if (!isGuestUser) {
          if (response?.data?.details?.newbillto) {
            NotificationManager.success(
              "New Plan Manager will \n be Created on Submit"
            );
          }
          if (response?.data?.details?.newpaticipant) {
            NotificationManager.success(
              "New Participant  will \n be Created on Submit"
            );
          }

          setTransData([]);
          navigate("/invoices/listInvoices", {
            replace: true,
          });
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        if (!isGuestUser) {
          clearTransactionEntryForm();
        }
      });
  };

  const clearTransactionEntryForm = () => {
    setTransData([]);
    clearErrors();
    reset();
    setTimeout(() => {
      setDummy(Date.now);
    }, "500");
  };

  const sendInvoicePDF = (InvID) => {
    let CompanyId = isGuestUser
      ? sessionStorage.getItem("comp_id")
      : selectedTransactionCompId;
    dispatch(setLoader(true));
    dispatch(initEmailInvoice(InvID, CompanyId))
      .catch((err) => {
        alert(err.msg);
      })
      .then((fileName) => {
        if (fileName) {
          setComposeEmailModalOpen(true);
          dispatch(setLoader(false));
        } else {
          dispatch(generatePrintForTransaction(InvID, CompanyId))
            .catch((err) => {
              alert(err.msg);
            })
            .then((response) => {
              dispatch(generatePdfForTransaction(response))
                .catch((err) => {
                  alert(err.msg);
                })
                .then((fileName) => {
                  dispatch(updatePDFNameInSP(InvID, fileName))
                    .catch((err) => {
                      alert(err.msg);
                    })
                    .then((response) => {
                      dispatch(initEmailInvoice(InvID, CompanyId))
                        .catch((err) => {
                          alert(err.msg);
                        })
                        .then((fileName) => {
                          setComposeEmailModalOpen(true);
                        })
                        .finally(() => {
                          dispatch(setLoader(false));
                        });
                    })
                    .finally(() => {});
                })
                .finally(() => {});
            })
            .finally(() => {});
        }
      })
      .finally(() => {});
  };

  const printInvoicePDF = (InvID) => {
    let CompanyId = isGuestUser
      ? sessionStorage.getItem("comp_id")
      : selectedTransactionCompId;
    dispatch(setLoader(true));
    dispatch(generatePrintForTransaction(InvID, CompanyId))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        dispatch(generatePdfForTransaction(response))
          .catch((err) => {
            alert(err.msg);
          })
          .then((fileName) => {
            dispatch(updatePDFNameInSP(InvID, fileName))
              .catch((err) => {
                alert(err.msg);
              })
              .then((response) => {
                saveAs(
                  `${MainAppConstants.fsPath}invoices/${fileName}`,
                  fileName
                );
              })
              .finally(() => {
                dispatch(setLoader(false));
              });
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      })
      .finally(() => {});
  };

  const cancelInvoicePage = () => {
    setTransData([]);
    clearTransactionEntryForm();
    navigate("/invoices/listInvoices", {
      replace: true,
    });
  };

  const handleOnError = (errors, e) => {
    console.log(errors);
  };

  const template = [{ id: "1", template: "Invoice Template" }];
  const parameters = {
    recipients: recipients,
    ccRecipients: ccRecipients,
    bccRecipients: bccRecipients,
    subject: subject,
    body: emailData,
    attachments: attachments,
    template: template,
    invoiceId: selectedTransactionId,
    companyId: isGuestUser
      ? sessionStorage.getItem("comp_id")
      : selectedTransactionCompId,
  };

  const cancelComposeEmailPopUp = () => {
    setComposeEmailModalOpen(false);
  };

  const changeWindowSize = (mode) => {
    if (mode == "min") {
      setMaxWidthValue(`${parseInt(maxWidthValue) - 10}px`);
    } else if (mode == "max") {
      setMaxWidthValue(`${parseInt(maxWidthValue) + 10}px`);
    }
  };

  const showEmailHistory = (InvID) => {
    dispatch(setLoader(true));
    setOpenMailHistory(false);
    dispatch(getEmailHistory(InvID)).then((response) => {
      setOpenMailHistory(true);
      dispatch(setLoader(false));
    });
  };

  const toggle = () => {
    setOpenMailHistory(false);
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>Make Your Invoice</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar title="Make Your Invoice" location={location} />
      <div className="invoice_form_container">
        <form
          className="form-horizontal invoice_line_items"
          id="invoice_line_items"
          style={{ maxWidth: maxWidthValue }}
        >
          <div className="formWrapper">
            <div className="formContent">
              <div className="form_view">
                <div className="formSheetBg pl-0 pr-0">
                  <div className="formStatusBar pl-1">
                    <div className="formStatusBarButtons">
                      <button
                        type="button"
                        variant="outlined"
                        className="btn btn-outline-save invoice-manage-button"
                        onClick={handleSubmit(
                          (formData) => saveInvoicePDF(formData),
                          handleOnError
                        )}
                      >
                        <Save
                          color="success"
                          fontSize="small"
                          className="mr-1 "
                        />
                        SAVE INVOICE
                      </button>
                      {selectedTransactionId && (
                        <button
                          type="button"
                          variant="outlined"
                          className="btn btn-outline-save invoice-manage-button"
                          onClick={() => sendInvoicePDF(selectedTransactionId)}
                        >
                          <Email
                            color="success"
                            fontSize="small"
                            className="mr-1"
                          />
                          SEND PDF
                        </button>
                      )}
                      {selectedTransactionId && (
                        <button
                          type="button"
                          variant="outlined"
                          className="btn btn-outline-save invoice-manage-button"
                          onClick={() => printInvoicePDF(selectedTransactionId)}
                        >
                          <Print
                            color="success"
                            fontSize="small"
                            className="mr-1 "
                          />
                          PRINT PDF
                        </button>
                      )}

                      {selectedTransactionId &&
                        appConstants &&
                        appConstants.guest === false && (
                          <button
                            type="button"
                            variant="outlined"
                            className="btn btn-outline-save invoice-manage-button"
                            onClick={() =>
                              showEmailHistory(selectedTransactionId)
                            }
                          >
                            <Email
                              color="success"
                              fontSize="small"
                              className="mr-1"
                            />
                            EMAIL HISTORY
                          </button>
                        )}

                      <button
                        type="button"
                        variant="outlined"
                        className="btn  btn-outline-discard  invoice-manage-button"
                        onClick={() => cancelInvoicePage()}
                      >
                        <ArrowBack
                          color="warning"
                          fontSize="small"
                          className="mr-1 "
                        />
                        GO BACK
                      </button>
                    </div>
                    <div>
                      {appConstants && appConstants.guest == true ? null : (
                        <HtmlTooltip
                          // open={true}
                          title={
                            <Fragment>
                              You can permanently set invoice window size from
                              <IconButton
                                className="ui-settings-info-button"
                                onClick={(e) =>
                                  navigate("/settings/uiSettings")
                                }
                              >
                                Open UI Settings
                              </IconButton>
                              Temporary Adjustments can be done from here. This
                              will redirect to UI settings page. All un-saved
                              data will be cleared.
                            </Fragment>
                          }
                        >
                          <IconButton className="invoice-info-button">
                            <InfoOutlined />
                          </IconButton>
                        </HtmlTooltip>
                      )}

                      <Tooltip
                        title="Maximise Invoice Window"
                        className="pointerCursor"
                      >
                        <IconButton
                          className="invoice-maximise-button"
                          onClick={(e) => changeWindowSize("max")}
                        >
                          <OpenInFull />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Minimise Invoice Window"
                        className="pointerCursor"
                      >
                        <IconButton
                          className="invoice-minimise-button"
                          onClick={(e) => changeWindowSize("min")}
                        >
                          <CloseFullscreen />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="clearfix position-relative transactionFormSheet">
                    {displayInvoiceForm()}
                    {displayInvSecForm()}
                    <table
                      id="invoice_lines_table"
                      className="table table-striped table-bordered fixed_header mt-25 invoice-lines-table"
                    >
                      {invoiceLinesHead()}
                      {invoiceLinesItems()}
                      {invoiceLinesButtons()}
                    </table>
                    {invoiceLinesSummary()}
                  </div>
                  <div className="formStatusBar pl-1">
                    <div className="formStatusBarButtons">
                      <button
                        type="button"
                        variant="outlined"
                        className="btn btn-outline-save invoice-manage-button"
                        onClick={handleSubmit(
                          (formData) => saveInvoicePDF(formData),
                          handleOnError
                        )}
                      >
                        <Save
                          color="success"
                          fontSize="small"
                          className="mr-1 "
                        />
                        SAVE INVOICE
                      </button>
                      {selectedTransactionId && (
                        <button
                          type="button"
                          variant="outlined"
                          className="btn btn-outline-save invoice-manage-button"
                          onClick={() => sendInvoicePDF(selectedTransactionId)}
                        >
                          <Email
                            color="success"
                            fontSize="small"
                            className="mr-1"
                          />
                          SEND PDF
                        </button>
                      )}
                      {selectedTransactionId && (
                        <button
                          type="button"
                          variant="outlined"
                          className="btn btn-outline-save invoice-manage-button"
                          onClick={() => printInvoicePDF(selectedTransactionId)}
                        >
                          <Print
                            color="success"
                            fontSize="small"
                            className="mr-1 "
                          />
                          PRINT PDF
                        </button>
                      )}
                      <button
                        type="button"
                        variant="outlined"
                        className="btn  btn-outline-discard  invoice-manage-button"
                        onClick={() => cancelInvoicePage()}
                      >
                        <ArrowBack
                          color="warning"
                          fontSize="small"
                          className="mr-1 "
                        />
                        GO BACK
                      </button>
                    </div>
                    <div>
                      {appConstants && appConstants.guest == true ? null : (
                        <HtmlTooltip
                          // open={true}
                          title={
                            <Fragment>
                              You can permanently set invoice window size from
                              <IconButton
                                className="ui-settings-info-button"
                                onClick={(e) =>
                                  navigate("/settings/uiSettings")
                                }
                              >
                                Open UI Settings
                              </IconButton>
                              Temporary Adjustments can be done from here. This
                              will redirect to UI settings page. All un-saved
                              data will be cleared.
                            </Fragment>
                          }
                        >
                          <IconButton className="invoice-info-button">
                            <InfoOutlined />
                          </IconButton>
                        </HtmlTooltip>
                      )}

                      <Tooltip
                        title="Maximise Invoice Window"
                        className="pointerCursor"
                      >
                        <IconButton
                          className="invoice-maximise-button"
                          onClick={(e) => changeWindowSize("max")}
                        >
                          <OpenInFull />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Minimise Invoice Window"
                        className="pointerCursor"
                      >
                        <IconButton
                          className="invoice-minimise-button"
                          onClick={(e) => changeWindowSize("min")}
                        >
                          <CloseFullscreen />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ComposeEmailModal
        type="sendInvoiceMail"
        isModalOpened={composeEmailModalOpen}
        handleCloseModal={() => cancelComposeEmailPopUp()}
        params={parameters}
        timeStamp={new Date().getTime()}
        fromFlag={"invoice"}
      />

      <Modal isOpen={openMailHistory} className="customModal" toggle={toggle}>
        <ModalHeader>Email History</ModalHeader>
        <ModalBody>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Mail To</th>
                <th>Mail CC</th>
                <th>Mail BCC</th>
                <th>Mail Send On</th>
                <th>Mail Send By</th>
              </tr>
            </thead>
            <tbody>
              {invoiceEmailHistory &&
                invoiceEmailHistory["invDetails"] &&
                invoiceEmailHistory["invDetails"].length > 0 &&
                invoiceEmailHistory["invDetails"].map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.ML_Send_To}</td>
                    <td>{detail.ML_Send_Cc}</td>
                    <td>{detail.ML_Send_Bcc}</td>
                    <td>{detail.ML_Send_On}</td>
                    <td>{detail.CON_AliasName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageInvoice;
