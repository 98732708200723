/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function (string) {
  const { t } = useTranslation();
  return t(string);
}
