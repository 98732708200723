/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { BrowserRouter } from "react-router-dom";

import "modules/themes/translations";

import { Provider } from "react-redux";
import __configureStore from "components/InitializeReducer";

// --------- To Handle drop - console logs ----------
import "./utils/dropConsole";

// ----------- Application StyleSheets -------------
import "./themes/reactifyCss";

// ----------- ROUTER -------------
import Navigation from "navigation";

const MainApp = () => {
  return (
    <Provider store={__configureStore}>
      <React.Suspense fallback="Loading...">
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
};

export default MainApp;
