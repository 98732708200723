/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Badge } from "reactstrap";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import { List, ListItem, Button } from "@mui/material";

// Page Title Bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";

import { AppTrlns, ChartConfig } from "config";

// Card & Collapsible Card
import { FixedCard, FixedCardContent } from "components/FixedCard";

import PieChart from "components/Charts/PieChart";
import BarChart from "components/Charts/BarChart";

import { setLoader } from "modules/themes/stores/themeSettings";
import { initInvoiceDashboard } from "modules/invoices/stores/invoices";

import { AppConstants as MainAppConstants } from "config";
import { GroupAddOutlined } from "@mui/icons-material";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [pieChartData, setPieChartData] = useState([0, 0]);

  const [barChartTitle, setBarChartTitle] = useState("Invoices");
  const [barChartLabel, setBarChartLabel] = useState([0, 0, 0, 0, 0]);
  const [barChartData, setBarChartData] = useState(["", "", "", "", ""]);

  const { invoiceDashboardData } = useSelector(
    (store) => store.invoices.invoices
  );
  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(initInvoiceDashboard())
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {})
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, []);

  useEffect(() => {
    if (invoiceDashboardData && invoiceDashboardData.TotalInv) {
      setPieChartData([
        parseInt(invoiceDashboardData.TotalInv.Total),
        parseInt(invoiceDashboardData.TotalInv.CurrMonth),
      ]);
    }

    if (invoiceDashboardData && invoiceDashboardData.GraphData) {
      setBarChartTitle(
        `Invoices - ${invoiceDashboardData.GraphData[0]["MonthName"]} ${invoiceDashboardData.GraphData[0]["Year"]} To ${invoiceDashboardData.GraphData[5]["MonthName"]} ${invoiceDashboardData.GraphData[5]["Year"]}`
      );
      setBarChartLabel([
        invoiceDashboardData.GraphData[0]["MonthName"],
        invoiceDashboardData.GraphData[1]["MonthName"],
        invoiceDashboardData.GraphData[2]["MonthName"],
        invoiceDashboardData.GraphData[3]["MonthName"],
        invoiceDashboardData.GraphData[4]["MonthName"],
        invoiceDashboardData.GraphData[5]["MonthName"],
      ]);
      setBarChartData([
        parseInt(invoiceDashboardData.GraphData[0]["Count"]),
        parseInt(invoiceDashboardData.GraphData[1]["Count"]),
        parseInt(invoiceDashboardData.GraphData[2]["Count"]),
        parseInt(invoiceDashboardData.GraphData[3]["Count"]),
        parseInt(invoiceDashboardData.GraphData[4]["Count"]),
        parseInt(invoiceDashboardData.GraphData[5]["Count"]),
      ]);
    }
  }, [invoiceDashboardData]);

  const pieDatasets = {
    labels: [`Total Invoices`, `This Month Invoices`],
    datasets: [
      {
        data: pieChartData,
        backgroundColor: ["rgba(75, 192, 192, 0.8)", "rgba(255, 183, 15, 0.8)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 183, 15, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartTitle = barChartTitle;
  const chartLabels = barChartLabel;
  const chartDatasets = [
    {
      label: " Invoices ",
      data: barChartData,
      borderColor: ChartConfig.color.primary,
      backgroundColor: ChartConfig.color.primary,
    },
  ];

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>Invoices Dashboard</title>
        <meta name="description" content="Invoices" />
      </Helmet>
      <PageTitleBar title="Dashboard" location={location} />

      <div className="row">
        {appConstants && appConstants.guest == true ? (
          <div className="reg-customers-info">
            <GroupAddOutlined className="reg-customers-info-icon" />
            <br />
            Only For Registered Users <br />
            <h3>
              No data will be saved for guest users. It is highly recommended to
              <br />
              use free registration in Simple Plans CRM, so that the invoice
              <br />
              template will be saved for the user.
            </h3>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="btn-xs text-white mt-30 mr-2"
              onClick={(e) => navigate("/resources/signup")}
            >
              Register Here
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="btn-xs text-white mt-30"
              onClick={(e) => navigate("/invoices/manageInvoice")}
            >
              Create Invoice
            </Button>
          </div>
        ) : null}
        <div
          className={`col-sm-6 col-md-4 w-xs-half-block ${
            appConstants && appConstants.guest == true ? "dummy-chart" : null
          }`}
        >
          <FixedCard>
            <FixedCardContent>
              <div className="clearfix">
                <div className="float-left">
                  <h3 className="mb-15 fw-semi-bold">Invoices</h3>
                </div>
              </div>
            </FixedCardContent>
            <div className="support-widget-wrap">
              <div
                className="text-center py-10 d-block mb-20"
                style={{ maxWidth: 320, margin: "auto" }}
              >
                <PieChart datasets={pieDatasets} />
              </div>
              <List className="list-unstyled p-0">
                <ListItem className="px-15 py-0 d-flex justify-content-between align-content-center">
                  <p className="mb-3 mt-3">Total Invoices</p>
                  <Badge color="warning" className="px-4">
                    {pieChartData[0]}
                  </Badge>
                </ListItem>
                <ListItem className="bg-light px-15 py-0 d-flex justify-content-between align-content-center">
                  <p className="mb-3 mt-3">This Month Invoices</p>
                  <Badge color="info" className="px-4">
                    {pieChartData[1]}
                  </Badge>
                </ListItem>
              </List>
              <div className="pb-20 pt-20 d-flex justify-content-end pr-15">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className="btn-xs text-white"
                  onClick={(e) => navigate("/invoices/listInvoices")}
                >
                  View Invoices
                </Button>
              </div>
            </div>
          </FixedCard>
        </div>

        <div
          className={`col-sm-6 col-md-8 w-xs-half-block ${
            appConstants && appConstants.guest == true ? "dummy-chart" : null
          }`}
        >
          <FixedCard>
            <FixedCardContent>
              <div className="clearfix">
                <div className="float-left">
                  <h3 className="mb-15 fw-semi-bold">Last 6 Months Invoices</h3>
                </div>
              </div>
            </FixedCardContent>
            <div className="pr-30 pl-30">
              <BarChart
                title={chartTitle}
                labels={chartLabels}
                datasets={chartDatasets}
              />
            </div>
            <br />
            <div className="pb-20 pt-20 d-flex justify-content-end pr-15">
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="btn-xs text-white"
                onClick={(e) => navigate("/invoices/listInvoices")}
              >
                View Invoices
              </Button>
            </div>
          </FixedCard>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
