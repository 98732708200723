/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import Chip from "@mui/material/Chip";

import { AppTrlns } from "config";

const NavMenuItem = ({ menu, module, onToggleMenu, onRequestMenu }) => {
  //console.log(menu);
  if (menu.child_routes != null) {
    return (
      <Fragment>
        <ListItem
          button
          component="li"
          onClick={onToggleMenu}
          className={`list-item ${classNames({ "item-active": menu.open })}`}
        >
          <ListItemIcon className="menu-icon">
            <i className={menu.menu_icon}></i>
          </ListItemIcon>
          <span className="menu text-capitalize">
            {AppTrlns(menu.menu_title)}
          </span>
          {menu.new_item && menu.new_item === true ? (
            <Chip label="new" className="new-item" color="secondary" />
          ) : (
            ""
          )}
        </ListItem>

        <Collapse in={menu.open} timeout="auto" className="sub-menu">
          <Fragment>
            <List className="list-unstyled py-0">
              {menu.child_routes.map((subMenu, index) => {
                //console.log(subMenu);
                return (
                  <ListItem button component="li" key={index}>
                    <NavLink to={subMenu.path} className="item-active">
                      <ListItemIcon className="menu-icon">
                        <i className={subMenu.menu_icon}></i>
                      </ListItemIcon>
                      <span className="menu">
                        {AppTrlns(subMenu.menu_title)}
                      </span>
                      {subMenu.new_item && subMenu.new_item === true ? (
                        <Chip
                          label="new"
                          className="new-item"
                          color="secondary"
                        />
                      ) : null}
                    </NavLink>
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        </Collapse>
      </Fragment>
    );
  }
  return (
    <ListItem button component="li">
      <NavLink className="item-active" to={menu.path} onClick={onRequestMenu}>
        <ListItemIcon className="menu-icon">
          <i className={menu.menu_icon}></i>
        </ListItemIcon>
        <span className="menu">{AppTrlns(menu.menu_title)}</span>
      </NavLink>
    </ListItem>
  );
};

export default NavMenuItem;
