/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

// app config
import { AppConfig } from "config";

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  COMPANY_SERVICES,
  USER_DETAILS,
} from ".";

const INIT_STATE = {
  user: [],
  companyServices: [],
  userDetails: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };

    case LOGOUT_USER:
      return { ...state, user: null };

    case COMPANY_SERVICES:
      return { ...state, companyServices: action.payload };

    case USER_DETAILS:
      return { ...state, userDetails: action.payload };

    default:
      return { ...state };
  }
};
