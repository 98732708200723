/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppConstants } from "modules/invoices/config";

import Dashboard from "modules/invoices/routes/";
import ListInvoices from "modules/invoices/routes/ListInvoices";
import ManageInvoice from "modules/invoices/routes/ManageInvoice";

const InvoicesNavigation = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path={AppConstants.scene.dashboard} element={<Dashboard />} />
      <Route
        path={AppConstants.scene.listInvoices}
        element={<ListInvoices />}
      />
      <Route
        path={AppConstants.scene.manageInvoice}
        element={<ManageInvoice />}
      />
      <Route
        index
        element={<Navigate to={`${pathname}/dashboard`} replace />}
      />
    </Routes>
  );
};
export default InvoicesNavigation;
