/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

// App Configuration
import { AppConfig } from "config";

const Footer = () => {
  return (
    <div className="rct-footer d-flex justify-content-between align-items-center">
      <ul className="list-inline footer-menus mb-0">
        <li className="list-inline-item">
          <Button component={Link} to="/settings/gettingStarted">
            Getting Started
          </Button>
        </li>
        <li className="list-inline-item">
          <Button component={Link} to="/settings/aboutUs">
            About Us
          </Button>
        </li>
        <li className="list-inline-item">
          <Button component={Link} to="/settings/contactUs">
            Contact Us
          </Button>
        </li>
      </ul>
      <h5 className="mb-0">{AppConfig.copyRightText}</h5>
    </div>
  );
};

export default Footer;
