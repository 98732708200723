/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/
import {
  EDITABLE_GRID_DATA,
  NDIS_PARTICIPANT_DETAILS,
  INVOICE_COMPANY_DETAILS,
  INVOICE_ACTIVE_ITEMS,
  INVOICE_PLAN_MANAGER,
  INVOICE_TAXES,
  INVOICE_UNITS,
  INVOICE_CLAIM_TYPES,
  INVOICE_TYPES,
  INVOICE_PRINT_DATA,
  INVOICE_LIST,
  ACTIVE_INVOICE_LIST,
  INVOICE_EMAIL_RECIPIENTS,
  INVOICE_EMAIL_CONTENT,
  INVOICE_EMAIL_TEMPLATE,
  INVOICE_FORM_DATA,
  INVOICE_DASHBOARD_DATA,
  INVOICE_EMAIL_HISTORY,
} from ".";

// app config
import { AppConfig } from "config";

const INIT_STATE = {
  editableGridData: [
    ["Anoop", "Test Corp", "Yonkers", "edit"],
    ["Shany", "Test Corp", "Hartford", "edit"],
    ["Geetha", "Test Corp", "Tampa", "edit"],
  ],
  ndisParticipantDetails: [],
  invoiceCompanyDetails: [],
  invoiceActiveItems: [],
  invoicePlanManager: [],
  invoiceTaxes: [],
  invoiceUnits: [],
  invoiceClaimTypes: [],
  invoiceTypes: [],
  invoicePrintData: [],
  invoiceList: [],
  activeInvoiceList: [],
  invoiceEmailRecipients: [],
  invoiceEmailContent: [],
  invoiceEmailTemplate: [],
  invoiceFormDetails: [],
  invoiceDashboardData: [],
  invoiceEmailHistory: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EDITABLE_GRID_DATA:
      return { ...state, editableGridData: action.payload };

    case NDIS_PARTICIPANT_DETAILS:
      return { ...state, ndisParticipantDetails: action.payload };
    case INVOICE_COMPANY_DETAILS:
      return { ...state, invoiceCompanyDetails: action.payload };
    case INVOICE_ACTIVE_ITEMS:
      return { ...state, invoiceActiveItems: action.payload };
    case INVOICE_PLAN_MANAGER:
      return { ...state, invoicePlanManager: action.payload };
    case INVOICE_TAXES:
      return { ...state, invoiceTaxes: action.payload };
    case INVOICE_UNITS:
      return { ...state, invoiceUnits: action.payload };
    case INVOICE_CLAIM_TYPES:
      return { ...state, invoiceClaimTypes: action.payload };
    case INVOICE_TYPES:
      return { ...state, invoiceTypes: action.payload };
    case INVOICE_PRINT_DATA:
      return { ...state, invoicePrintData: action.payload };
    case INVOICE_LIST:
      return { ...state, invoiceList: action.payload };
    case ACTIVE_INVOICE_LIST:
      return { ...state, activeInvoiceList: action.payload };
    case INVOICE_EMAIL_RECIPIENTS:
      return { ...state, invoiceEmailRecipients: action.payload };
    case INVOICE_EMAIL_CONTENT:
      return { ...state, invoiceEmailContent: action.payload };
    case INVOICE_EMAIL_TEMPLATE:
      return { ...state, invoiceEmailTemplate: action.payload };
    case INVOICE_FORM_DATA:
      return { ...state, invoiceFormDetails: action.payload };
    case INVOICE_DASHBOARD_DATA:
      return { ...state, invoiceDashboardData: action.payload };
    case INVOICE_EMAIL_HISTORY:
      return { ...state, invoiceEmailHistory: action.payload };

    default:
      return { ...state };
  }
};
