/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import TextTranslation from "components/TextTranslation/TextTranslation";
import { AppTrlns, ChartConfig } from "config";
import FieldError from "components/FieldError";
import { setLoader } from "modules/themes/stores/themeSettings";
import { TextField, Button } from "@mui/material";

import { postContactUs } from "modules/settings/stores/settings";

const ContactUs = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contact_email: null,
      contact_message: null,
      contact_name: null,
      contact_phone: null,
      contact_subject: null,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });
  };

  const handleOnError = () => {};

  const handleOnSubmit = (form) => {
    dispatch(setLoader(true));
    dispatch(postContactUs(form))
      .catch((err) => {
        alert(err.msg); // eslint-disable-line no-alert
      })
      .then(() => {
        dispatch(setLoader(false));
        reset();
        clearErrors();
        NotificationManager.success(
          <TextTranslation
            text={"settings:ContactUs.Notifications.contactUsSaved"}
          />
        );
      })
      .finally(() => {});
  };

  const cancelContactForm = () => {
    reset();
    clearErrors();
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("settings:ContactUs.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:ContactUs.sceneTitle")}
        location={location}
      />
      <div className="container-fluid mb-50">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 mb-3">
                <TextField
                  size="small"
                  label={
                    <TextTranslation
                      text={"settings:ContactUs.formLabels.contactName"}
                    />
                  }
                  variant="outlined"
                  fullWidth
                  error={errors?.invoiceBoxWidth ? true : false}
                  {...register("contact_name", {
                    required: true,
                  })}
                  onChange={(e) => handleChange(e)}
                />
                {errors?.contact_name?.type === "required" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.required"}
                      />
                    }
                  />
                )}
              </div>
              <div className="col-md-4 mb-3">
                <TextField
                  size="small"
                  label={
                    <TextTranslation
                      text={"settings:ContactUs.formLabels.contactEmail"}
                    />
                  }
                  variant="outlined"
                  fullWidth
                  error={errors?.contact_email ? true : false}
                  {...register("contact_email", {
                    required: true,
                    pattern: /\S+@\S+\.\S+/,
                  })}
                  onChange={(e) => handleChange(e)}
                />
                {errors?.contact_email?.type === "required" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.required"}
                      />
                    }
                  />
                )}
                {errors?.contact_email?.type === "pattern" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.invalidEmail"}
                      />
                    }
                  />
                )}
              </div>
              <div className="col-md-4 mb-3">
                <TextField
                  size="small"
                  label={
                    <TextTranslation
                      text={"settings:ContactUs.formLabels.contactPhone"}
                    />
                  }
                  variant="outlined"
                  fullWidth
                  error={errors?.contact_phone ? true : false}
                  {...register("contact_phone", {
                    required: true,
                  })}
                  onChange={(e) => handleChange(e)}
                />
                {errors?.contact_phone?.type === "required" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.required"}
                      />
                    }
                  />
                )}
              </div>
              <div className="col-md-12 mb-3">
                <TextField
                  size="small"
                  label={
                    <TextTranslation
                      text={"settings:ContactUs.formLabels.contactSubject"}
                    />
                  }
                  variant="outlined"
                  fullWidth
                  error={errors?.contact_subject ? true : false}
                  {...register("contact_subject", {
                    required: true,
                  })}
                  onChange={(e) => handleChange(e)}
                />
                {errors?.contact_subject?.type === "required" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.required"}
                      />
                    }
                  />
                )}
              </div>
              <div className="col-md-12 mb-3">
                <TextField
                  multiline
                  rows={5}
                  size="small"
                  label={
                    <TextTranslation
                      text={"settings:ContactUs.formLabels.contactMessage"}
                    />
                  }
                  variant="outlined"
                  fullWidth
                  error={errors?.contact_message ? true : false}
                  {...register("contact_message", {
                    required: true,
                  })}
                  onChange={(e) => handleChange(e)}
                />
                {errors?.contact_message?.type === "required" && (
                  <FieldError
                    errorText={
                      <TextTranslation
                        text={"settings:ContactUs.errorText.required"}
                      />
                    }
                  />
                )}
              </div>

              <div className="col-md-12 form-group mt-50 mb-3 d-flex justify-content-end">
                <Button
                  className="h-100 mr-20"
                  color="warning"
                  variant="contained"
                  onClick={() => cancelContactForm()}
                >
                  <TextTranslation
                    text={"settings:ContactUs.buttonText.CancelContactUs"}
                  />
                </Button>
                <Button
                  className="h-100"
                  color="success"
                  variant="contained"
                  onClick={handleSubmit(handleOnSubmit, handleOnError)}
                >
                  <TextTranslation
                    text={"settings:ContactUs.buttonText.SaveContactUs"}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
