/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import { AppTrlns } from "config";

const TextTranslation = ({ text }) => {
  return AppTrlns(text);
};
export default TextTranslation;
