/**
 * Simple Plans Portal
 *
 * @author      Anu Abraham
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppTrlns } from "config";

import { Helmet } from "react-helmet";
import PageTitleBar from "components/PageTitleBar/PageTitleBar";
import Datatable from "components/DataTable";

import BasicDatePicker from "components/DatePicker/DatePicker";

import { setLoader } from "modules/themes/stores/themeSettings";
import {
  initListInvoices,
  generatePrintForTransaction,
  generatePdfForTransaction,
  updatePDFNameInSP,
  initEmailInvoice,
  exportInvoicesToCSV,
  getEmailHistory,
  deleteUserInvoices,
  updateCompany,
} from "modules/invoices/stores/invoices";
import { logout } from "modules/resources/stores/resources";

import { NotificationManager } from "react-notifications";
import {
  DownloadRounded,
  Edit,
  GroupAddOutlined,
  Mail,
  PanoramaFishEyeOutlined,
  Search,
  Visibility,
  DeleteForever,
} from "@mui/icons-material";
import {
  Tooltip,
  Box,
  Button,
  FormControl,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";

import { AppConstants as MainAppConstants } from "config";

import { saveAs } from "file-saver";

import ComposeEmailModal from "modules/settings/routes/ComposeEmailModal";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog/DeleteConfirmationDialog";

import { CurrencyConverter, formatDate } from "utils/pivot";

import { CSVLink, CSVDownload } from "react-csv";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ListInvoices = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const csvLink = useRef();

  const [count, setCount] = useState(1);
  const [listPage, setListPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [initDataTable, setInitDataTable] = useState(true);

  const [composeEmailModalOpen, setComposeEmailModalOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [ccRecipients, setCCRecipients] = useState([]);
  const [bccRecipients, setBCCRecipients] = useState([]);

  const [subject, setSubject] = useState("");
  const [emailData, setEmailData] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [invoicePdfFileName, setInvoicePdfFileName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null);

  const [selectedTransactionId, setSelectedTransactionId] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [masterCBStatus, setMasterCBStatus] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [csvFileName, setCSVFileName] = useState(
    `CSV-${new Date().toISOString().split("T")[0]}`
  );
  const [CSVData, setCSVData] = useState([]);

  const [openMailHistory, setOpenMailHistory] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(0);
  const [deleteInvoices, setDeleteInvoices] = useState([]);

  const [dummy, setDummy] = useState("");

  const {
    invoiceList,
    activeInvoiceList,
    ndisParticipantDetails,
    invoicePlanManager,
    invoiceEmailRecipients,
    invoiceEmailContent,
    invoiceEmailTemplate,
    invoiceCompanyDetails,
    invoiceEmailHistory,
  } = useSelector((store) => store.invoices.invoices);

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedFromDate = formatDate(firstDayOfMonth, "YYYY-MM-DD");

    const formattedToDate = formatDate(today, "YYYY-MM-DD");

    setFromDate(formattedFromDate);
    setToDate(formattedToDate);
  }, []);

  useEffect(() => {
    if (selectedCompanyId && fromDate && toDate) {
      dispatch(setLoader(true));
      dispatch(
        initListInvoices(
          0,
          10,
          null,
          [],
          {},
          selectedCompanyId,
          fromDate,
          toDate
        )
      )
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {
          setCount(response);
          setSelectedInvoices([]);
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  }, [selectedCompanyId, fromDate, toDate]);

  useEffect(() => {
    if (
      !selectedCompanyId &&
      (appConstants || sessionStorage.getItem("inv_comp_id"))
    ) {
      let companyId = sessionStorage.getItem("inv_comp_id")
        ? sessionStorage.getItem("inv_comp_id")
        : appConstants["CMP_Id"];
      setSelectedCompanyId(companyId);

      const invoiceCompanyIndex = invoiceCompanyDetails.findIndex(
        (p) => p.CMP_Id.toString() === companyId.toString()
      );

      if (invoiceCompanyIndex > -1) {
        console.log("im here 1_______________");
        setSelectedCompanyOption(invoiceCompanyDetails[invoiceCompanyIndex]);
      }
    }
  }, [appConstants, invoiceCompanyDetails]);

  useEffect(() => {
    if (
      selectedCompanyId &&
      invoiceCompanyDetails &&
      Object.keys(invoiceCompanyDetails).length > 0
    ) {
      // const invoiceCompanyIndex = invoiceCompanyDetails.findIndex(
      //   (p) => p.CMP_Id.toString() === selectedCompanyId.toString()
      // );

      // if (invoiceCompanyIndex > -1) {
      //   console.log("im here 2 _______________");
      //   setSelectedCompanyOption(invoiceCompanyDetails[invoiceCompanyIndex]);
      // }
      const filteredCompanyDetails = invoiceCompanyDetails.filter(
        (company) => company.CMP_Select === "select"
      );
      if (filteredCompanyDetails.length > 0) {
        setSelectedCompanyOption(filteredCompanyDetails[0]);
      }
      // console.log(filteredCompanyDetails);
    }
  }, [selectedCompanyId, invoiceCompanyDetails]);

  useEffect(() => {
    if (dataLoad) {
      dispatch(setLoader(true));
      dispatch(
        initListInvoices(
          listPage,
          rowsPerPage,
          searchText,
          filterList,
          sortOrder,
          selectedCompanyId,
          fromDate,
          toDate
        )
      )
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {
          setDataLoad(false);
          setCount(response);
          setSelectedInvoices([]);
        })
        .finally(() => {
          setInitDataTable(false);
          dispatch(setLoader(false));
        });
    }
  }, [dataLoad]);

  useEffect(() => {
    if (
      invoiceEmailRecipients &&
      Object.keys(invoiceEmailRecipients).length > 0
    ) {
      setRecipients(invoiceEmailRecipients["INV_ToMails"]);
      setCCRecipients(invoiceEmailRecipients["INV_CcMails"]);
      setBCCRecipients(invoiceEmailRecipients["INV_BccMails"]);
    }
  }, [invoiceEmailRecipients]);

  useEffect(() => {
    if (
      invoiceEmailContent &&
      Object.keys(invoiceEmailContent).length > 0 &&
      invoiceEmailTemplate &&
      Object.keys(invoiceEmailTemplate).length > 0 > 0
    ) {
      let invoiceVariableData = {
        "{cmp_logo}": invoiceEmailContent["LogoPath"] ?? "",
        "{cmp_name}": invoiceEmailContent["Company"],
        "{cmp_abn}": invoiceEmailContent["ABN"],
        "{doc_amt}": invoiceEmailContent["Total"],
        "{doc_date}": invoiceEmailContent["INV_Date"],
        "{doc_no}": invoiceEmailContent["INVPrefix"],
        "{user_part_name}": invoiceEmailContent["NDIS_Name"],
        "{adt_cnt}": "",
        "{mail_footer}": invoiceEmailContent["Footer"],
      };

      setEmailData(
        replacePreviewHtml(
          invoiceEmailTemplate[0]["SET_Content"],
          invoiceVariableData
        )
      );
      // console.log("invoiceEmailContent____________", invoiceEmailContent);

      let attachmentData = [];
      if (invoiceEmailContent["PdfFile"]) {
        attachmentData.push({
          filename: invoiceEmailContent["PdfFile"],
          path: `${MainAppConstants.fsPath}invoices/${invoiceEmailContent["PdfFile"]}`,
        });
      }

      // console.log("attachmentData___________", attachmentData);
      setAttachments(attachmentData);

      setInvoicePdfFileName(invoiceEmailContent["PdfFile"]);

      setSubject(invoiceEmailContent["Email_subject"]);
    }
  }, [invoiceEmailContent, invoiceEmailTemplate]);

  const handleChangeDate = (data) => {
    const { name, value, pickerID } = data.target;

    if (data["target"]["pickerID"] == 1) {
      setFromDate(value);
    } else if (data["target"]["pickerID"] == 2) {
      setToDate(value);
    }
  };

  const replacePreviewHtml = (text, dummyData) => {
    let replacedText = text;
    Object.keys(dummyData).forEach(function (key) {
      replacedText = replacedText.replaceAll(key, dummyData[key]);
    });
    return replacedText;
  };

  const masterCheckboxClick = (e) => {
    setMasterCBStatus(e.target.checked);
    if (e.target.checked) {
      invoiceList.map((itmvalue, i) => {
        if (selectedInvoices.indexOf(itmvalue.InvID) == -1) {
          selectedInvoices.push(itmvalue.InvID);
        }
      });
    } else {
      invoiceList.map((itmvalue, i) => {
        if (selectedInvoices.indexOf(itmvalue.InvID) > -1) {
          let itemIndex = selectedInvoices.indexOf(itmvalue.InvID);
          selectedInvoices.splice(itemIndex, 1);
        }
      });
    }
    setSelectedInvoices(selectedInvoices);
  };

  const setRowSelected = (e, InvID) => {
    if (selectedInvoices.indexOf(InvID) == -1) {
      selectedInvoices.push(InvID);
    } else {
      let itemIndex = selectedInvoices.indexOf(InvID);
      selectedInvoices.splice(itemIndex, 1);
    }
    setSelectedInvoices(selectedInvoices);
    setTimeout(() => {
      setDummy(Date.now());
    }, 200);
  };

  const invoiceColumns = [
    {
      name: <Checkbox onClick={(e) => masterCheckboxClick(e)} />,
      style: {
        maxWidth: "20%",
        textAlign: "center",
      },
    },
    {
      name: "#",
      style: {
        maxWidth: "20%",
        textAlign: "center",
      },
    },
    {
      name: "Company Name",
      style: {
        minWidth: "40%",
        textAlign: "left",
      },
      sort: false,
    },
    {
      name: "Invoice #",
      style: {
        minWidth: "40%",
        textAlign: "left",
      },
      sort: false,
      filter: Array.isArray(activeInvoiceList)
        ? activeInvoiceList.map((el) => {
            return el.InvPrefix;
          })
        : null,
    },
    {
      name: "NDIS Participant",
      style: {
        textAlign: "left",
      },
      sort: false,
      filter: Array.isArray(ndisParticipantDetails)
        ? ndisParticipantDetails.map((el) => {
            return el.NDIS_Name;
          })
        : null,
    },
    {
      name: "Bill To",
      style: {
        textAlign: "left",
      },
      sort: false,
      filter: Array.isArray(invoicePlanManager)
        ? invoicePlanManager.map((el) => {
            return el.PM_Name;
          })
        : null,
    },
    {
      name: "Invoice Date",
      style: {
        textAlign: "left",
      },
      sort: false,
    },
    {
      name: "Support Start Date",
      style: {
        textAlign: "left",
      },
      sort: false,
    },
    {
      name: "Support End Date",
      style: {
        textAlign: "left",
      },
      sort: false,
    },

    {
      name: "Total Amount (In AUD)",
      style: {
        textAlign: "right",
      },
      sort: false,
    },
    {
      name: "Edit",
      style: {
        textAlign: "center",
      },
      sort: false,
    },
    {
      name: "Send Email",
      style: {
        textAlign: "center",
      },
      sort: false,
    },
    {
      name: "Email History",
      style: {
        textAlign: "center",
      },
      sort: false,
    },
    {
      name: "Download",
      style: {
        textAlign: "center",
      },
      sort: false,
    },
    {
      name: "Delete",
      style: {
        textAlign: "center",
      },
      sort: false,
    },
  ];

  const renderTableData = () => {
    let dataTableOBJ = [];
    invoiceList &&
      invoiceList.map((value, i) => {
        // console.log(value);
        dataTableOBJ.push([
          <Checkbox
            checked={selectedInvoices.indexOf(value.InvID) == -1 ? false : true}
            onClick={(e) => setRowSelected(e, value.InvID)}
          />,
          listPage * rowsPerPage + i + 1,
          value.Company,
          value.InvPrefix,
          value.INV_CreatedFor,
          value.INV_Prov,
          value.INV_Date,
          value.INV_StartDate,
          value.INV_EndDate,
          value.INV_TotalAmt,
          <Tooltip title="Edit Invoice" className="pointerCursor">
            <Edit
              color="success"
              onClick={() => editInvoiceData(value.InvID, selectedCompanyId)}
            />
          </Tooltip>,
          <Tooltip
            title="Send Mail"
            className="pointerCursor position-relative"
          >
            <Mail
              color="success"
              onClick={() => emailInvoicePDF(value.InvID, selectedCompanyId)}
            />
            <div className="mail-count-badge">{value.MailCount}</div>
          </Tooltip>,
          <Tooltip
            title="Mail History"
            className="pointerCursor position-relative"
          >
            <Visibility
              color="success"
              onClick={() => showMailHistory(value.InvID, selectedCompanyId)}
            />
          </Tooltip>,
          <Tooltip title="Download PDF" className="pointerCursor">
            <DownloadRounded
              color="success"
              onClick={() =>
                downloadInvoicePDF(value.InvID, value.InvPDF, selectedCompanyId)
              }
            />
          </Tooltip>,
          <Tooltip title="Delete" className="pointerCursor">
            <DeleteForever
              color="success"
              onClick={() => deleteInvoice(value.InvID, selectedCompanyId)}
            />
          </Tooltip>,
        ]);
      });
    return dataTableOBJ;
  };

  const editInvoiceData = (InvID, CompanyId) => {
    navigate("/invoices/manageInvoice", {
      state: {
        transaction_id: InvID ?? "",
        CompanyId: CompanyId,
      },
      replace: true,
    });
  };

  const showMailHistory = (InvID, CompanyId) => {
    dispatch(setLoader(true));
    setOpenMailHistory(false);
    dispatch(getEmailHistory(InvID)).then((response) => {
      setOpenMailHistory(true);
      dispatch(setLoader(false));
    });
  };

  const toggle = () => {
    setOpenMailHistory(false);
  };

  const downloadInvoicePDF = (InvID, InvPDF, CompanyId) => {
    if (InvPDF) {
      saveAs(`${MainAppConstants.fsPath}invoices/${InvPDF}`, InvPDF);
    } else {
      dispatch(setLoader(true));
      dispatch(generatePrintForTransaction(InvID, CompanyId))
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {
          dispatch(generatePdfForTransaction(response))
            .catch((err) => {
              alert(err.msg);
            })
            .then((fileName) => {
              dispatch(updatePDFNameInSP(InvID, fileName))
                .catch((err) => {
                  alert(err.msg);
                })
                .then((response) => {
                  saveAs(
                    `${MainAppConstants.fsPath}invoices/${fileName}`,
                    fileName
                  );
                })
                .finally(() => {
                  dispatch(setLoader(false));
                });
            })
            .finally(() => {
              dispatch(setLoader(false));
            });
        })
        .finally(() => {});
    }
  };

  const deleteInvoice = (InvID, CompanyId) => {
    let deleteIDs;
    if (InvID) {
      deleteIDs = InvID.split(",");
    } else {
      deleteIDs = selectedInvoices;
    }
    setDeleteInvoices(deleteIDs);
    setDeleteDialog(true);
  };

  const confirmDelete = () => {
    setDeleteInvoices([]);
    setDeleteDialog(false);

    dispatch(setLoader(true));
    dispatch(deleteUserInvoices(deleteInvoices))
      .catch((err) => {
        alert(err.msg);
      })
      .then((response) => {
        dispatch(setLoader(false));
        searchTransactions();
        NotificationManager.success("Invoices Deleted Successfully");
      })
      .finally(() => {});
  };

  const confirmCancel = () => {
    setDeleteInvoices([]);
    setDeleteDialog(false);
  };

  const emailInvoice = () => {
    console.log(selectedInvoices);
  };

  const emailInvoicePDF = (InvID, selectedCompanyId) => {
    setSelectedTransactionId(InvID);
    dispatch(setLoader(true));
    dispatch(initEmailInvoice(InvID, selectedCompanyId))
      .catch((err) => {
        alert(err.msg);
      })
      .then((fileName) => {
        if (fileName) {
          setComposeEmailModalOpen(true);
          dispatch(setLoader(false));
        } else {
          dispatch(generatePrintForTransaction(InvID, selectedCompanyId))
            .catch((err) => {
              alert(err.msg);
            })
            .then((response) => {
              dispatch(generatePdfForTransaction(response))
                .catch((err) => {
                  alert(err.msg);
                })
                .then((fileName) => {
                  dispatch(updatePDFNameInSP(InvID, fileName))
                    .catch((err) => {
                      alert(err.msg);
                    })
                    .then((response) => {
                      dispatch(initEmailInvoice(InvID, selectedCompanyId))
                        .catch((err) => {
                          alert(err.msg);
                        })
                        .then((fileName) => {
                          setComposeEmailModalOpen(true);
                        })
                        .finally(() => {
                          dispatch(setLoader(false));
                        });
                    })
                    .finally(() => {});
                })
                .finally(() => {});
            })
            .finally(() => {});
        }
      })
      .finally(() => {});
  };

  const cancelComposeEmailPopUp = () => {
    setComposeEmailModalOpen(false);
  };

  const template = [{ id: "1", template: "Invoice Template" }];
  const parameters = {
    recipients: recipients,
    ccRecipients: ccRecipients,
    bccRecipients: bccRecipients,
    subject: subject,
    body: emailData,
    attachments: attachments,
    template: template,
    invoiceId: selectedTransactionId,
    companyId: selectedCompanyId,
  };

  const handleFilter = (
    listPage,
    rowsPerPage,
    searchText,
    filterList,
    sortOrder
  ) => {
    setListPage(listPage);
    setRowsPerPage(rowsPerPage);
    dispatch(
      initListInvoices(
        listPage,
        rowsPerPage,
        searchText,
        filterList,
        sortOrder,
        selectedCompanyId,
        fromDate,
        toDate
      )
    ).then((response) => {
      setCount(response);
      // setSelectedInvoices([]);
    });
  };

  const searchTransactions = () => {
    setListPage(0);
    setRowsPerPage(10);
    setSearchText(null);
    setFilterList([]);
    setSortOrder({});

    setInitDataTable(true);
    setDataLoad(true);
  };

  const exportToCSV = () => {
    var regexp = /^[a-zA-Z0-9-_]+$/;
    if (csvFileName.search(regexp) === -1) {
      NotificationManager.warning(
        "Invalid File Name. Alphabets, Digits, Hyphen & Underscore is Allowed"
      );
    } else {
      dispatch(setLoader(true));
      dispatch(exportInvoicesToCSV(selectedInvoices, csvFileName))
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {
          let csv_Data = [];
          csv_Data = [response.xlHeader].concat(response.xlData);
          setCSVData(csv_Data);
          csvLink.current.link.click();

          setSelectedInvoices([]);
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  };

  return (
    <div className="ecom-dashboard-wrapper container-fluid">
      <Helmet>
        <title>List Invoices</title>
      </Helmet>
      <PageTitleBar title="List Invoices" location={location} />

      {appConstants && appConstants.guest == true ? (
        <div className="reg-customers-info">
          <GroupAddOutlined className="reg-customers-info-icon" />
          <br />
          Only For Registered Users <br />
          <h3>
            No data will be saved for guest users. It is highly recommended to
            <br />
            use free registration in Simple Plans CRM, so that the invoice
            <br />
            template will be saved for the user.
          </h3>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="btn-xs text-white mt-30 mr-2"
            onClick={(e) => {
              dispatch(setLoader(true));
              dispatch(logout("redirectSignUp"))
                .catch((err) => {})
                .then(() => {
                  dispatch(setLoader(false));
                })
                .finally(() => {});
            }}
          >
            Register Here
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="btn-xs text-white mt-30"
            onClick={(e) => navigate("/invoices/manageInvoice")}
          >
            Create Invoice
          </Button>
        </div>
      ) : null}
      <div
        className={`${
          appConstants && appConstants.guest == true ? "dummy-chart" : null
        }`}
      >
        <div className="w-100 bg-white mt-4 p-3 d-flex border">
          <Box className="d-flex" sx={{ m: 1 }}>
            <Box
              spacing={3}
              sx={{ flexDirection: "row", width: "100%", mr: 2 }}
            >
              <BasicDatePicker
                id="RCFY_StartDate"
                name="RCFY_StartDate"
                label="From"
                format="dd-MM-yyyy"
                handleChange={handleChangeDate}
                value={fromDate}
                pickerID={1}
                variant="outlined"
              />
            </Box>
            <Box
              spacing={3}
              sx={{ flexDirection: "row", width: "100%", mr: 2 }}
            >
              <BasicDatePicker
                id="RCFY_EndDate"
                name="RCFY_EndDate"
                label="To"
                format="dd-MM-yyyy"
                handleChange={handleChangeDate}
                value={toDate}
                pickerID={2}
                variant="outlined"
              />
            </Box>
            <Box
              spacing={3}
              sx={{ flexDirection: "row", width: "100%", mr: 2 }}
            >
              <FormControl sx={{ width: "100%" }}>
                {console.log(
                  "invoiceCompanyDetails___________",
                  invoiceCompanyDetails
                )}
                <Autocomplete
                  disableClearable
                  options={invoiceCompanyDetails || []}
                  getOptionLabel={(option) =>
                    option && option.CMP_Name ? option.CMP_Name : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option &&
                    value &&
                    option.CMP_Id &&
                    value.CMP_Id &&
                    option.CMP_Id.toString() === value.CMP_Id.toString()
                  }
                  onChange={(e, options) => {
                    if (options && options.CMP_Id) {
                      dispatch(updateCompany(options?.CMP_Id));
                      setSelectedCompanyId(options.CMP_Id);
                      setSelectedCompanyOption(options);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        "data-label": "Select Company",
                      }}
                      variant="outlined"
                      label="Select Company"
                      placeholder="Select Company"
                      fullWidth
                    />
                  )}
                  value={selectedCompanyOption}
                />
              </FormControl>
            </Box>
            <Button
              size="small"
              className="pl-40 pr-40"
              variant="contained"
              color="success"
              startIcon={<Search />}
              onClick={(e) => searchTransactions()}
            >
              Search
            </Button>
          </Box>
        </div>
        {selectedInvoices.length > 0 ? (
          <div className="w-100 bg-white mt-4 p-3 d-flex border">
            <span className="mt-5">
              Total Invoices Selected : {selectedInvoices.length} no(s)
            </span>
            <TextField
              label="CSV File Name"
              variant="outlined"
              className="ml-50"
              size="small"
              value={csvFileName}
              onChange={(e) => setCSVFileName(e.target.value)}
              // helperText="Only Alphabets, Digits and Underscore is Allowed"
            />
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="btn-xs text-white ml-50"
              onClick={(e) => exportToCSV()}
            >
              Export To CSV
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              className="btn-xs text-white ml-30"
              onClick={(e) => deleteInvoice()}
            >
              Delete Invoices
            </Button>
            {/* <Button
              size="small"
              variant="contained"
              color="secondary"
              className="btn-xs text-white ml-30"
              onClick={(e) => emailInvoice()}
            >
              Email Invoices
            </Button> */}
          </div>
        ) : null}

        <div className="row mb-4 mt-4">
          <div className="col-md-12" id="postedTransactions">
            <Datatable
              title="List Invoices"
              column={invoiceColumns}
              createNewDatatTable={() =>
                navigate("/invoices/manageInvoice", {
                  state: {},
                  replace: true,
                })
              }
              data={renderTableData()}
              handleFilter={handleFilter}
              totalCount={count}
            />
          </div>
        </div>

        <CSVLink
          data={CSVData}
          filename={`${csvFileName}.csv`}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      </div>

      <ComposeEmailModal
        type="sendInvoiceMail"
        isModalOpened={composeEmailModalOpen}
        handleCloseModal={() => cancelComposeEmailPopUp()}
        params={parameters}
        timeStamp={new Date().getTime()}
        fromFlag={"invoice"}
      />

      <Modal isOpen={openMailHistory} className="customModal" toggle={toggle}>
        <ModalHeader>Email History</ModalHeader>
        <ModalBody>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Mail To</th>
                <th>Mail CC</th>
                <th>Mail BCC</th>
                <th>Mail Send On</th>
                <th>Mail Send By</th>
              </tr>
            </thead>
            <tbody>
              {invoiceEmailHistory &&
                invoiceEmailHistory["invDetails"] &&
                invoiceEmailHistory["invDetails"].length > 0 &&
                invoiceEmailHistory["invDetails"].map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.ML_Send_To}</td>
                    <td>{detail.ML_Send_Cc}</td>
                    <td>{detail.ML_Send_Bcc}</td>
                    <td>{detail.ML_Send_On}</td>
                    <td>{detail.CON_AliasName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
      {deleteDialog ? (
        <DeleteConfirmationDialog
          title="Confirm Delete Invoices"
          message={`Are You Sure Want To Delete ${deleteInvoices.length} Entries? This Cannot Be Undone.`}
          onConfirm={() => confirmDelete()}
          onCancel={() => confirmCancel()}
        />
      ) : null}
    </div>
  );
};
export default ListInvoices;
