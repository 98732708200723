/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React, { Fragment } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

// themes
import primaryTheme from "./ThemeSelector/primaryTheme";
import darkTheme from "./ThemeSelector/darkTheme";
import secondaryTheme from "./ThemeSelector/secondaryTheme";
import warningTheme from "./ThemeSelector/warningTheme";
import dangerTheme from "./ThemeSelector/dangerTheme";
import infoTheme from "./ThemeSelector/infoTheme";
import successTheme from "./ThemeSelector/successTheme";

const ThemeVendor = ({ children }) => {
  const storeSettings = useSelector((store) => store.themes.themeSettings);
  const { locale, darkMode, rtlLayout, activeTheme } = storeSettings;

  // --------- Manage Theme ------------
  let theme = "";
  switch (activeTheme.id) {
    case 1:
      theme = primaryTheme;
      break;
    case 2:
      theme = secondaryTheme;
      break;
    case 3:
      theme = warningTheme;
      break;
    case 4:
      theme = infoTheme;
      break;
    case 5:
      theme = dangerTheme;
      break;
    case 6:
      theme = successTheme;
      break;
    default:
      break;
  }

  if (darkMode) {
    theme = darkTheme;
  }

  if (rtlLayout) {
    theme.direction = "rtl";
  } else {
    theme.direction = "ltr";
  }

  if (locale != i18next.language) {
    i18next.init({
      lng: locale,
    });
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: theme.direction == "rtl" ? [rtlPlugin] : null,
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <Fragment>{children}</Fragment>
        </I18nextProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default ThemeVendor;
