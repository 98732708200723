// import i18next from "i18next";
// import { initReactI18next } from "react-i18next";

// import TRANSLATIONS_EN from "./en.json";
// import TRANSLATIONS_AR from "./ar.json";

// i18next
//   .use(initReactI18next)
//   .addResourceBundle("en", "settings", TRANSLATIONS_EN);
// i18next
//   .use(initReactI18next)
//   .addResourceBundle("ar", "settings", TRANSLATIONS_AR);

// export default i18next;

/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import TRANSLATIONS_EN from "./en.json";

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
};
i18next.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  ns: ["translation", "module"],
  defaultNS: "translation",
});
export default i18next;
