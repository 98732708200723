/**
 * Rct Card
 */
import React from "react";

// rct card heading
import { FixedCardHeading } from "./FixedCardHeading";

const FixedCard = ({
  children,
  customClasses,
  heading,
  headingCustomClasses,
  colClasses,
}) => (
  <div className={colClasses && colClasses}>
    <div className={`rct-block ${customClasses ? customClasses : ""}`}>
      {heading && (
        <FixedCardHeading
          title={heading}
          customClasses={headingCustomClasses}
        />
      )}
      {children}
    </div>
  </div>
);

export { FixedCard };
