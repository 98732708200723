/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const FullPageLoader = () => (
  <div className="loader-container">
    <div className="loader">
      <CircularProgress />
    </div>
  </div>
);

export default FullPageLoader;
