/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { createTheme } from "@mui/material/styles";
import { AppConfig } from "config";

const theme = createTheme({
  palette: {
    primary: {
      main: AppConfig.themeColors.danger,
    },
    secondary: {
      main: AppConfig.themeColors.primary,
    },
  },
});

export default theme;
