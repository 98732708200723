/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import {
  SET_MODULES_LIST_DATA,
  SET_MENU_LIST_DATA,
  SET_MENU_LIST_DUMMY,
  ACL_LIST,
  TEMPLATE_LIST,
  EMAIL_TEMPLATE_LIST,
  USER_UI_SETTINGS,
  COMPANY_SETTINGS,
} from ".";

import { INVOICE_COMPANY_DETAILS } from "modules/invoices/stores/invoices/index";

import {
  __signIn,
  __logout,
  __getModuleMenuList,
  __updateModuleMenuList,
  __getACLList,
  __initACL,
  __updateACL,
  __getACLData,
  __getTemplates,
  __getTemplatesData,
  __updateTemplatesData,
  __getEmailTemplates,
  __getEmailTemplatesData,
  __updateEmailTemplatesData,
  __initUISettings,
  __updateUISettings,
  __sendEmail,
  __postContactUs,
  __initCompanySettings,
  __updateCompanySettings,
} from "./modules";

import { batch } from "react-redux";

//----------------------- Get All Menu Data For Manage Menu ------------------------
export const getModuleMenuList = () => (dispatch) => {
  return __getModuleMenuList().then((response) => {
    batch(() => {
      let setModuleListData = [];
      let setMenuListData = [];
      response &&
        response.map((value, i) => {
          if (setModuleListData.indexOf(value.Module_Menu) === -1) {
            setModuleListData.push(value.Module_Menu);
            setMenuListData[value.Module_Menu] = [];
          }
          setMenuListData[value.Module_Menu].push(value);
        });
      dispatch({
        type: SET_MODULES_LIST_DATA,
        payload: setModuleListData,
      });

      dispatch({
        type: SET_MENU_LIST_DATA,
        payload: setMenuListData,
      });

      let menuJSON = JSON.stringify(Object.entries(setMenuListData));
      //console.log(convArray, Object.fromEntries(JSON.parse(convArray)));

      dispatch({
        type: SET_MENU_LIST_DUMMY,
        payload: menuJSON,
      });
    });
  });
};

//----------------------- Update Menu To Reducer ------------------------
export const manipulateMenuListData = (menuListData) => (dispatch) => {
  dispatch({
    type: SET_MENU_LIST_DATA,
    payload: menuListData,
  });
};

//----------------------- Update Menu Props ------------------------
export const updateModuleMenuList = (menuData, module) => (dispatch) => {
  return __updateModuleMenuList(menuData, module).then((response) => {});
};

//----------------------- Get ACL List---------------------
export const getACLList = () => (dispatch) => {
  return __getACLList().then((response) => {
    let dataTableOBJ = [];
    response &&
      response.map((value, i) => {
        dataTableOBJ.push([
          value.SACL_Name,
          value.CMP_Name,
          value.SACL_Status,
          value.SACL_Id,
          value.SACL_Id,
        ]);
      });
    dispatch({
      type: ACL_LIST,
      payload: dataTableOBJ,
    });
  });
};

//----------------------- Initialize ACL  ------------------------
export const initACL = () => (dispatch, getState) => {
  return __initACL().then((response) => {
    const [menuList, aclList] = response;

    batch(() => {
      /*-----------Menu list dispatch-------------*/
      let moduleListData = [];
      let menuListData = [];
      menuList.data &&
        menuList.data.map((value, i) => {
          if (moduleListData.indexOf(value.Module_Menu) === -1) {
            moduleListData.push(value.Module_Menu);
            menuListData[value.Module_Menu] = [];
          }
          menuListData[value.Module_Menu].push(value);
        });
      dispatch({
        type: SET_MODULES_LIST_DATA,
        payload: menuListData,
      });

      dispatch({
        type: SET_MENU_LIST_DATA,
        payload: moduleListData,
      });

      /*-----------ACL list dispatch-------------*/
      let dataTableOBJ = [];
      aclList.data &&
        aclList.data.map((value, i) => {
          dataTableOBJ.push([
            value.SACL_Name,
            value.SACL_Status,
            value.SACL_Id,
            value.SACL_Id,
          ]);
        });
      dispatch({
        type: ACL_LIST,
        payload: dataTableOBJ,
      });
    });
  });
};

//----------------------- get ACL ------------------------
export const updateACL = (form) => (dispatch) => {
  return __updateACL(form).then((response) => {});
};

//----------------------- get Acl data ------------------------
export const getACLData = (id) => (dispatch) => {
  return __getACLData(id).then((response) => {
    return response;
  });
};

//---------------Get Template Type----------------------------
export const getTemplates = (type) => (dispatch) => {
  return __getTemplates(type).then((response) => {
    dispatch({
      type: TEMPLATE_LIST,
      payload: response,
    });
  });
};

//---------------Get Template Data----------------------------
export const getTemplatesData = (id) => (dispatch) => {
  return __getTemplatesData(id).then((response) => {
    return response;
  });
};

//---------------Save Template Data--------------------------------
export const updateTemplatesData = (form) => (dispatch) => {
  return __updateTemplatesData(form).then((response) => {
    //return response;
  });
};

//---------------Get Email Template Type----------------------------
export const getEmailTemplates = (type) => (dispatch) => {
  return __getEmailTemplates(type).then((response) => {
    dispatch({
      type: EMAIL_TEMPLATE_LIST,
      payload: response,
    });
  });
};

//---------------Get Email Template Data----------------------------
export const getEmailTemplatesData = (id) => (dispatch) => {
  return __getEmailTemplatesData(id).then((response) => {
    return response;
  });
};

//---------------Save Email Template Data--------------------------------
export const updateEmailTemplatesData = (form) => (dispatch) => {
  return __updateEmailTemplatesData(form).then((response) => {
    //return response;
  });
};

//--------------- Initialize UI Settings --------------------------------
export const initUISettings = () => (dispatch) => {
  return __initUISettings().then((response) => {
    dispatch({
      type: USER_UI_SETTINGS,
      payload: response,
    });
  });
};

//--------------- Update UI Settings --------------------------------
export const updateUISettings = (form) => (dispatch) => {
  return __updateUISettings(form).then((response) => {
    //console.log(response);
    //return response;
  });
};

//----------------------- send Email------------------------
export const sendEmail = (mailData) => (dispatch) => {
  return __sendEmail(mailData).then((response) => {
    return response;
  });
};

//--------------- Post Contact Us Form --------------------------------
export const postContactUs = (form) => (dispatch) => {
  return __postContactUs(form).then((response) => {
    //console.log(response);
    return response;
  });
};

//--------------- Initialize Company Settings --------------------------------
export const initCompanySettings = (selectedCompany) => (dispatch) => {
  return __initCompanySettings(selectedCompany).then((response) => {
    const [invoiceCompanyDetails, companySettingsData] = response;

    batch(() => {
      dispatch({
        type: INVOICE_COMPANY_DETAILS,
        payload: invoiceCompanyDetails?.data || [],
      });
      dispatch({
        type: COMPANY_SETTINGS,
        payload: companySettingsData?.data?.data || [],
      });
    });
  });
};

//--------------- Update Company Settings --------------------------------
export const updateCompanySettings = (form) => (dispatch) => {
  return __updateCompanySettings(form).then((response) => {
    return response;
  });
};
