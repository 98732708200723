/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  COMPANY_SERVICES,
  USER_DETAILS,
} from ".";

import {
  __signIn,
  __signInWithGoogle,
  __logout,
  __initSignUp,
  __signUpNewUser,
  __initMyProfileDetails,
} from "./modules";
import { decrypt, encrypt } from "utils/pivot";

//----------------------- User Authentication Section ------------------------
export const signIn = (email) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  return __signIn(email)
    .then((response) => {
      sessionStorage.setItem("user_id", response[0].US_Id);
      sessionStorage.setItem("comp_id", response[0].CMP_Id);

      localStorage.setItem("user_id", response[0].US_Id);
      localStorage.setItem("comp_id", response[0].CMP_Id);
      localStorage.setItem("mode", "si");

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: true,
      });
    })
    .catch(function (error) {
      if (error.msg_stat == "0") {
        dispatch({
          type: LOGIN_USER_FAILURE,
        });
        return error;
      }
    });
};

//----------------------- User Authentication With Google Section ------------------------
export const signInWithGoogle = (email, google_key) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  return __signInWithGoogle(email, google_key)
    .then((response) => {
      // console.log(response);
      if (response.length > 0 && response[0].US_Id) {
        sessionStorage.setItem("user_id", response[0].US_Id);
        sessionStorage.setItem("comp_id", response[0].CMP_Id);

        localStorage.setItem("user_id", response[0].US_Id);
        localStorage.setItem("comp_id", response[0].CMP_Id);
        localStorage.setItem("mode", "si");

        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: true,
        });
        return "success";
      } else if (
        response.status == "new_user" ||
        response.status == "new_google_user"
      ) {
        return response.status;
      } else {
        return "fail";
      }
    })
    .catch(function (error) {
      if (error.msg_stat == "0") {
        dispatch({
          type: LOGIN_USER_FAILURE,
        });
        return error;
      }
    });
};

//---------------------------- User Logout Section ---------------------------
export const logout =
  (redirectSignUp = null) =>
  (dispatch, getState) => {
    return __logout(getState().users, redirectSignUp).then((response) => {
      dispatch({ type: LOGOUT_USER });

      localStorage.clear();
      sessionStorage.clear();

      localStorage.setItem("mode", "so");

      if (redirectSignUp !== null) {
        localStorage.setItem("redirectSignUp", true);
      }
      window.location.reload();
    });
  };

//---------------------------- Init SignUp Data ---------------------------
export const initSignUp = () => (dispatch) => {
  return __initSignUp().then((response) => {
    dispatch({
      type: COMPANY_SERVICES,
      payload: response[0],
    });
  });
};

//---------------------------- User SignUp Section ---------------------------
export const signUpNewUser = (formData) => (dispatch) => {
  return __signUpNewUser(formData).then((response) => {
    return response;
  });
};

//--------------------------Init My Profile-------------------------------------
export const initMyProfileDetails = () => (dispatch) => {
  return __initMyProfileDetails().then((response) => {
    dispatch({
      type: USER_DETAILS,
      payload: response,
    });
  });
};
