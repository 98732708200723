/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import createReducer from "./reducers";

const __configureStore = configureStore({
  reducer: createReducer(),
  middleware: [thunk],
});
__configureStore.asyncReducers = {};
__configureStore.injectReducer = (key, asyncReducer) => {
  __configureStore.asyncReducers[key] = asyncReducer;
  __configureStore.replaceReducer(
    createReducer(__configureStore.asyncReducers)
  );
};
export default __configureStore;
