/**
 * Horizontal Menu
 */

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import NavMenuItem from "./NavMenuItem";
import { setSidebarMenu } from "utils/pivot";

// Redux Modules
import {
  onToggleMenu,
  collapsedSidebarAction,
} from "modules/themes/stores/themeSettings";

const HorizontalMenu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let moduleName = pathname.split("/")[1];

  const { sidebarMENU, userACL } = useSelector(
    (store) => store.themes.themeSettings
  );

  const menuOBJ =
    sidebarMENU && sidebarMENU[moduleName] && userACL && userACL[moduleName]
      ? setSidebarMenu(
          JSON.parse(sidebarMENU[moduleName]),
          moduleName,
          userACL[moduleName]
        )
      : [];

  useEffect(() => {
    if (menuOBJ.length > 0) {
      onWindowResize();
    }

    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, [menuOBJ]);

  const onWindowResize = () => {};

  const toggleMenu = (menu, moduleName) => {
    let data = {
      menu,
      moduleName,
    };

    dispatch(onToggleMenu(data));
  };

  const requestMenu = () => {
    if (window.innerWidth < 1199) {
      dispatch(collapsedSidebarAction(true));
    }
  };

  return (
    <div className="hmenu-container" id="hmenu_container">
      <div id="left_menu_container">
        <ul id="left_menu_nav" className="hmenu-main-nav">
          {menuOBJ &&
            menuOBJ.length > 0 &&
            menuOBJ.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={(e) => {
                  toggleMenu(menu, moduleName);
                }}
                onRequestMenu={() => requestMenu()}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default HorizontalMenu;
