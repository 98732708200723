//---------------Populate Chart Data----------------------------
export const populateData = (props) => {
  let _labels = props.labels;
  let _datasets = props.datasets;

  const data = {
    labels: _labels,
    datasets: _datasets,
  };
  return data;
};

//---------------Populate Chart Options----------------------------
export const populateOptions = (props) => {
  let _title = props.title;
  let _legend = typeof props.legend ? props.legend : true;
  let _legendposn = props.legendposn ? props.legendposn : "top";
  let _horizontal = props.horizontal ? true : false;

  const options = {
    responsive: true,
    indexAxis: _horizontal ? "y" : "x",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: _legend,
        position: _legendposn,
      },
      title: {
        display: _title ? true : false,
        text: _title,
      },
    },
  };

  return options;
};
