/**
 * Rct Card Title
 */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

const FixedCardHeading = ({ title, customClasses }) => (
  <div className={`rct-block-title ${customClasses ? customClasses : ""}`}>
    <h4>{title}</h4>
  </div>
);

// type checking props
FixedCardHeading.propTypes = {
  title: PropTypes.any,
};

export { FixedCardHeading };
