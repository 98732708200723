/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

const scene = {
  dashboard: "/dashboard",
  menu: "/menu",
  acl: "/acl",
  pdfLayouts: "/pdfLayouts",
  emailLayouts: "/emailLayouts",
  uiSettings: "uiSettings",
  companySettings: "companySettings",
  aboutus: "aboutus",
  contactus: "contactus",
  gettingstarted: "gettingstarted",
};

const AppConstants = {
  scene,
};

export default AppConstants;
