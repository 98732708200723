/**
 * Simple Plans
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans
 * @license     -
 *
 **/

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// Redux Modules
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// page title bar
import PageTitleBar from "components/PageTitleBar/PageTitleBar";

import { AppTrlns } from "config";

import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Box,
  tabsClasses,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { useTreeItem, treeItemClasses } from "@mui/lab/TreeItem";
import clsx from "clsx";

import { setLoader } from "modules/themes/stores/themeSettings";
import TextTranslation from "components/TextTranslation/TextTranslation";

import {
  getModuleMenuList,
  manipulateMenuListData,
  updateModuleMenuList,
} from "modules/settings/stores/settings";

import MenuIcons from "components/Widgets/MenuIcons";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import {
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@mui/icons-material";

const ManageMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);
  const [expandedTree, setExpandedTree] = useState([]);
  const [editMenuIcons, setEditMenuIcons] = useState(false);
  const [editedMenu, setEditedMenu] = useState(null);
  const [revertDisabled, setRevertDisabled] = useState(true);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getModuleMenuList()).then(() => {
      dispatch(setLoader(false));
    });
  }, []);

  const { moduleListData, menuListData, menuListDataDummy } = useSelector(
    (store) => store.settings.settings
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (name, value) => {
    //alert(name + " -- " + value);
    const [MN_MOD_NAME, MN_MENU_ID, MN_MENU_FIELD] = name.split("__");

    let MN_KEY = menuListData[MN_MOD_NAME].findIndex(
      (MNU) => MNU.MN_Id == MN_MENU_ID
    );

    if (MN_MENU_FIELD == "Tags") {
      menuListData[MN_MOD_NAME][MN_KEY]["MN_Tags"] = value;
    } else if (MN_MENU_FIELD == "Order") {
      menuListData[MN_MOD_NAME][MN_KEY]["MN_Order"] = value;
    } else if (MN_MENU_FIELD == "Icon") {
      menuListData[MN_MOD_NAME][MN_KEY]["MN_Icon"] = value;
    } else if (MN_MENU_FIELD == "Seperator") {
      menuListData[MN_MOD_NAME][MN_KEY]["MN_WithSeperator"] = value;
    }

    setRevertDisabled(false);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div className="w-100 d-flex flex-row mt-1 mb-1 menuRow">
          <div className="menuLabelContainer">
            <div
              onClick={handleExpansionClick}
              className={classes.iconContainer}
            >
              {icon}
              {label[0]}
            </div>
          </div>
          <div
            className={classes.label}
            onClick={handleSelectionClick}
            component="div"
          >
            <div className="d-flex menuInputContainer">
              {/* <div className="menuSeperatorContainer">{label[1]}</div> */}
              <div className="menuIconContainer">{label[1]}</div>
              <div className="menuOrderContainer">{label[2]}</div>
              <div className="flex-grow-1 menuTagContainer">{label[3]}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const StyledTreeItem = styled((props) => (
    <TreeItem ContentComponent={CustomContent} {...props} />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  const isChildExists = (MNId, menuOBJ) => {
    let childExists = false;
    menuOBJ.map((childValue, i) => {
      if (MNId == childValue["MN_Parent"]) {
        childExists = true;
      }
    });
    return childExists;
  };

  const displayMenuPositionOptions = () => {
    return Array.apply(null, Array(25)).map(function (val, i) {
      return (
        <MenuItem value={i + 1} key={i}>
          {i + 1}
        </MenuItem>
      );
    });
  };

  const changeMenuIcon = (itemName) => {
    setEditedMenu(itemName);
    setEditMenuIcons(true);
  };

  const renderMenuRow = (
    Module_Name,
    MN_Id,
    Module_Menu,
    // MN_WithSeperator,
    MN_Icon,
    MN_Order,
    MN_Tags
  ) => {
    return [
      <div className="menuLabel">
        <TextTranslation text={"Menu." + Module_Menu + "." + Module_Name} />
      </div>,
      // <Checkbox
      //   name={Module_Menu + "__" + MN_Id + "__Seperator"}
      //   defaultChecked={MN_WithSeperator == 1 ? true : false}
      //   defaultValue={MN_WithSeperator}
      //   onChange={(e) => handleChange(e.target.name, e.target.checked)}
      //   sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
      // />,
      <Button
        variant="contained"
        className="manage-menu-button"
        onClick={() => changeMenuIcon(Module_Menu + "__" + MN_Id + "__Icon")}
      >
        <i
          id={Module_Menu + "__" + MN_Id + "__Icon"}
          className={`zmdi ${MN_Icon}`}
        ></i>
      </Button>,
      <Select
        className="manage-menu-select"
        variant="standard"
        size="small"
        disableUnderline
        name={Module_Menu + "__" + MN_Id + "__Order"}
        defaultValue={MN_Order}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        sx={{ width: "80px" }}
      >
        {displayMenuPositionOptions()}
      </Select>,
      <TextField
        className="manage-menu-textfield"
        variant="standard"
        size="small"
        name={Module_Menu + "__" + MN_Id + "__Tags"}
        defaultValue={MN_Tags}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />,
    ];
  };

  const renderMenu = (parent, key, menuOBJ) => {
    return (
      menuOBJ &&
      menuOBJ.map((value, i) => {
        if (parent === value["MN_Parent"]) {
          let childItems = isChildExists(value["MN_Id"], menuOBJ);
          if (!expandedTree.includes(`${key}_${value["MN_Id"]}`)) {
            setExpandedTree((expandedTree) => [
              ...expandedTree,
              `${key}_${value["MN_Id"]}`,
            ]);
          }
          return (
            <StyledTreeItem
              key={`${key}_${value["MN_Id"]}`}
              nodeId={`${key}_${value["MN_Id"]}`}
              label={renderMenuRow(
                value["MN_Title"],
                value["MN_Id"],
                value["Module_Menu"],
                // value["MN_WithSeperator"],
                value["MN_Icon"],
                value["MN_Order"],
                value["MN_Tags"]
              )}
            >
              {childItems ? renderMenu(value["MN_Id"], key, menuOBJ) : null}
            </StyledTreeItem>
          );
        }
      })
    );
  };

  const onMenuIconClick = (key, value) => {
    handleChange(editedMenu, key);
    setEditMenuIcons(false);
  };

  const toggleEditMenuIcons = () => {
    setEditMenuIcons(false);
  };

  const handleOnCancel = (module) => {
    dispatch(manipulateMenuListData(menuListData));

    menuListData[module] = Object.fromEntries(JSON.parse(menuListDataDummy))[
      module
    ];
    dispatch(manipulateMenuListData(menuListData));
    setRevertDisabled(true);
  };

  const handleOnSave = (module) => {
    let thisOBJ = Object.fromEntries(JSON.parse(menuListDataDummy))[module];
    let newMenuData = [];

    thisOBJ &&
      thisOBJ.map((value, i) => {
        let thisKEY = menuListData[module].findIndex(
          (MNU) => MNU.MN_Id == value.MN_Id
        );
        if (
          value.MN_Icon != menuListData[module][thisKEY].MN_Icon ||
          value.MN_Order != menuListData[module][thisKEY].MN_Order ||
          value.MN_Tags != menuListData[module][thisKEY].MN_Tags ||
          value.MN_WithSeperator !=
            menuListData[module][thisKEY].MN_WithSeperator
        ) {
          newMenuData.push({
            MN_Id: menuListData[module][thisKEY].MN_Id,
            MN_WithSeperator: menuListData[module][thisKEY].MN_WithSeperator,
            MN_Icon: menuListData[module][thisKEY].MN_Icon,
            MN_Order: menuListData[module][thisKEY].MN_Order,
            MN_Tags: menuListData[module][thisKEY].MN_Tags,
          });
        }
      });

    //console.log(newMenuData);
    dispatch(setLoader(true));
    dispatch(updateModuleMenuList(newMenuData, module)).then(() => {
      dispatch(setLoader(false));
    });
  };

  return (
    <div className="ecom-dashboard-wrapper">
      <Helmet>
        <title>{AppTrlns("settings:Menu.sceneTitle")}</title>
        <meta name="description" content="Reactify Ecommerce Dashboard" />
      </Helmet>
      <PageTitleBar
        title={AppTrlns("settings:Menu.sceneTitle")}
        location={location}
      />

      <div className="container-fluid menuContainerWrapper">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {moduleListData &&
            moduleListData.map((key, i) => {
              // require("modules/" +
              //   menuListData[key][0]["Module_Menu"] +
              //   "/translations/" +
              //   locale +
              //   ".json");
              return (
                <Tab
                  key={`module_${i}`}
                  label={menuListData[key][0]["Module_Name"]}
                  {...a11yProps(i)}
                />
              );
            })}
        </Tabs>
        {moduleListData &&
          moduleListData.map((key, i) => {
            return (
              <TabPanel key={`module_${i}`} value={activeTab} index={i}>
                {/* {renderMenu(0, key, moduleListData[key])} */}
                <TreeView
                  aria-label="customized"
                  expanded={expandedTree}
                  defaultCollapseIcon={
                    <IndeterminateCheckBoxOutlined className="menuTreeIcon" />
                  }
                  defaultExpandIcon={
                    <AddBoxOutlined className="menuTreeIcon" />
                  }
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    marginBottom: "18px",
                  }}
                >
                  {renderMenu(0, key, menuListData[key])}
                </TreeView>
                <div className="col-md-12 d-inline">
                  <Stack spacing={2} direction="row" className="pull-right">
                    <Button
                      disabled={revertDisabled}
                      variant="contained"
                      size="small"
                      color="warning"
                      onClick={() => handleOnCancel(key)}
                      startIcon={<CloseOutlined />}
                    >
                      Revert
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleOnSave(key)}
                      startIcon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Stack>
                </div>
              </TabPanel>
            );
          })}
      </div>
      <Modal
        isOpen={editMenuIcons}
        toggle={() => toggleEditMenuIcons()}
        size="lg"
      >
        <ModalHeader toggle={() => toggleEditMenuIcons()}>
          Icon Lists
        </ModalHeader>
        <ModalBody className="mt-4">
          <div className="row full-height">
            {Object.entries(MenuIcons).map(([key, value]) => (
              <div
                className="text-center mb-3 col-sm-2 menuIconsList"
                key={key}
                onClick={() => onMenuIconClick(key, value)}
              >
                <i className={"menuIcons zmdi " + key}></i>
                <p className="menuIconName">{value}</p>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageMenu;
