/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { APPS_LIST, INIT_APP_CONSTANTS, SET_SEARCH_DATA } from ".";

// App config
import { AppConfig } from "config";

const INIT_STATE = {
  appsList: [],
  searchData: [],
  appConstants: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPS_LIST:
      return { ...state, appsList: action.payload };

    case SET_SEARCH_DATA:
      return { ...state, searchData: action.payload };

    case INIT_APP_CONSTANTS:
      return { ...state, appConstants: action.payload };

    default:
      return { ...state };
  }
};
