/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

const scene = {
  dashboard: "/dashboard",
  applications: "/applications",
};

const AppConstants = {
  scene,
};

export default AppConstants;
