/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NotificationManager } from "react-notifications";

import {
  Autocomplete,
  FormControl,
  TextField,
  createFilterOptions,
  IconButton,
} from "@mui/material";

import JoditEditor from "jodit-react";

import FieldError from "components/FieldError";
import TextTranslation from "components/TextTranslation/TextTranslation";

import { AppConstants as MainAppConstants } from "config";

import { setLoader } from "modules/themes/stores/themeSettings";
import { sendEmail } from "modules/settings/stores/settings";

import * as validationPattern from "utils/validationPattern";
import { AttachFile, Delete } from "@mui/icons-material";
import Upload from "rc-upload";

import { sendEmailHistory } from "modules/invoices/stores/invoices";

const ComposeEmailModal = (props) => {
  const dispatch = useDispatch();
  const editor = useRef(null);

  const filter = createFilterOptions();
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedCCRecipients, setSelectedCCRecipients] = useState([]);
  const [selectedBCCRecipients, setSelectedBCCRecipients] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [content, setContent] = useState("");

  const [recipientsCombo, setRecipientsCombo] = useState([]);
  const [recipientsCCCombo, setRecipientsCCCombo] = useState([]);
  const [recipientsBCCCombo, setRecipientsBCCCombo] = useState([]);

  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  const [selectedTransactionCompId, setSelectedTransactionCompId] =
    useState("");

  const [attachment, setAttachment] = useState([]);
  const [dummy, setDummy] = useState(null);
  const [fromFlag, setFromFlag] = useState(null);

  const imgExt = [".jpg", ".png", ".jpeg", ".pdf"];
  const imgAllowedArray = [".jpg", ".png", ".jpeg"];

  const { appConstants } = useSelector((store) => store.themes.generalSettings);

  const config = {
    removeButtons: ["image", "video", "link", "about"],
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      RCP_Id: [],
      RCP_Id_Options: [],
      CCP_Id: [],
      CCP_Id_Options: [],
      BCCP_Id: [],
      BCCP_Id_Options: [],
      Subject: "",
      Content: "",
      TEMP_Id: [],
      TEMP_Id_Options: [],
    },
  });

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  useEffect(() => {
    // console.log("useEffect called", props.params.attachments);
    setFromFlag(props.fromFlag);

    setSelectedTransactionId(props.params.invoiceId ?? "");
    setSelectedTransactionCompId(props.params.companyId ?? "");

    const selectedRecipients = props.params.recipients.filter(
      (recipient) => recipient.selected === "selected"
    );

    setValue("RCP_Id_Options", selectedRecipients ?? null, {
      shouldValidate: true,
    });
    setValue(
      "RCP_Id",
      selectedRecipients.length > 0
        ? selectedRecipients.map((a) => a.RCP_Name)
        : null,
      {
        shouldValidate: true,
      }
    );
    setSelectedRecipients(selectedRecipients);
    setRecipientsCombo(props.params.recipients);

    const selectedCCRecipients = props.params.ccRecipients.filter(
      (ccrecipient) => ccrecipient.selected === "selected"
    );

    setValue("CCP_Id_Options", selectedCCRecipients ?? null, {
      shouldValidate: true,
    });
    setValue(
      "CCP_Id",
      selectedCCRecipients.length > 0
        ? selectedCCRecipients.map((a) => a.CCP_Name)
        : null,
      {
        shouldValidate: true,
      }
    );
    setSelectedCCRecipients(selectedCCRecipients);
    setRecipientsCCCombo(props.params.ccRecipients);

    const selectedBCCRecipients = props.params.bccRecipients.filter(
      (bccRecipient) => bccRecipient.selected === "selected"
    );

    setValue("BCCP_Id_Options", selectedBCCRecipients ?? null, {
      shouldValidate: true,
    });
    setValue(
      "BCCP_Id",
      selectedBCCRecipients.length > 0
        ? selectedBCCRecipients.map((a) => a.BCCP_Name)
        : null,
      {
        shouldValidate: true,
      }
    );
    setSelectedBCCRecipients(selectedBCCRecipients);
    setRecipientsBCCCombo(props.params.bccRecipients);

    setValue("Subject", props.params.subject ? props.params.subject : null, {
      shouldValidate: true,
    });

    setValue("Content", props.params.body ? props.params.body : null, {
      shouldValidate: true,
    });
    setContent(htmlDecode(props.params.body));
    setAttachment(props.params.attachments);
  }, [props.timeStamp, props.params]);

  const toggleComposeMailModal = () => {
    setSelectedRecipients([]);
    setSelectedCCRecipients([]);
    setSelectedBCCRecipients([]);
    setSelectedTemplate([]);
    setContent("");
    props.handleCloseModal(false);
  };

  const handleFormSubmit = (form) => {
    let recipients = [];
    recipients.push(
      selectedRecipients.map((option) =>
        option.RCP_Temp_Name ? option.RCP_Temp_Name : option.RCP_Name
      )
    );

    let ccRecipients = [];
    ccRecipients.push(
      selectedCCRecipients.map((option) =>
        option.CCP_Temp_Name ? option.CCP_Temp_Name : option.CCP_Name
      )
    );

    let bccRecipients = [];
    bccRecipients.push(
      selectedBCCRecipients.map((option) =>
        option.BCCP_Temp_Name ? option.BCCP_Temp_Name : option.BCCP_Name
      )
    );

    const parameters = {
      from: "no-reply@simpleplans.com.au",
      to: recipients.toString(),
      cc: ccRecipients.toString(),
      bcc: bccRecipients.toString(),
      subject: form["Subject"],
      text: content.replace(/<(.|\n)*?>/g, ""),
      html: content,
      attachments: attachment,
    };
    if (recipients.length > 0) {
      dispatch(setLoader(true));
      dispatch(sendEmail(parameters))
        .catch((err) => {
          alert(err.msg);
        })
        .then((response) => {
          if (
            fromFlag == "invoice" &&
            selectedTransactionId &&
            selectedTransactionCompId
          ) {
            dispatch(
              sendEmailHistory(
                parameters,
                selectedTransactionId,
                selectedTransactionCompId
              )
            )
              .catch((err) => {
                alert(err.msg);
              })
              .then((response) => {
                reset();
                clearErrors();
                NotificationManager.success(
                  <TextTranslation
                    text={"settings:ComposeEmail.notifications.successMsg"}
                  />
                );
                toggleComposeMailModal();
                window.location.reload();
              })
              .finally(() => {});
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
        });

      props.handleCloseModal(false);
    } else {
      NotificationManager.error("Please Select Recipient");
    }
  };

  const handleOnError = () => {};

  const handleRecipientChange = (e, options) => {
    setSelectedRecipients(options ? options : []);
    setValue("RCP_Id_Options", options ? options : []);
    setValue(
      "RCP_Id",
      options
        ? options.map((option) =>
            option.RCP_Temp_Name ? option.RCP_Temp_Name : option.RCP_Name
          )
        : null,
      {
        shouldValidate: true,
      }
    );
    setRecipientsCombo(
      props.params.recipients.filter(
        (user) => !options.map((a) => a.RCP_Name).includes(user.RCP_Name)
      )
    );
  };

  const handleCCRecipientChange = (e, options) => {
    setSelectedCCRecipients(options ? options : []);
    setValue("CCP_Id_Options", options ? options : []);
    setValue(
      "CCP_Id",
      options
        ? options.map((option) =>
            option.CCP_Temp_Name ? option.CCP_Temp_Name : option.CCP_Name
          )
        : null,
      {
        shouldValidate: true,
      }
    );
    setRecipientsCCCombo(
      props.params.ccRecipients.filter(
        (user) => !options.map((a) => a.CCP_Name).includes(user.CCP_Name)
      )
    );
  };

  const handleBCCRecipientChange = (e, options) => {
    setSelectedBCCRecipients(options ? options : []);
    setValue("BCCP_Id_Options", options ? options : []);
    setValue(
      "BCCP_Id",
      options
        ? options.map((option) =>
            option.BCCP_Temp_Name ? option.BCCP_Temp_Name : option.BCCP_Name
          )
        : null,
      {
        shouldValidate: true,
      }
    );
    setRecipientsBCCCombo(
      props.params.bccRecipients.filter(
        (user) => !options.map((a) => a.BCCP_Name).includes(user.BSCCP_Name)
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });
  };

  const deleteAttachments = (fileName) => {
    let documentArray = attachment;
    let index = documentArray.findIndex((item) => item.filename == fileName);
    //const index = documentArray.indexOf(fileName);
    if (index > -1) {
      documentArray.splice(index, 1);
    }

    setAttachment(documentArray);
    setTimeout(() => {
      setDummy(Date.now());
    }, 500);
  };

  return (
    <Modal
      isOpen={props.isModalOpened}
      className=""
      toggle={toggleComposeMailModal}
    >
      <ModalHeader>
        <TextTranslation text={"settings:ComposeEmail.formLabels.formTitle"} />
      </ModalHeader>
      <ModalBody>
        <div className="transactionFormGroup">
          <table>
            <tbody>
              <tr>
                <td className="td_label">
                  <div>
                    <label className="formLabel">
                      <TextTranslation
                        text={"settings:ComposeEmail.formLabels.recipients"}
                      />
                    </label>
                  </div>
                </td>
                <td className="td_input">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <Autocomplete
                        multiple
                        filterSelectedOptions
                        options={
                          recipientsCombo
                            ? recipientsCombo.map((elem) => ({
                                RCP_Id: elem["RCP_Id"],
                                RCP_Name: elem["RCP_Name"],
                              }))
                            : []
                        }
                        getOptionLabel={(option) =>
                          option.RCP_Temp_Name
                            ? option.RCP_Temp_Name
                            : option.RCP_Name
                        }
                        renderOption={(props, option, { inputValue }) => {
                          let thisOption = option.RCP_Name;
                          if (option["RCP_Temp_Name"] !== undefined) {
                            thisOption = (
                              <div className="create-new-option">
                                {option.RCP_Name}
                              </div>
                            );
                          }
                          return (
                            <li {...props} key={option.RCP_Id}>
                              {thisOption}
                            </li>
                          );
                        }}
                        filterOptions={(options, params) => {
                          let newOptions = [];
                          let filtered = [];
                          options.forEach((element) => {
                            if (
                              element.RCP_Name.toLowerCase().includes(
                                params.inputValue.toLowerCase()
                              )
                            )
                              newOptions.push(element);
                          });
                          filtered = newOptions;
                          const isExisting = options.some(
                            (option) => params.inputValue === option["RCP_Name"]
                          );
                          if (
                            params.inputValue.trim() !== "" &&
                            !isExisting &&
                            validationPattern.validateEmail(params.inputValue)
                          ) {
                            filtered.push({
                              RCP_Id: "",
                              RCP_Temp_Name: params.inputValue,
                              RCP_Name: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.RCP_Id === parseInt(value.RCP_Id)
                        }
                        onChange={(e, options) => {
                          handleRecipientChange(e, options);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors?.RCP_Id ? true : false}
                            {...register("RCP_Id", {
                              required:
                                selectedRecipients &&
                                selectedRecipients.length === 0
                                  ? true
                                  : false,
                            })}
                            variant="standard"
                          />
                        )}
                        value={selectedRecipients}
                      />
                      {errors?.RCP_Id?.type === "required" && (
                        <FieldError errorText="This Field Is Required" />
                      )}
                    </FormControl>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="td_label">
                  <div>
                    <label className="formLabel">
                      <TextTranslation
                        text={"settings:ComposeEmail.formLabels.ccRecipients"}
                      />
                    </label>
                  </div>
                </td>

                <td className="td_input">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <Autocomplete
                        multiple
                        filterSelectedOptions
                        options={
                          recipientsCCCombo
                            ? recipientsCCCombo.map((elem) => ({
                                CCP_Id: elem["CCP_Id"],
                                CCP_Name: elem["CCP_Name"],
                              }))
                            : []
                        }
                        getOptionLabel={(option) =>
                          option.CCP_Temp_Name
                            ? option.CCP_Temp_Name
                            : option.CCP_Name
                        }
                        renderOption={(props, option, { inputValue }) => {
                          let thisOption = option.CCP_Name;
                          if (option["CCP_Temp_Name"] !== undefined) {
                            thisOption = (
                              <div className="create-new-option">
                                {option.CCP_Name}
                              </div>
                            );
                          }
                          return (
                            <li {...props} key={option.CCP_Id}>
                              {thisOption}
                            </li>
                          );
                        }}
                        filterOptions={(options, params) => {
                          let newOptions = [];
                          let filtered = [];
                          options.forEach((element) => {
                            if (
                              element.CCP_Name.toLowerCase().includes(
                                params.inputValue.toLowerCase()
                              )
                            )
                              newOptions.push(element);
                          });
                          filtered = newOptions;
                          const isExisting = options.some(
                            (option) => params.inputValue === option["CCP_Name"]
                          );
                          if (
                            params.inputValue.trim() !== "" &&
                            !isExisting &&
                            validationPattern.validateEmail(params.inputValue)
                          ) {
                            filtered.push({
                              CCP_Id: "",
                              CCP_Temp_Name: params.inputValue,
                              CCP_Name: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.CCP_Id === parseInt(value.CCP_Id)
                        }
                        onChange={(e, options) => {
                          handleCCRecipientChange(e, options);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors?.CCP_Id ? true : false}
                            {...register("CCP_Id", {
                              // required:
                              //   selectedCCRecipients &&
                              //   selectedCCRecipients.length === 0
                              //     ? true
                              //     : false,
                            })}
                            variant="standard"
                          />
                        )}
                        value={selectedCCRecipients}
                      />
                    </FormControl>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="td_label">
                  <div>
                    <label className="formLabel">
                      <TextTranslation
                        text={"settings:ComposeEmail.formLabels.bccRecipients"}
                      />
                    </label>
                  </div>
                </td>
                <td className="td_input">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <Autocomplete
                        multiple
                        filterSelectedOptions
                        options={
                          recipientsBCCCombo
                            ? recipientsBCCCombo.map((elem) => ({
                                BCCP_Id: elem["BCCP_Id"],
                                BCCP_Name: elem["BCCP_Name"],
                              }))
                            : []
                        }
                        getOptionLabel={(option) =>
                          option.BCCP_Temp_Name
                            ? option.BCCP_Temp_Name
                            : option.BCCP_Name
                        }
                        renderOption={(props, option, { inputValue }) => {
                          let thisOption = option.BCCP_Name;
                          if (option["BCCP_Temp_Name"] !== undefined) {
                            thisOption = (
                              <div className="create-new-option">
                                {option.BCCP_Name}
                              </div>
                            );
                          }
                          return (
                            <li {...props} key={option.BCCP_Id}>
                              {thisOption}
                            </li>
                          );
                        }}
                        filterOptions={(options, params) => {
                          let newOptions = [];
                          let filtered = [];
                          options.forEach((element) => {
                            if (
                              element.BCCP_Name.toLowerCase().includes(
                                params.inputValue.toLowerCase()
                              )
                            )
                              newOptions.push(element);
                          });
                          filtered = newOptions;
                          const isExisting = options.some(
                            (option) =>
                              params.inputValue === option["BCCP_Name"]
                          );
                          if (
                            params.inputValue.trim() !== "" &&
                            !isExisting &&
                            validationPattern.validateEmail(params.inputValue)
                          ) {
                            filtered.push({
                              BCCP_Id: "",
                              BCCP_Temp_Name: params.inputValue,
                              BCCP_Name: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.BCCP_Id === parseInt(value.BCCP_Id)
                        }
                        onChange={(e, options) => {
                          handleBCCRecipientChange(e, options);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors?.BCCP_Id ? true : false}
                            {...register("BCCP_Id", {
                              // required:
                              //   selectedCCRecipients &&
                              //   selectedCCRecipients.length === 0
                              //     ? true
                              //     : false,
                            })}
                            variant="standard"
                          />
                        )}
                        value={selectedBCCRecipients}
                      />
                    </FormControl>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="td_label">
                  <div>
                    <label className="formLabel">
                      <TextTranslation
                        text={"settings:ComposeEmail.formLabels.subject"}
                      />
                    </label>
                  </div>
                </td>
                <td className="td_input">
                  <div className="fieldWidget">
                    <FormControl variant="standard" fullWidth>
                      <TextField
                        id="Subject"
                        variant="standard"
                        error={errors?.Subject ? true : false}
                        {...register("Subject")}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="td_input" colSpan={2}>
                  <div className="fieldWidget  mt-20 border border-dark rounded ">
                    <FormControl variant="standard" fullWidth>
                      <JoditEditor
                        ref={editor}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)}
                        value={content}
                      />
                    </FormControl>
                  </div>
                </td>
              </tr>

              {attachment.length > 0 ? (
                <tr>
                  <td className="td_input" colSpan={2}>
                    <div className="fieldWidget mt-20">
                      <ul className="list-group">
                        {attachment.map((value, key) => {
                          let url = value.path;
                          return (
                            <li
                              className="list-group-item clearfix col-md-12"
                              key={key}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-grow p-2 icon-holder">
                                  <img
                                    src={require("assets/img/pdf_logo.png")}
                                    className="img-fluid pdficon"
                                  />
                                  <div className="ml-2">
                                    <a
                                      href="#"
                                      className="text-dark-75 font-weight-bold mt-1 font-size-lg"
                                    >
                                      {value.filename.split(".")[0]}
                                    </a>
                                    <span className="pe-2 text-nowrap mb-1 d-inline-block d-flex">
                                      {value.filename.split(".")[1]}
                                    </span>
                                  </div>
                                </div>
                                <div className="pull-right button-group">
                                  {key == 0 ? null : (
                                    <IconButton
                                      color="primary"
                                      onClick={(e) =>
                                        deleteAttachments(value.filename)
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="contained"
          size="small"
          color="warning"
          onClick={toggleComposeMailModal}
        >
          <TextTranslation text={"settings:ComposeEmail.buttonText.cancel"} />
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleSubmit(handleFormSubmit, handleOnError)}
        >
          <TextTranslation text={"settings:ComposeEmail.buttonText.send"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ComposeEmailModal;
